//This component fetches and displays a server stored certificate
import { Component, OnInit, Input } from '@angular/core';
import { StreamCertificateService } from '../../../services/stream-certificate.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-stream-certificate',
  templateUrl: './stream-certificate.component.html',
  styleUrls: ['./stream-certificate.component.scss']
})
export class StreamCertificateComponent implements OnInit {

  @Input() showCertificate: boolean;
  public base64Certificate: SafeUrl;
  public isLoading: boolean = false;
  private streamSub: Subscription;
  private streamSubLoading: Subscription;

  constructor(private streamService: StreamCertificateService,
              private sanitizer: DomSanitizer) 
  {
    //Set initial base64 certificate to avoid errors while loading
    this.base64Certificate = sanitizer.bypassSecurityTrustResourceUrl('data:application/pdf;base64,');
  }

  ngOnInit() {
    //Wait for certificate to be fetched then display
    this.streamSub = this.streamService.onChange.subscribe((data: string) => {
      this.streamCertificate(data);
    })
    //Watch for changes in loading to display loading gif
    this.streamSubLoading = this.streamService.onLoading.subscribe((loading: boolean) => {
      if (loading) {
        this.showCertificate = true;
      }
      this.isLoading = loading;
    })
  }

  //Unsubscribe on route change
  ngOnDestroy() {
    if (this.streamSub) this.streamSub.unsubscribe();
    if (this.streamSubLoading) this.streamSubLoading.unsubscribe();
  }

  //Display certificate
  private streamCertificate(data: string): void {
    this.showCertificate = true;
    this.base64Certificate = this.sanitizer.bypassSecurityTrustResourceUrl('data:application/pdf;base64,' + data);
  }

  //Close certificate view
  hideStream(): void {
    this.showCertificate = false;
  }

}
