//This guard checks whether user has a token
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../services/authentication.service';
import { LoginStateService } from '../services/login-state.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
  constructor(private auth: AuthenticationService,
              private router: Router,
              private loginState: LoginStateService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    //If has token return true else try to login with certificate
    let hasToken = this.auth.getAccessToken() !== undefined && this.auth.getAccessToken() !== null && this.auth.getAccessToken() !== "";
    if (hasToken) {
      if (!this.loginState.loggedIn) {
        this.loginState.toggle(true);
      }
      return true; 
    }
    if (environment.envName == 'dev') console.warn("Manually add ACCESS-TOKEN, see dev/jwt-generation.py in union-backend")
    this.loginState.toggle(false);
    this.auth.requestAuthCode(location.origin + state.url);
    return false;
  }
}
