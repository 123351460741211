<div class="expage">
    <div id="part7" style="text-align:left;">
        <div class="bold blue leftcontainer mediumheight">
            <p class="lefttext textdown">7. {{strings['ROLLING_STOCK_SHORT']}}</p>
        </div>
        <div class="fullborder rightcontainer mediumheight" style="text-align:center;width:15cm;">
            <p>{{strings['DESCRIPTION']}}</p>
        </div>
        <div class="fullborder rightcontainer mediumheight" style="text-align:center;width:9.89cm;border-left:none;">
            <p>{{strings['NOTES']}}</p>
        </div>
        
        <div style="clear:both;"></div>
        
        

        <div *ngFor="let line of engine_lines">
            <div class="leftcontainer smallerheight"></div>
            <div class="fullborder rightcontainer smallerheight" style="width:15cm;border-top:none;">
                <p class="Xengineline textdownhalf lefttext" id="XengineDescription1">
                    <span *ngIf="holder.engines[line]!=null">
                        <b>{{holder.engines[line].engine}}</b> | {{strings['VALIDFROM']}} {{dateFormatter.dateToStr(holder.engines[line].valid_from, 'ddmmyyyy')}}
                    </span>
                </p>
            </div>
            <div class="fullborder rightcontainer smallerheight" style="width:9.89cm;border-left:none;border-top:none;">
                <p class="Xengineline textdownhalf lefttext" id="XengineRemark1"><span *ngIf="holder.engines[line]!= null">{{holder.engines[line]['note']}}</span></p>
            </div>
            
            <div style="clear:both;"></div>
        </div>

    <div class="smalljump"></div>
    
    <div id="part8">
        <div class="bold blue leftcontainer mediumheight">
            <p class="lefttext textdown">8. {{strings['INFRASTRUCTURE']}}</p>
        </div>
        <div class="fullborder rightcontainer mediumheight" style="text-align:center;width:15cm;">
            <p>{{strings['DESCRIPTION']}}</p>
        </div>
        <div class="fullborder rightcontainer mediumheight" style="text-align:center;width:9.89cm;border-left:none;">
            <p>{{strings['NOTES']}}</p>
        </div>
        
        <div style="clear:both;"></div>

        <div *ngFor="let line of infrastructure_lines">
            <div class="leftcontainer smallerheight"></div>
            <div class="fullborder rightcontainer smallerheight" style="width:15cm;border-top:none;">
                <p class="Xinfraline textdownhalf lefttext" *ngIf="all_infrastructures()[line]!=null">
                    <b>{{all_infrastructures()[line]['name']}}</b> | {{strings['VALIDFROM']}} {{dateFormatter.dateToStr(all_infrastructures()[line]['valid_from'], 'ddmmyyyy')}}
                </p>
            </div>
            <div class="fullborder rightcontainer smallerheight" style="width:9.89cm;border-left:none;border-top:none;">
                <p class="Xinfraline textdownhalf lefttext"><span *ngIf="all_infrastructures()[line]!=null">{{all_infrastructures()[line]['note']}}</span></p>
            </div>
            
            <div style="clear:both;"></div>
        </div>
    </div>
</div>