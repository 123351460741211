<div id="header" style="width:100%;">
    <a id="startlink" routerLink="/start">
        <img id="startlogo" src="{{artesLogoSrc}}">
    </a>

    <div *ngIf="isMainPage">
        <form class="navbar" *ngIf="loginStateService.loggedIn">
            <a class="item" (click)="logout()">{{strings.artesStrings['LOGOUT']}}</a>
    
            <!--<div class="dropdown item" *ngIf="auth.isAdmin">
                <button class="dropbtn">{{stringsService.artesStrings['ADMIN_ARTES']}}</button>
                <div class="dropdown-content">
                        <a class="leftdrpdwn" routerLink="/admin/stretches/list">{{stringsService.artesStrings['STRETCHES']}}</a>
                        <a class="leftdrpdwn" routerLink="/admin/stations/list">{{stringsService.artesStrings['STATIONS']}}</a>
                        <a class="leftdrpdwn" style="border-bottom:1px solid grey" routerLink="/admin/engines/list">{{stringsService.artesStrings['ENGINETYPES']}}</a>
                </div>
            </div>-->
    
            <div class="dropdown item" *ngIf="auth.isAdmin">
                <div style="position:relative;background-color:rgba(0,0,0,0)">
                    <button class="dropbtn">{{strings.artesStrings['ACTIONS']}}</button>
                    <span *ngIf="eventsService.scans.length + eventsService.reports.length > 0" class="spanamount main">
                        {{eventsService.scans.length + eventsService.reports.length || 0}}
                    </span>
                </div>
                <div class="dropdown-content">
                    <div style="position:relative;"><a routerLink="/events/scans" style="margin-left:30px;">{{strings.artesStrings['SCANNINGS']}}</a><span class="spanamount">{{eventsService.scans.length || 0}}</span></div>
                    <div style="position:relative;border-bottom: 1px solid grey;"><a routerLink="/events/reported-issues" style="margin-left:30px">{{strings.artesStrings['REPORTED_ISSUES']}}</a><span class="spanamount">{{eventsService.reports.length || 0}}</span></div>
                </div>
            </div>
    
            <div class="dropdown item">
                <button class="dropbtn">{{strings.artesStrings['STATISTICS']}}</button>
                <div class="dropdown-content">
                    <div style="position: relative;"><a routerLink="/statistics/actions">{{strings.artesStrings['ACTIONS']}}</a></div>
                    <div style="position: relative;"><a routerLink="/statistics/skills">{{strings.artesStrings['SKILLS']}}</a></div>
                </div>
            </div>
    
            <input type="search" autocomplete="" class="item" onfocusout="this.value=''" (keydown)="search($event)" placeholder="{{strings.artesStrings['SEARCH']}}" /> 
        </form>
        <form class="navbar" *ngIf="!loginStateService.loggedIn">
            <a class="item" (click)="router.navigate(['/start']);">{{strings.conStrings['LOGIN']}}</a>
            <a class="item" (click)="router.navigate(['/validate']);">{{strings.artesStrings['VALIDATE_CERTIFICATE']}}</a>
        </form>
    </div>
    <div *ngIf="!isMainPage && loginStateService.loggedIn">
        <div class="navbar">
            <form>
                <a class="item" (click)="close()">{{strings.artesStrings['CLOSE_WINDOW']}}</a>
                <a *ngIf="auth.isAdmin" class="item" (click)="navigate('/export')">{{strings.artesStrings['EXPORT']}}</a>
                <a *ngIf="auth.isAdmin" class="item" (click)="navigate('/history')">{{strings.artesStrings['HISTORY']}}</a>
                <a class="item" (click)="navigate('/certificate')">{{strings.artesStrings['CERTIFICATE']}}</a>
                <a class="item" (click)="navigate('/data')">{{strings.artesStrings['DATA']}}</a>
            </form>
        </div>            
    </div>
    
</div>