import * as moment from 'moment';
import { ICertificate } from '../interfaces/i-certificate';
import { Action } from './action';
import { Employer } from './employer';
import { Language } from './language';
import { Status } from './status';

export class Certificate {

    private _id: number;
    private _issuer_id: number;
    private _issuer: Employer = new Employer();
    private _valid_from: Date;
    private _valid_to: Date;
    private _additional_information: string;
    private _category_remark: string;
    private _restrictions: string;
    private _status_id: number;
    private _language_id: number;
    private _language: Language;
    private _signed: number;
    private _actions: Array<Action> = [];
    private _temporary: boolean = false;
    private _label: string;
    private _status: Status = new Status({ id: 2, status: 'INACTIVE' });

    constructor(obj?: ICertificate) {
        if (!obj) return;
        this._id = obj.id;
        this._label = obj.label;
        this._issuer_id = obj.issuer_id;
        this._issuer = new Employer(obj.issuer);
        this._valid_from = (obj.valid_from === null) ? null : moment(obj.valid_from).toDate();
        this._valid_to = (obj.valid_to === null) ? null : moment(obj.valid_to).toDate();
        this._additional_information = obj.additional_information;
        this._category_remark = obj.category_remark;
        this._restrictions = obj.restrictions;
        this._status_id = obj.status_id;
        this._language_id = obj.language_id || 1;
        this._language = new Language(obj.language);
        this._signed = obj.signed;
        this._temporary = obj.valid_to !== null && obj.valid_to !== undefined;
        if (obj.actions && obj.actions instanceof Array) {
            for (let a of obj.actions) {
                this._actions.push(new Action(a));
            }
        }
        if (obj.status) {
            this._status = new Status(obj.status)
        }
    }

    public toJSON() {
        return {
            id: this._id, issuer_id: this._issuer_id,
            valid_from: moment(this._valid_from).local().format('YYYY-MM-DD'), 
            valid_to: (this._valid_to) ? moment(this._valid_to).local().format('YYYY-MM-DD') : null,
            additional_information: this._additional_information, category_remark: this._category_remark,
            restrictions: this._restrictions, status_id: this._status_id, 
            language_id: this._language_id, signed: this._signed, temporary: (this._temporary) ? 1 : 0
        }
    }

    /**
     * Getter id
     * @return {number}
     */
	public get id(): number {
		return this._id;
	}

    /**
     * Getter issuer_id
     * @return {number}
     */
	public get issuer_id(): number {
		return this._issuer_id;
	}

    /**
     * Getter issuer
     * @return {Employer }
     */
	public get issuer(): Employer  {
		return this._issuer;
	}

    /**
     * Getter valid_from
     * @return {Date}
     */
	public get valid_from(): Date {
		return this._valid_from;
	}

    /**
     * Getter valid_to
     * @return {Date}
     */
	public get valid_to(): Date {
		return this._valid_to;
	}

    /**
     * Getter additional_information
     * @return {string}
     */
	public get additional_information(): string {
		return this._additional_information;
	}

    /**
     * Getter category_remark
     * @return {string}
     */
	public get category_remark(): string {
		return this._category_remark;
	}

    /**
     * Getter restrictions
     * @return {string}
     */
	public get restrictions(): string {
		return this._restrictions;
	}

    /**
     * Getter status_id
     * @return {number}
     */
	public get status_id(): number {
		return this._status_id;
	}

    /**
     * Getter language_id
     * @return {number}
     */
	public get language_id(): number {
		return this._language_id;
	}

    /**
     * Getter language
     * @return {Language}
     */
	public get language(): Language {
		return this._language;
	}

    /**
     * Getter signed
     * @return {number}
     */
	public get signed(): number {
		return this._signed;
	}

    /**
     * Getter actions
     * @return {Array<Action> }
     */
	public get actions(): Array<Action>  {
		return this._actions;
	}

    /**
     * Getter temporary
     * @return {boolean }
     */
	public get temporary(): boolean  {
		return this._temporary;
	}

    /**
     * Getter label
     * @return {string}
     */
	public get label(): string {
		return this._label;
	}

    /**
     * Getter status
     * @return {Status }
     */
	public get status(): Status  {
		return this._status;
	}

    /**
     * Setter id
     * @param {number} value
     */
	public set id(value: number) {
		this._id = value;
	}

    /**
     * Setter issuer_id
     * @param {number} value
     */
	public set issuer_id(value: number) {
		this._issuer_id = value;
	}

    /**
     * Setter issuer
     * @param {Employer } value
     */
	public set issuer(value: Employer ) {
		this._issuer = value;
	}

    /**
     * Setter valid_from
     * @param {Date} value
     */
	public set valid_from(value: Date) {
		this._valid_from = value;
	}

    /**
     * Setter valid_to
     * @param {Date} value
     */
	public set valid_to(value: Date) {
		this._valid_to = value;
	}

    /**
     * Setter additional_information
     * @param {string} value
     */
	public set additional_information(value: string) {
		this._additional_information = value;
	}

    /**
     * Setter category_remark
     * @param {string} value
     */
	public set category_remark(value: string) {
		this._category_remark = value;
	}

    /**
     * Setter restrictions
     * @param {string} value
     */
	public set restrictions(value: string) {
		this._restrictions = value;
	}

    /**
     * Setter status_id
     * @param {number} value
     */
	public set status_id(value: number) {
		this._status_id = value;
	}

    /**
     * Setter language_id
     * @param {number} value
     */
	public set language_id(value: number) {
		this._language_id = value;
	}

    /**
     * Setter language
     * @param {Language} value
     */
	public set language(value: Language) {
        if (value) this.language_id = value.id;
		this._language = value;
	}

    /**
     * Setter signed
     * @param {number} value
     */
	public set signed(value: number) {
		this._signed = value;
	}

    /**
     * Setter actions
     * @param {Array<Action> } value
     */
	public set actions(value: Array<Action> ) {
		this._actions = value;
	}

    /**
     * Setter temporary
     * @param {boolean } value
     */
	public set temporary(value: boolean ) {
		this._temporary = value;
	}

    /**
     * Setter label
     * @param {string} value
     */
	public set label(value: string) {
		this._label = value;
	}

    /**
     * Setter status
     * @param {Status } value
     */
	public set status(value: Status ) {
		this._status = value;
	}

    public toString(): string {
        return `ID: ${this._id} - ${moment(this._valid_from).local().format('YYYY-MM-DD')}`
    }
}
