//This component displays the front side of the certificate
import { Component, OnInit, Input } from '@angular/core';
import { DateFormatterService } from '../../../../services/date-formatter.service';
import { DomSanitizer } from '@angular/platform-browser';
import { CertificateHolder } from 'src/app/classes/certificateHolder';

@Component({
  selector: 'app-certificate-front',
  templateUrl: './certificate-front.component.html',
  styleUrls: ['./certificate-front.component.scss']
})
export class CertificateFrontComponent implements OnInit {

  //Holder and strings are sent by the certificate component
  @Input() holder: CertificateHolder;
  @Input() strings: Object = {};

  constructor(public dateFormatter: DateFormatterService,
              public sanitizer: DomSanitizer) { }

  ngOnInit() {
  }
}
