//This component displays the first page of the export form
import { Component, OnInit, Input } from '@angular/core';
import { CertificateHolder } from 'src/app/classes/certificateHolder';
import { DateFormatterService } from '../../../../services/date-formatter.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-export-page1',
  templateUrl: './export-page1.component.html',
  styleUrls: ['./export-page1.component.scss']
})
export class ExportPage1Component implements OnInit {

  //Get holder and strings from export component
  @Input() holder: CertificateHolder;
  @Input() strings: Object;

  constructor(public dateFormatter: DateFormatterService,
              public sanitizer: DomSanitizer) { }

  ngOnInit() {
  }

}
