//This component intercepts requests
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { StringService } from '../services/string.service';
import { Router } from '@angular/router';
import { catchError, switchMap, filter, take, map } from 'rxjs/operators';
import { environment } from '../../environments/environment'

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private isRefreshing: Boolean = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private auth: AuthenticationService,
              private strings: StringService,
              private router: Router) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    //If client has token add to request
    if (this.auth.getAccessToken()) {
      request = this.addToken(request, this.auth.getAccessToken());
    }
    return next.handle(request).pipe(catchError((error: any): Observable<any> => {
      if (error instanceof HttpErrorResponse) {
        //If 401 received call try to renew token
        if (error.status === 401) {
          if (this.router.url.indexOf('/login/access-denied') === -1) {
            return this.onUnauthorized(request, next)
          } else {
            this.auth.logout();
          }
        } else if (error.status === 403) {
          //If 403 received redirect to access denied
          this.router.navigateByUrl('/login/access-denied')
        } else {
          //Else if url is not to oauth server, display error
          if (error.url.indexOf(environment.ssoUrl) === -1) {
            if (this.strings.conStrings[error.message]) {
              alert(this.strings.conStrings[error.message]);
            } else {
              alert(error.message)
            }
          } else if (error.url.indexOf(environment.ssoUrl) !== -1 && error.status === 400) {
            //If url to oauth server and receives 400, try to get new auth code
            this.auth.requestAuthCode(location.origin + this.router.url)
          }
          return throwError(error); 
        }
      }
      return throwError(error);
    }))
  }

  //Add bearer token to request
  private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      },
      withCredentials: true
    })
  }

  //On 401 from application server try to refresh token
  private onUnauthorized(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      return this.auth.refreshAccessToken().pipe(
        switchMap((response: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(response.access_token);
          return next.handle(this.addToken(request, response.access_token));
        })
      )
    } else {
      this.isRefreshing = false;
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(accessToken => {
          return next.handle(this.addToken(request, accessToken))
        })
      )
    }
  }
}
