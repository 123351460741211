<div class="holder" *ngIf="loginStateService.loggedIn">
    <app-status></app-status>
    <div id="cert-holder">
        <div class="select-title">{{strings.artesStrings['LANGUAGE']}}</div>
        <div class="certificate-input" id="select-language" *ngIf="auth.isAdmin">
          <div class="action-container"> 
            <mat-select [(value)]="holderService.holder.active_certificate.language" (selectionChange)="setCertificateLanguage($event.value)" [disabled]="!auth.isAdmin">
              <mat-option *ngFor="let language of constantsService.constants.languages" [value]="language">
                {{language.label}}
              </mat-option>
            </mat-select>
         </div>    
        </div>
        <div style="clear:both"></div>
        <div id="certificate">
          <app-certificate-front [holder]="holderService.holder" [strings]="strings.certificateStrings" *ngIf="state===1" class="page" (click)="state=2"></app-certificate-front>
          <app-certificate-back [holder]="holderService.holder" [strings]="strings.certificateStrings" *ngIf="state===2" class="page" (click)="state=1"></app-certificate-back>
        </div>
        <div *ngIf="auth.isAdmin" class="select-title">{{strings.artesStrings['ACTION']}}</div> 
        <div class="action-container" *ngIf="auth.isAdmin"> 
              <mat-select [(value)]="selectedAction">
                <mat-option *ngFor="let action of actions" [value]="action">{{action.label}}</mat-option>
              </mat-select>
        </div>
        <button *ngIf="auth.isAdmin" id="action-button" (click)="performAction(selectedAction)">{{strings.artesStrings['PERFORM_ACTION']}}</button>
    </div>
  </div>
  