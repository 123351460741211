import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import * as moment from 'moment';
import { ICertificateHolder } from '../interfaces/i-certificate-holder';
import { IChange } from '../interfaces/i-change';
import { Category } from './category';
import { Certificate } from './certificate';
import { Country } from './country';
import { Employer } from './employer';
import { Engine } from './engine';
import { Language } from './language';
import { License } from './license';
import { Station } from './station';
import { Stretch } from './stretch';
import { StretchSorter } from 'src/helpers/stretchSorter';

export class CertificateHolder {

    private _id: number;
    private _employee_number: string;
    private _first_name: string;
    private _last_name: string;
    private _full_name: string;
    private _birth_date: Date;
    private _birth_place: string;
    private _nationality_id: number;
    private _nationality: Country = new Country();
    private _work_email: string;
    private _license: License = new License();
    private _image: SafeUrl;
    private _categories: Array<Category> = [];
    private _certificates: Array<Certificate> = [];
    private _engines: Array<Engine> = [];
    private _languages: Array<Language> = [];
    private _stations: Array<Station> = [];
    private _stretches: Array<Stretch> = [];
    private _category_char_arr: Array<string> = [];
    private _dirty: boolean = false;
    private _active_certificate: Certificate = new Certificate();
    private _employer_id: number;
    private _employer: Employer = new Employer();
    private _original: ICertificateHolder;
    private sanitizer: DomSanitizer;
    private _change_log: ChangeLog;

    constructor(obj?: ICertificateHolder) {
        if (!obj) return;
        this._original = obj;
        this._id = obj.id;
        this._employee_number = obj.employee_number;
        this._first_name = obj.first_name;
        this._last_name = obj.last_name;
        this._full_name = obj.full_name;
        this._birth_date = (obj.birth_date === null) ? null : moment(obj.birth_date).toDate();
        this._birth_place = obj.birth_place;
        this._nationality_id = obj.nationality_id;
        if (obj.nationality !== undefined) {
            this._nationality = new Country(obj.nationality);
        }
        this._work_email = obj.work_email;
        if (obj.license !== undefined) {
            this._license = new License(obj.license);
        }
        this._image = obj.image;
        if (obj.employer !== undefined) {
            this._employer = new Employer(obj.employer);
        }
        if (obj.categories !== undefined && obj.categories instanceof Array) {
            for (let c of obj.categories) {
                this._categories.push(new Category(c));
            }
        }
        if (obj.certificates !== undefined && obj.certificates instanceof Array) {
            for (let c of obj.certificates) {
                this._certificates.push(new Certificate(c));
            }
        }
        if (obj.active_certificate) {
            this._active_certificate = this.certificates.find((certificate: Certificate) => { return certificate.id === obj.active_certificate.id });
        } else {
            let activeCertificates = this._certificates.filter(e => e.status.status === 'ACTIVE' || e.status.status === 'SUSPENDED');
            if (activeCertificates.length > 0) {
                this._active_certificate = activeCertificates.reduce((a, b) => moment(a.valid_from).isSameOrAfter(moment(b.valid_from)) ? a : b);
            }
        }
        if (!this._active_certificate) this._active_certificate = new Certificate();
        if (obj.engines !== undefined && obj.engines instanceof Array) {
            for (let e of obj.engines) {
                this._engines.push(new Engine(e));
            }
        }
        if (obj.languages !== undefined && obj.languages instanceof Array) {
            for (let l of obj.languages) {
                this._languages.push(new Language(l));
            }
        }
        if (obj.stations !== undefined && obj.stations instanceof Array) {
            for (let s of obj.stations) {
                this._stations.push(new Station(s));
            }
            
            this._stations.sort(StretchSorter.customSorter);
        }
        if (obj.stretches !== undefined && obj.stretches instanceof Array) {
            for (let s of obj.stretches) {
                this._stretches.push(new Stretch(s));
            }

            this._stretches.sort(StretchSorter.customSorter);

        }
        this.setCategoryCharArr();
        this._change_log = new ChangeLog(this._original);
    }

    public toJSON(): Object {
        let categories = this._categories.map((category: Category) => { return category.toJSON() });
        let languages = this._languages.map((language: Language) => { return language.toJSON() });
        let engines = this._engines.map((engine: Engine) => { return engine.toJSON() });
        let stations = this._stations.map((station: Station) => { return station.toJSON() });
        let stretches = this._stretches.map((stretch: Stretch) => { return stretch.toJSON() });
        return {
            id: this._id, employee_number: this._employee_number, first_name: this._first_name,
            last_name: this._last_name, birth_date: moment(this._birth_date).format("YYYY-MM-DD"),
            birth_place: this._birth_place, nationality_id: this._nationality_id,
            work_email: this._work_email, categories: categories, languages: languages,
            engines: engines, stations: stations, stretches: stretches,
            active_certificate: this._active_certificate,
            change_log: this._change_log.toJSON()
        }
    }

    get id(): number {
        return this._id;
    }
    set id(id: number) {
        this._id = id;
    }
    get employee_number(): string {
        return this._employee_number;
    }
    set employee_number(employee_number: string) {
        this._employee_number = employee_number;
    }
    get first_name(): string {
        return this._first_name;
    }
    set first_name(first_name: string) {
        this._first_name = first_name;
    }
    get last_name(): string {
        return this._last_name;
    }
    set last_name(last_name: string) {
        this._last_name = last_name;
    }
    get full_name(): string {
        return this._full_name;
    }
    set full_name(full_name: string) {
        this._full_name = full_name;
    }
    get birth_date(): Date {
        return this._birth_date;
    }
    set birth_date(birth_date: Date) {
        this._birth_date = birth_date;
    }
    get birth_place(): string {
        return this._birth_place;
    }
    set birth_place(birth_place: string) {
        this._birth_place = birth_place;
    }
    get nationality_id(): number {
        return this._nationality_id;
    }
    set nationality_id(nationality_id: number) {
        this._nationality_id = nationality_id;
    }
    get nationality(): Country {
        return this._nationality;
    }
    set nationality(nationality: Country) {
        this._nationality = nationality;
    }
    get work_email(): string {
        return this._work_email;
    }
    set work_email(work_email: string) {
        this._work_email = work_email;
    }
    get license(): License {
        return this._license;
    }
    set license(license: License) {
        this._license = license;
    }
    get image(): SafeUrl {
        return this._image;
    }
    set image(image: SafeUrl) {
        if (image !== undefined) {
            this._image = this.sanitizer.bypassSecurityTrustResourceUrl('data:application/jpg;base64,' + image);
        } else {
            this.sanitizer.bypassSecurityTrustResourceUrl('data:application/jpg;base64,');
        }

        this._image = image;
    }
    get categories(): Array<Category> {
        return this._categories;
    }
    set categories(categories: Array<Category>) {
        this._categories = categories;
    }
    public addCategory(category: Category): void {
        if (this.categories.findIndex(elem => elem.id === category.id) == -1) {
            this._categories.push(category);
            this._dirty = true;
            this._change_log.add({action: ChangeLog.ADDED, constant: ChangeLog.CATEGORY, object: category});
            this.setCategoryCharArr();
        }
    }
    public removeCategory(category: Category): void {
        let idx = this.categories.indexOf(category);
        if (idx > -1) {
            this._categories.splice(idx, 1);
            this._dirty = true;
            this._change_log.add({action: ChangeLog.REMOVED, constant: ChangeLog.CATEGORY, object: category});
            this.setCategoryCharArr();
        }
    }
    get certificates(): Array<Certificate> {
        return this._certificates;
    }
    set certificates(certificates: Array<Certificate>) {
        this._certificates = certificates;
    }
    get engines(): Array<Engine> {
        return this._engines;
    }
    set engines(engines: Array<Engine>) {
        this._engines = engines;
    }
    public addEngine(engine: Engine): void {
        if (this.engines.findIndex(elem => elem.id === engine.id) == -1) {
            this._engines.push(engine);
            this._dirty = true;
            this._change_log.add({action: ChangeLog.ADDED, constant: ChangeLog.ENGINE, object: engine});
        }
    }
    public removeEngine(engine: Engine): void {
        let idx = this.engines.indexOf(engine);
        if (idx > -1) {
            this._engines.splice(idx, 1);
            this._dirty = true;
            this._change_log.add({action: ChangeLog.REMOVED, constant: ChangeLog.ENGINE, object: engine});
        }
    }
    public updateEngine(engine: Engine) {
        const idx = this.engines.indexOf(engine);
        if (idx > -1) {
            this.engines[idx] = engine;
            this._dirty = true;
            this._change_log.add({ action: ChangeLog.UPDATED, constant: ChangeLog.ENGINE, object: engine });
        }
    }
    get languages(): Array<Language> {
        return this._languages;
    }
    set languages(languages: Array<Language>) {
        this._languages = languages;
    }
    public addLanguage(language: Language): void {
        if (this.languages.findIndex(elem => elem.id === language.id) == -1) {
            this._languages.push(language);
            this._dirty = true;
            this._change_log.add({action: ChangeLog.ADDED, constant: ChangeLog.LANGUAGE, object: language});
        }
    }
    public removeLanguage(language: Language): void {
        let idx = this.languages.indexOf(language);
        if (idx > -1) {
            this._languages.splice(idx, 1);
            this._dirty = true;
            this._change_log.add({action: ChangeLog.REMOVED, constant: ChangeLog.LANGUAGE, object: language});
        }
    }
    public updateLanguage(language: Language) {
        const idx = this.languages.indexOf(language);
        if (idx > -1) {
            this.languages[idx] = language;
            this._dirty = true;
            this._change_log.add({ action: ChangeLog.UPDATED, constant: ChangeLog.LANGUAGE, object: language });
        }
    }
    get stations(): Array<Station> {
        return this._stations;
    }
    set stations(stations: Array<Station>) {
        this._stations = stations;
    }
    public addStation(station: Station): void {
        if (this.stations.findIndex(elem => elem.id === station.id) == -1) {
            this._stations.push(station);
            this._dirty = true;
            this._change_log.add({action: ChangeLog.ADDED, constant: ChangeLog.STATION, object: station});
        }
    }
    public removeStation(station: Station): void {
        let idx = this.stations.indexOf(station);
        if (idx > -1) {
            this._stations.splice(idx, 1);
            this._dirty = true;
            this._change_log.add({action: ChangeLog.REMOVED, constant: ChangeLog.STATION, object: station});
        }
    }
    public updateStation(station: Station) {
        const idx = this.stations.indexOf(station);
        if (idx > -1) {
            this.stations[idx] = station;
            this._dirty = true;
            this._change_log.add({ action: ChangeLog.UPDATED, constant: ChangeLog.STATION, object: station });
        }
    }
    get stretches(): Array<Stretch> {
        return this._stretches;
    }
    set stretches(stretches: Array<Stretch>) {
        this._stretches = stretches;
    }
    public addStretches(newStretches: Stretch[], validFrom: Date): void {
      newStretches.forEach(stretch => {

        const index = this.stretches.findIndex(elem => elem.id === stretch.id)
        if (index == -1) {
          stretch.note = ""; // Without this "undefined" would be shown in the note field
          stretch.valid_from = validFrom;

          this._stretches.push(stretch);
          this._dirty = true;
          this._change_log.add({action: ChangeLog.ADDED, constant: ChangeLog.STRETCH, object: stretch});
        } else { // If stretch exists, then mass update.
          this.stretches[index].valid_from = validFrom;
          this._change_log.add({action: ChangeLog.UPDATED, constant: ChangeLog.VALID_FROM, object: stretch});
        }

      });

    }
    public removeStretch(stretch: Stretch): void {
        let idx = this.stretches.indexOf(stretch);
        if (idx > -1) {
            this._stretches.splice(idx, 1);
            this._dirty = true;
            this._change_log.add({action: ChangeLog.REMOVED, constant: ChangeLog.STRETCH, object: stretch});
        }
    }
    public updateStretch(stretch: Stretch) {
        const idx = this.stretches.indexOf(stretch);
        if (idx > -1) {
            this.stretches[idx] = stretch;
            this._dirty = true;
            this._change_log.add({ action: ChangeLog.UPDATED, constant: ChangeLog.STRETCH, object: stretch });
        }
    }
    public getCertificateById(id: number): Certificate {
        for (let cert of this._certificates) {
            if (cert.id === id) return cert;
        }
        return null;
    }
    get employer_id(): number {
        return this._employer_id;
    }
    set employer_id(employer_id) {
        this._employer_id = employer_id;
    }
    get employer(): Employer {
        return this._employer;
    }
    set employer(employer: Employer) {
        this._employer = employer;
    }
    get dirty(): boolean {
        return this._dirty;
    }
    set dirty(dirty: boolean) {
        this._dirty = dirty;
    }
    get active_certificate(): Certificate {
        return this._active_certificate;
    }
    set active_certificate(certificate: Certificate) {
        this._active_certificate = certificate;
    }
    get newest_certificate(): Certificate {
        if (this._active_certificate.id) {
            return this._active_certificate;
        } else {
            let newest: Certificate = null;
            for (let certificate of this._certificates) {
                if (newest === null || certificate.valid_from > newest.valid_from) {
                    newest = certificate;
                }
            }
            return newest;
        }
    }
    get newest_issue_action_id(): number {
        if (this._active_certificate.id !== undefined) {
            for (let action of this._active_certificate.actions) {
                if (action.action_type_id === 1) return action.id;
            }
        } else {
            let newest: Certificate = this.newest_certificate;
            if (newest !== null) {
                for (let action of newest.actions) {
                    if (action.action_type_id === 1) return action.id;
                }
            }
        }
        return null;
    }
    get categoryCharArr(): string[] {
        return this._category_char_arr;
    }
    get original(): ICertificateHolder {
        return this._original;
    }
    set original(original: ICertificateHolder) {
        this._original = original;
    }
    get certificate_valid_from(): Date {
        return this._active_certificate.valid_from;
    }
    set certificate_valid_from(valid_from: Date) {
        this._dirty = true;
        this._active_certificate.valid_from = valid_from;
        this._change_log.add({action: ChangeLog.UPDATED, constant: ChangeLog.VALID_FROM, object: valid_from});
    }
    get certificate_valid_to(): Date {
        return this._active_certificate.valid_to;
    }
    set certificate_valid_to(valid_to: Date) {
        this._dirty = true;
        this._active_certificate.valid_to = valid_to;
        this._change_log.add({action: ChangeLog.UPDATED, constant: ChangeLog.VALID_TO, object: valid_to});
    }
    get additional_information(): string {
        return this._active_certificate.additional_information;
    }
    set additional_information(additional_information: string) {
        this._dirty = true;
        this._active_certificate.additional_information = additional_information;
        this._change_log.add({action: ChangeLog.UPDATED, constant: ChangeLog.ADDITIONAL_INFORMATION, object: additional_information});
    }
    get certificate_language(): Language {
        return this.active_certificate.language
    }
    set certificate_language(language: Language) {
        this._dirty = true;
        this._active_certificate.language_id = language.id;
        this.active_certificate.language = language;
        this._change_log.add({ action: ChangeLog.UPDATED, constant: ChangeLog.CERTIFICATE_LANGUAGE, object: language });
    }
    get category_remark(): string {
        return this._active_certificate.category_remark;
    }
    set category_remark(category_remark: string) {
        this._dirty = true;
        this._active_certificate.category_remark = category_remark;
        this._change_log.add({action: ChangeLog.UPDATED, constant: ChangeLog.CATEGORY_REMARK, object: category_remark});
    }
    get restrictions(): string {
        return this._active_certificate.restrictions;
    }
    set restrictions(restrictions: string) {
        this._dirty = true;
        this._active_certificate.restrictions = restrictions;
        this._change_log.add({action: ChangeLog.UPDATED, constant: ChangeLog.RESTRICTIONS, object: restrictions});
    }
    get language(): Language {
        return this._active_certificate.language;
    }
    set language(language: Language) {
        this._dirty = true;
        this._active_certificate.language = language;
    }
    get temporary(): boolean {
        return this._active_certificate.temporary;
    }
    set temporary(temporary: boolean) {
        this._dirty = true;
        this._active_certificate.temporary = temporary;
    }
    get change_log(): ChangeLog {
        return this._change_log;
    }

    public setCategoryCharArr(): void {
        this._category_char_arr = [];
        let countA: number = 0;
        let maxA: number = 5;
        let countB: number = 0;
        let maxB: number = 2;
        this._categories.sort(this.compareCategories);
        this._categories.forEach(element => {
            if (element.category.includes("A")) {
                countA++;
            } else if (element.category.includes("B")) {
                countB++;
            }
        });

        if (countA === maxA) {
            this._category_char_arr.push("A");
            for (let i=0; i<maxA; i++) { this._category_char_arr.push("***"); }
        } else if (countA === 0) {
            for (let i=0; i<maxA+1; i++) { this._category_char_arr.push("***"); }
        } else {
            this._category_char_arr.push("A");
            this._categories.forEach(element => {
                if (element.category.includes("A")) {
                    this._category_char_arr.push(element.category[1].toString());
                }
            });
            for (let i=0; i<maxA-countA; i++) { this._category_char_arr.push("***"); }
        }

        if (countB === maxB) {
            this._category_char_arr.push("B");
            for (let i=0; i<maxB; i++) { this._category_char_arr.push("***"); }
        } else if (countB === 0) {
            for (let i=0; i<maxB+1; i++) { this._category_char_arr.push("***"); }
        } else {
            this._category_char_arr.push("B");
            this._categories.forEach(element => {
                if (element.category.includes("B")) {
                    this._category_char_arr.push(element.category[1].toString());
                }
            });
            for (let i=0; i<maxB-countB; i++) { this._category_char_arr.push("***"); }
        }
    }

    private compareCategories(categoryA: Category, categoryB: Category): number {
        if (categoryA.category < categoryB.category) return -1;
        if (categoryA.category > categoryB.category) return 1;
        return 0;
    }

    public setActiveCertificate(): void {
        let found: boolean = false;
        for (let cert of this.certificates) {
            if (cert.status_id === 1) {
                let now = moment().startOf('day');
                if (cert.valid_from !== null && cert.valid_from !== undefined) {
                    if (cert.valid_to === null || moment(cert.valid_to).startOf('day') >= now) {
                        this._active_certificate = cert;
                        found = true;
                    }
                }
            }
        }
        if (!found) this._active_certificate = new Certificate();
    }

    public clone(): any {
        return JSON.stringify(this);
    }

    public isValidForUpdate(): boolean {
        for (let engine of this._engines) {
            if (engine.valid_from === null) return false;
        }
        for (let language of this._languages) {
            if (language.valid_from === null) return false;
        }
        for (let station of this._stations) {
            if (station.valid_from === null) return false;
        }
        for (let stretch of this._stretches) {
            if (stretch.valid_from === null) return false;
        }
        if (this._active_certificate.valid_from === null) return false;
        return true;
    }

}

export class ChangeLog {

    public static UPDATED: string = "UPDATED";
    public static REMOVED: string = "REMOVED";
    public static ADDED: string = "ADDED";

    public static CATEGORY: string = "CATEGORY";
    public static ADDITIONAL_INFORMATION: string = "ADDITIONAL_INFORMATION";
    public static LANGUAGE: string = "LANGUAGE";
    public static RESTRICTIONS: string = "RESTRICTIONS";
    public static ENGINE: string = "ENGINE";
    public static STATION: string = "STATION";
    public static STRETCH: string = "STRETCH";
    public static VALID_FROM: string = "VALID_FROM";
    public static VALID_TO: string = "VALID_TO";
    public static CATEGORY_REMARK: string = "CATEGORY_REMARK";
    public static CERTIFICATE_LANGUAGE: string = "CERTIFICATE_LANGUAGE";
    public static CERTIFICATE_STATUS: string = "CERTIFICATE_STATUS";

    private _log: Array<IChange> = [];
    private _original: any;

    constructor(original?: any) {
        this._original = original;
    }

    get log(): Array<IChange> {
        return this._log;
    }

    public toJSON(): Object {
        return {
            log: this._log
        }
    }

    public add(change: IChange): void {
        //Check if change is a duplicate
        let idx: number = this.indexOf(change);
        if (idx != -1) {
            switch (change.action) {
                case ChangeLog.ADDED:
                case ChangeLog.REMOVED:
                    this._log.splice(idx, 1);
                    return;
                case ChangeLog.UPDATED:
                    if (!this.differentFromOriginal(change)) {
                        this._log.splice(idx, 1);
                        return;
                    }
                default: return;
            }
            /*
            //If change object does not have id (if it is string), do nothing
            if (change.object['id'] === undefined) {
                this._log[idx].object = change.object;
                return;
            }
            //Remove duplicate change
            this._log.splice(idx, 1);
            //If change is of certificate language
            if (change.constant == ChangeLog.CERTIFICATE_LANGUAGE) {
                let tmp = new CertificateHolder(this._original);
                if (change.object['id'] != tmp?.active_certificate?.language_id) {
                    this._log.unshift(change);
                }
            }*/
        } else {
            //If not duplicate, add to changes
            this._log.unshift(change);
        }
    }

    private indexOf(change: IChange): number {
        for (let i=0; i<this._log.length; i++) {
            if (this._log[i].constant === change.constant) {
                if (change.action === ChangeLog.UPDATED && change.action === this._log[i].action) {
                    if (typeof change.object === 'string') return i;
                    else if (change.object.id === this._log[i].object.id) return i;
                }
                else if (change.action === ChangeLog.REMOVED && change.object === this._log[i].object) return i;
                else if (change.action === ChangeLog.ADDED && change.object['id'] === this._log[i].object['id']) return i;
            }
        }
        return -1;
    }

    private differentFromOriginal(change: IChange): boolean {
        switch (change.constant) {
            case ChangeLog.STRETCH:
                const stretch = this._original.stretches.find(e => e.id === change.object.id);
                if (stretch) {
                    return (
                        stretch._pivot_valid_from !== (change.object._valid_from ? moment(change.object._valid_from).format('YYYY-MM-DD') : null) ||
                        stretch._pivot_note !== change.object.note
                    )
                };
                return false;
            case ChangeLog.STATION:
                const station = this._original.stations.find(e => e.id === change.object.id)
                if (station) {
                    return (
                        station._pivot_valid_from !== (change.object._valid_from ? moment(change.object._valid_from).format('YYYY-MM-DD') : null) ||
                        station._pivot_note !== change.object.note ||
                        station._pivot_last_used !== (change.object._last_used ? moment(change.object._last_used).format('YYYY-MM-DD') : null) ||
                        station._pivot_last_used_outside_secure_area !== (change.object._last_used_outside_secure_area ? moment(change.object._last_used_outside_secure_area).format('YYYY-MM-DD') : null)
                    )
                }
                return false;
            case ChangeLog.LANGUAGE:
                const language = this._original.languages.find(e => e.id === change.object.id)
                if (language) {
                    return (
                        language._pivot_valid_from !== (change.object._valid_from ? moment(change.object._valid_from).format('YYYY-MM-DD') : null) ||
                        language._pivot_note !== change.object.note
                    )
                }
                return false;
            case ChangeLog.ENGINE:
                const engine = this._original.engines.find(e => e.id === change.object.id)
                if (engine) {
                    return (
                        engine._pivot_valid_from !== (change.object._valid_from ? moment(change.object._valid_from).format('YYYY-MM-DD') : null) ||
                        engine._pivot_note !== change.object._note ||
                        engine._pivot_last_used !== (change.object._last_used ? moment(change.object._last_used).format('YYYY-MM-DD') : null)
                    )
                }
                return false;
            case ChangeLog.CATEGORY:
                return true;
            case ChangeLog.ADDITIONAL_INFORMATION:
                return this._original.certificates[this._original.certificates.length - 1]?.additional_information !== change.object;
            case ChangeLog.RESTRICTIONS:
                return this._original.certificates[this._original.certificates.length - 1]?.restrictions !== change.object;
            case ChangeLog.VALID_FROM:
                return this._original.certificates[this._original.certificates.length - 1]?.valid_from !== change.object;
            case ChangeLog.VALID_TO:
                return this._original.certificates[this._original.certificates.length - 1]?.valid_to !== change.object;
            case ChangeLog.CATEGORY_REMARK:
                return this._original.certificates[this._original.certificates.length - 1]?.category_remark !== change.object;
            case ChangeLog.CERTIFICATE_LANGUAGE:
                return this._original.certificates[this._original.certificates.length - 1]?.language_id !== change.object.id;
            case ChangeLog.CERTIFICATE_STATUS:
                return this._original.certificates[this._original.certificates.length - 1]?.status_id !== change.object.id;
            default:
                return false;
        }
    }
}
