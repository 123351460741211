<div class="expage">
    <div id="toppart">
        <div>
            <div class="leftcontainer" id="dbholder">
                <img src="assets/img/LogoDB.png" style="height:2.5cm;float:left;" />
            </div>
            <div id="title-right" class="blue" style="margin-top:1.3cm;">
                <p class="bold textdown">{{strings['COPY_COMPLEMENTARY']}}</p>
            </div>
            <div id="imageholder">
                <img src="assets/img/eu_large.png" style="height:2.5cm;float:right;" />
            </div>
        </div>
        
        <div style="clear:both;"></div>
        
        
        <div style="margin-top:0cm;">
            <div class="leftcontainer largeheight">
            </div>
            <div id="subtitle-right" class="underline">
                <p>{{strings['ISSUED_IN_ACCORDANCE']}} ,<br> {{strings['DOES_NOT_AUTHORISE']}}</p>
            </div>
        </div>
    </div>
    
    <div class="largejump"></div>
    
    <div id="part1">
        <div class="bold blue leftcontainer largeheight">
            <p class="lefttext textdown">1. {{strings['EMPLOYER']}} -<br> {{strings['CONTRACTER']}}</p>
        </div>
        <div class="fullborder rightcontainer largeheight">
            <p class="lefttext textdown">{{holder.employer.name}}</p>
        </div>

        <div style="clear:both"></div>
        
        <div id="typeholder">
            <p class="left" style="margin-right:0.3cm;">{{strings['RAILWAY_UNDERTAKING']}}</p>
            <div class="checkbox left" style="position:relative;">
                <div class="bold alignbox" style="font-size:0.8cm;line-height:100%;" *ngIf="holder.employer.employer_type_id==1">X</div>
            </div>
            <p class="left" style="margin-left: 1cm;margin-right: 0.3cm;">{{strings['INFRASTRUCTURE_MANAGER']}}</p>
            <div class="checkbox left" style="position:relative;">
                <div class="bold alignbox" style="font-size:0.8cm;line-height:100%;" *ngIf="holder.employer.employer_type_id==2">X</div>
            </div>
            <div style="clear: both;"></div>
        </div>
    </div>
    
    <div class="smalljump"></div>
    
    <div id="part2">
        <div class="leftcontainer smallheight">
            <p class="lefttext textdown">{{strings['POSTAL_ADDRESS']}}</p>
        </div>
        <div class="fullborder rightcontainer smallheight">
            <p class="lefttext textdown">{{holder.employer.address}}</p>
        </div>
        
        <div style="clear:both"></div>
        
        <div class="leftcontainer smallheight">
            <p class="lefttext textdown">{{strings['POST_CODE_AND_CITY']}}</p>
        </div>
        <div>
            <div class="fullborder rightcontainer smallheight" style="width:8.3cm;border-top:none;">
                <p class="lefttext textdown">{{holder.employer.zipcode}}</p>
            </div>
            <div class="fullborder rightcontainer smallheight" style="width:8.3cm;border-top:none;border-left:none;">
                <p class="lefttext textdown">{{holder.employer.city}}</p>
            </div>
            <div class="fullborder rightcontainer smallheight" style="width:8.3cm;border-top:none;border-left:none;">
                <p class="lefttext textdown">{{strings[holder.employer.country.country]}}</p>
            </div>
        </div>
        
        <div style="clear:both;"></div>
        <div class="leftcontainer smallheight">
            <p class="lefttext textdown">{{strings['HOLDER_REFERENCE_NUMBER']}}</p>
        </div>
        <div class="fullborder rightcontainer smallheight" style="border-top:none;text-align:left;">
            <p class="lefttext textdown">{{holder.employee_number}}</p>
        </div>
        
        <div style="clear:both"></div>
        <div class="smalljump"></div>
        
        <div class="bold blue leftcontainer mediumheight">
            <p class="lefttext textdown">2. {{strings['HOLDER']}}</p>
        </div>
        <div class="fullborder rightcontainer mediumheight">
            <div class="fullborder rightcontainer largeheight" style="width:24.5cm;height:1.1cm;margin-left:0.2cm;margin-top:0.3cm;">
                <div class="leftcontainer smallheight" style="border-right:1px solid black;height:1.1cm;">
                    <p class="lefttext textdown">{{strings['LICENSE_NUMBER']}}</p>
                </div>
                <div class="leftcontainer smallheight" style="border-right:1px solid black;height:1.1cm;width:1.4cm;line-height:250%;">
                    <p class="aligntext red bold" style="font-size:.8cm;"><span *ngIf="holder.license.license_number">{{holder.license.license_number[0]}}</span></p>
                </div>
                <div class="leftcontainer smallheight" style="border-right:1px solid black;height:1.1cm;width:1.4cm;line-height:250%;">
                    <p class="aligntext red bold" style="font-size:.8cm;"><span *ngIf="holder.license.license_number">{{holder.license.license_number[1]}}</span></p>
                </div>
                <div class="leftcontainer smallheight" style="border-right:1px solid black;height:1.1cm;width:1.4cm;line-height:250%;">
                    <p class="aligntext red bold" style="font-size:.8cm;"><span *ngIf="holder.license.license_number">{{holder.license.license_number[2]}}</span></p>
                </div>
                <div class="leftcontainer smallheight" style="border-right:1px solid black;height:1.1cm;width:1.4cm;line-height:250%;">
                    <p class="aligntext red bold" style="font-size:.8cm;"><span *ngIf="holder.license.license_number">{{holder.license.license_number[3]}}</span></p>
                </div>
                <div class="leftcontainer smallheight" style="border-right:1px solid black;height:1.1cm;width:1.4cm;line-height:250%;">
                    <p class="aligntext red bold" style="font-size:.8cm;"><span *ngIf="holder.license.license_number">{{holder.license.license_number[4]}}</span></p>
                </div>
                <div class="leftcontainer smallheight" style="border-right:1px solid black;height:1.1cm;width:1.4cm;line-height:250%;">
                    <p class="aligntext red bold" style="font-size:.8cm;"><span *ngIf="holder.license.license_number">{{holder.license.license_number[5]}}</span></p>
                </div>
                <div class="leftcontainer smallheight" style="border-right:1px solid black;height:1.1cm;width:1.4cm;line-height:250%;">
                    <p class="aligntext red bold" style="font-size:.8cm;"><span *ngIf="holder.license.license_number">{{holder.license.license_number[6]}}</span></p>
                </div>
                <div class="leftcontainer smallheight" style="border-right:1px solid black;height:1.1cm;width:1.4cm;line-height:250%;">
                    <p class="aligntext red bold" style="font-size:.8cm;"><span *ngIf="holder.license.license_number">{{holder.license.license_number[7]}}</span></p>
                </div>
                <div class="leftcontainer smallheight" style="border-right:1px solid black;height:1.1cm;width:1.4cm;line-height:250%;">
                    <p class="aligntext red bold" style="font-size:.8cm;"><span *ngIf="holder.license.license_number">{{holder.license.license_number[8]}}</span></p>
                </div>
                <div class="leftcontainer smallheight" style="border-right:1px solid black;height:1.1cm;width:1.4cm;line-height:250%;">
                    <p class="aligntext red bold" style="font-size:.8cm;"><span *ngIf="holder.license.license_number">{{holder.license.license_number[9]}}</span></p>
                </div>
                <div class="leftcontainer smallheight" style="border-right:1px solid black;height:1.1cm;width:1.4cm;line-height:250%;">
                    <p class="aligntext red bold" style="font-size:.8cm;"><span *ngIf="holder.license.license_number">{{holder.license.license_number[10]}}</span></p>
                </div>
                <div class="leftcontainer smallheight" style="border:none;line-height:250%;height:1.1cm;width:1.4cm;">
                    <div class="aligntext red bold" style="font-size:.8cm;"><span *ngIf="holder.license.license_number">{{holder.license.license_number[11]}}</span></div>
                </div>
            </div>
        </div>
        
        <div style="clear:both;"></div>
        
        <div class="leftcontainer smallheight">
            <p class="lefttext textdown">{{strings['SURNAMES']}}</p>
        </div>
        <div class="fullborder rightcontainer smallheight" style="border-top:none;width:15cm;">
            <p class="lefttext textdown">{{holder.last_name}}</p>
        </div>
        <div class="fullborder rightcontainer smallheight" style="border-left:none;border-top:none;width:9.9cm;"></div>
        
        <div style="clear:both;"></div>
        
        <div class="leftcontainer smallheight">
            <p class="lefttext textdown">{{strings['FIRSTNAMES']}}</p>
        </div>
        <div class="fullborder rightcontainer smallheight" style="border-top:none;width:15cm;">
            <p class="lefttext textdown">{{holder.first_name}}</p>
        </div>
        <div class="fullborder rightcontainer smallheight" style="border-left:none;border-top:none;width:9.9cm;"></div>
    
        <div style="clear:both;"></div>
        
        <div class="leftcontainer smallheight">
            <p class="lefttext textdown">{{strings['BIRTHPLACE']}}</p>
        </div>
        <div class="fullborder rightcontainer smallheight" style="border-top:none;width:15cm;">
            <p class="lefttext textdown">{{holder.birth_place}}</p>
        </div>
        <div class="fullborder rightcontainer smallheight" style="border-left:none;border-top:none;border-bottom:none;width:9.9cm;"></div>
    
        <div style="clear:both;"></div>
        
        <div class="leftcontainer smallheight">
            <p class="lefttext textdown">{{strings['BIRTHDATE']}}</p>
        </div>
        <div class="fullborder rightcontainer smallheight" style="border-top:none;width:15cm;">
            <p class="lefttext textdown">{{dateFormatter.dateToStr(holder.birth_date, 'ddmmyyyy')}}</p>
        </div>
        <div class="fullborder rightcontainer smallheight" style="border-left:none;border-top:none;border-bottom:none;width:9.9cm;"></div>
    
        <div style="clear:both;"></div>
        
        <div class="leftcontainer smallheight">
            <p class="lefttext textdown">{{strings['NATIONALITY']}}</p>
        </div>
        <div class="fullborder rightcontainer smallheight" style="border-top:none;width:15cm;">
            <p class="lefttext textdown">{{strings[holder.nationality.country]}}</p>
        </div>
        <div class="fullborder rightcontainer smallheight" style="border-left:none;border-top:none;border-bottom:none;width:9.9cm;"></div>
    
        <div style="clear:both;"></div>
        
        <div class="leftcontainer smallheight">
            <p class="lefttext textdown">{{strings['POSTAL_ADDRESS']}} ({{strings['OPTIONAL']}})</p>
        </div>
        <div class="fullborder rightcontainer smallheight" style="height:4cm;width:15cm;border-top:none;">
            <p class="lefttext textdown"></p>
        </div>
        <div class="fullborder rightcontainer" style="border-left:none;border-top:none;width:9.9cm;height:6cm;margin-top:-2cm;">
            <div id="photosignaturecontainer" style="height:6cm;">
                <div id="exphoto">
                    <span class="helper"></span><img id="image" [src]="sanitizer.bypassSecurityTrustResourceUrl('data:application/jpg;base64,' + this.holder.image)" />
                    <div style="width:5cm;border-bottom:1px solid black;margin-top:1cm;margin-left:-1cm;"></div>
                    <p>{{strings['SIGNATURE']}}</p>
                </div>
                
            </div>
        </div>
        </div>
        <div style="clear:both;"></div>
        
        
    
    
    <div style="clear:both;"></div>
    <div class="smalljump"></div>
    
    <div id="part3">
        <div class="bold blue leftcontainer largeheight">
            <p class="lefttext textdown">3. {{strings['CATEGORIES_OF_DRIVING']}}</p>
        </div>
        <div class="fullborder rightcontainer largeheight" style="width:17.5cm;">
            <div class="fullborder right" style="width:6cm;height:80%;margin-top:0.25cm;margin-right:0.25cm;">
                <p class="lefttext textdown" id="xCategoryRemark" style="margin-top:-0.05cm;"></p>
            </div>
            <div class="right">
                <div class="smallheight" style="text-align:center;margin-right:0.5cm;line-height:300%;">
                    <div class="checkbox right" style="position:relative">
                        <p class="bold alignbox" style="font-size:0.5cm;" *ngIf="holder.categoryCharArr[0]=='A'">X</p>
                        <p class="bold alignbox" *ngIf="holder.categoryCharArr[0]!='A'">{{holder.categoryCharArr[0]}}</p>
                    </div>
                    <p class="bold right" style="font-size:0.5cm;margin-right: 0.3cm;line-height:0">A</p>
                </div>
                <div class="smallheight" style="text-align:center;margin-top:0.6cm;margin-right:0.5cm;">
                    <div class="checkbox right" style="position:relative;margin-left:1.3cm;margin-top:0;">
                        <p class="bold alignbox">{{holder.categoryCharArr[5]}}</p>
                    </div>
                    <div class="checkbox right" style="position:relative;margin-left:1.3cm;margin-top:0;">
                        <p class="bold alignbox">{{holder.categoryCharArr[4]}}</p>
                    </div>
                    <div class="checkbox right" style="position:relative;margin-left:1.3cm;margin-top:0;">
                        <p class="bold alignbox">{{holder.categoryCharArr[3]}}</p>
                    </div>
                    <div class="checkbox right" style="position:relative;margin-left:1.3cm;margin-top:0;">
                        <p class="bold alignbox">{{holder.categoryCharArr[2]}}</p>
                    </div>
                    <div class="checkbox right" style="position:relative;margin-left:1.3cm;margin-top:0;">
                        <p class="bold alignbox">{{holder.categoryCharArr[1]}}</p>
                    </div>
                    
                </div>
            </div>
            
        </div>
        <div class="fullborder rightcontainer largeheight" style="width:7cm;margin-left:0.35cm;">
            <div class="smallheight" style="text-align:center;line-height:300%;width:1.3cm;margin:0 auto;margin-left:calc(50% - .65cm - .4cm);">
                <p class="bold left" style="font-size:0.5cm;line-height:0">B</p>
                <div class="checkbox right" style="position:relative">
                    <p class="bold alignbox" style="font-size:0.5cm;" *ngIf="holder.categoryCharArr[6]=='B'">X</p>
                    <p class="bold alignbox" *ngIf="holder.categoryCharArr[6]!='B'">{{holder.categoryCharArr[6]}}</p>
                </div>
            </div>
            <div class="smallheight" style="text-align:center;margin-top:0.6cm;">
                <div class="checkbox left" style="position:relative;margin-left:1cm;margin-top:0;">
                    <p class="bold alignbox">{{holder.categoryCharArr[7]}}</p>
                </div>
                <div class="checkbox right" style="position:relative;margin-right:1cm;margin-top:0;">
                    <p class="bold alignbox">{{holder.categoryCharArr[8]}}</p>
                </div>
            </div>
        </div>
    </div>
    
    <div style="clear:both;"></div>
    <div class="smalljump"></div>
    
    <div id="part4">
        <div class="bold blue leftcontainer largeheight">
            <p class="lefttext textdown">4. {{strings['ADDITIONAL_INFORMATION']}}</p>
        </div>
        <div class="fullborder rightcontainer largeheight">
            <p class="lefttext textdown">{{holder.active_certificate.additional_information}}</p>
        </div>
    </div>
    
    <div style="clear:both;"></div>
    <div class="smalljump"></div>
    
    <div id="part5">
        <div class="bold blue leftcontainer largeheight">
            <p id="exportrestrictions" class="lefttext textdown">5. {{strings['RESTRICTIONS']}}</p>
        </div>
        <div class="fullborder rightcontainer largeheight">
            <p class="lefttext textdown">{{holder.active_certificate.restrictions}}</p>
        </div>
    </div>
    
    <div style="clear:both;"></div>
    <div class="smalljump"></div>
    
    <div id="part6">
        <div class="bold blue leftcontainer largeheight" style="height:4cm;">
            <p id="exportlanguageskills" class="lefttext textdown">6. {{strings['LANGUAGE_SKILLS']}}</p>
        </div>
        <div class="fullborder rightcontainer largeheight" style="height:4cm;">
            <div *ngFor="let language of holder.languages">
                <p class="lefttext textdown">
                    {{strings['VALIDFROM']}} {{dateFormatter.dateToStr(language.valid_from, 'ddmmyyyy')}} | <b>{{strings[language.iso2_code | uppercase]}}</b>
                </p>
            </div>
        </div>
    </div>
</div>