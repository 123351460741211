<div class="pageone">
    <div id="left">
        <div id="topbar">
            <p class="certificate-header">{{strings['COMPLEMENTARY_CERTIFICATE_FOR_TRAIN_DRIVERS']}}</p>
            <div class="languageline" style="margin-top:-0.2cm"></div>
        </div>
        <div id="categories">
            <div class="section-header">3. {{strings['CATEGORIES_OF_DRIVING']}}</div>
            <p>{{strings['FILL_IN_RELEVANT']}}</p>
            <div class="cubecontainer">
                <div class="cube"><p class="cubetext certCategoriesA">{{holder.categoryCharArr[0]}}</p></div>
                <div class="cube"><p id="certCategoryA1" class="cubetext certCategories">{{holder.categoryCharArr[1]}}</p></div>
                <div class="cube"><p id="certCategoryA2" class="cubetext certCategories">{{holder.categoryCharArr[2]}}</p></div>
                <div class="cube"><p id="certCategoryA3" class="cubetext certCategories">{{holder.categoryCharArr[3]}}</p></div>
                <div class="cube"><p id="certCategoryA4" class="cubetext certCategories">{{holder.categoryCharArr[4]}}</p></div>
                <div class="cube"><p id="certCategoryA5" class="cubetext certCategories">{{holder.categoryCharArr[5]}}</p></div>
            </div>
            <div style="clear:both;"></div>
            <div class="cubecontainer" style="clear:both;">
                <div class="cube"><p class="cubetext certCategoriesB">{{holder.categoryCharArr[6]}}</p></div>
                <div class="cube"><p id="certCategoryB1" class="cubetext certCategories">{{holder.categoryCharArr[7]}}</p></div>
                <div class="cube"><p id="certCategoryB2" class="cubetext certCategories">{{holder.categoryCharArr[8]}}</p></div>
            </div>
            <div id="remarks" style="clear:both;">
                <p id="rem">{{strings['NOTES']}}:</p>
                <p class="inputtext" id="certRemark">{{holder.active_certificate.category_remark}}</p>
            </div>
        </div>
        <div id="additionalinfo" style="margin-top:-0.2cm;">
            <div class="section-header">4. {{strings['ADDITIONAL_INFORMATION']}}</div>
            <p style="color:red;position:absolute;width:9.6cm;margin-left:0.7cm;text-align:left;margin-top:-0.35cm;">{{holder.active_certificate.additional_information}}</p>
            <div class="writingline"></div>
            <div class="writingline"></div>
            <div class="writingline"></div>
        </div>
        <div id="languageskills">
            <div class="section-header" id="certificate-languageskills">5. {{strings['LANGUAGE_SKILLS']}}</div>
            <p id="certificate-linguisticsknowledge" style="margin-left:1.4cm;margin-right:1.4cm;">{{strings['LINGUISTICS_KNOWLEDGE']}}</p>
            <div class="languageline" style="margin-top:0.5cm;">
                <p class="inputtext lefttext certificate-date" style="color:black;margin-left:0cm;float:left;margin-top:-0.4cm;">{{strings['DATE']}}</p>
                <p id="certificate-language" class="inputtext lefttext" style="color:black;margin-left:2.3cm;float:left;margin-top:-0.4cm;">{{strings['LANGUAGE']}}</p>
                <p class="inputtext lefttext certificate-notes" style="color:black;float:right;margin-right:0.5cm;margin-top:-0.4cm;">{{strings['NOTE']}}</p>
            </div>
            <div style="clear:both;"></div>
            <div class="languageline">
                <div *ngIf="holder.languages.length > 0">
                    <p class="inputtext lefttext langline" style="margin-left:0cm;float:left;margin-top:-0.4cm;width:2.1cm;height:0.3cm;" id="certLanguageDate1">{{dateFormatter.dateToStr(holder.languages[0].valid_from, 'ddmmyyyy')}}</p>
                    <p class="inputtext lefttext langline" style="margin-left:2.3cm;float:left;margin-top:-0.4cm;" id="certLanguage1">{{strings[(holder.languages[0].iso2_code | uppercase)]}}</p>
                    <p class="inputtext lefttext langline" style="float:right;margin-right:0.5cm;margin-top:-0.4cm;" id="certLanguageNote1">{{holder.languages[0].note}}</p>
                </div>
            </div>
            <div style="clear:both;"></div>
            <div class="languageline">
              <div *ngIf="holder.languages.length > 1">
                  <p class="inputtext lefttext langline" style="margin-left:0cm;float:left;margin-top:-0.4cm;width:2.1cm;height:0.3cm" id="certLanguageDate2">{{dateFormatter.dateToStr(holder.languages[1].valid_from, 'ddmmyyyy')}}</p>
                  <p class="inputtext lefttext langline" style="margin-left:2.3cm;float:left;margin-top:-0.4cm;" id="certLanguage2">{{strings[(holder.languages[1].iso2_code | uppercase)]}}</p>
                  <p class="inputtext lefttext langline" style="float:right;margin-right:0.5cm;margin-top:-0.4cm;" id="certLanguageNote2">{{holder.languages[1].note}}</p>
              </div>
            </div>
            <div style="clear:both;"></div>
            <div class="languageline">
              <div *ngIf="holder.languages.length > 2">
                <p class="inputtext lefttext langline" style="margin-left:0cm;float:left;margin-top:-0.4cm;width:2.1cm;height:0.3cm;" id="certLanguageDate3">{{dateFormatter.dateToStr(holder.languages[2].valid_from, 'ddmmyyyy')}}</p>
                <p class="inputtext lefttext langline" style="margin-left:2.3cm;float:left;margin-top:-0.4cm;" id="certLanguage3">{{strings[(holder.languages[2].iso2_code | uppercase)]}}</p>
                <p class="inputtext lefttext langline" style="float:right;margin-right:0.5cm;margin-top:-0.4cm;" id="certLanguageNote3">{{holder.languages[2].note}}</p>
              </div>
            </div>
        </div>
        <div id="restrictions" style="margin-top:-0.3cm;">
            <div class="section-header" id="certificate-restrictions">6. {{strings['RESTRICTIONS']}}</div>
            <p class="inputtext" style="position:absolute;width:9.6cm;margin-left:0.7cm;margin-top:-0.4cm;text-align:left;" id="certRestrictions">{{holder.active_certificate.restrictions}}</p>
            <div class="writingline" style="color:red;"></div>
            <div class="writingline" style="color:red;"></div>
            <div class="writingline" style="color:red;"></div>
        </div>
    </div>
    <div id="middle">
        <div id="topbar">
            <p class="certificate-header">{{strings['COMPLEMENTARY_CERTIFICATE_FOR_TRAIN_DRIVERS']}}</p>
            <div class="languageline" style="margin-top:-0.2cm"></div>
        </div>
        <div id="employer">
            <div class="section-header" id="emp">1. {{strings['DATA_OF_EMPLOYER']}}</div>
            <div id="empname" style="margin-top:-0.2cm;">
                <div class="writingline"><p id="certEmployer" class="inputtext" style="font-size:0.4cm;">{{holder.employer.name}}</p></div>
                <p id="certificate-legalname" style="margin-top:-0.4cm;">{{strings['LEGAL_NAME']}}</p>
            </div>
        </div>
        <div id="type">
            <p id="certificate-railwayundertaking" class="typetext">{{strings['RAILWAY_UNDERTAKING']}}</p>
            <div class="typebox"><p id="certRail" class="marktext" ng-if="certificateHolder.employer.type==1">X</p></div>
            <p id="certificate-infrastructuremanager" class="typetext">{{strings['INFRASTRUCTURE_MANAGER']}}</p>
            <div class="typebox"><p id="certInfra" class="marktext" ng-if="certificateHolder.emplyer.type==2">X</p></div>
        </div>
        <div id="employerinfo">
            <div class="writingline"><p id="certEmpWorkplace" class="inputtext lefttext">{{holder.employer.city}}</p></div>
            <p id="certificate-workplace" class="lefttext">{{strings['WORKPLACE']}}</p>
            <div class="writingline"><p id="certEmpAddress" class="inputtext lefttext">{{holder.employer.address}}</p></div>
            <p class="lefttext certificate-postaladdress">{{strings['POSTAL_ADDRESS']}}</p>
            <div class="writingline"><p id="certEmpCityCountry" class="inputtext lefttext">{{holder.employer.zipcode}} {{holder.employer.city}} - {{strings[holder.employer.country.toString()]}}</p></div>
            <p id="certificate-citycountry" class="lefttext">{{strings['CITY']}} - {{strings['COUNTRY']}}</p>
        </div>
        <div id="holder" style="margin-top: -0.3cm;">
            <div class="section-header" id="certificate-dataofholder">2. {{strings['DATA_OF_HOLDER']}}</div>
            <div id="birthplace" style="margin-top:-0.2cm;">
                <div class="writingline"><p id="certBirthplace" class="inputtext lefttext">{{holder.birth_place}}</p></div>
                <p id="certificate-birthplace" class="lefttext">{{strings['BIRTHPLACE']}}</p>
            </div>
            <div id="birthday" style="margin-top:-0.1cm;">
                <p id="certificate-birthday" class="btext1">{{strings['BIRTHDATE']}}</p>
                <div class="bcube"><p id="certBirth0" class="lictext">{{dateFormatter.dateToSingle(holder.birth_date, 'year', 0)}}</p></div>
                <div class="bcube"><p id="certBirth1" class="lictext">{{dateFormatter.dateToSingle(holder.birth_date, 'year', 1)}}</p></div>
                <div class="bcube"><p id="certBirth2" class="lictext">{{dateFormatter.dateToSingle(holder.birth_date, 'year', 2)}}</p></div>
                <div class="bcube"><p id="certBirth3" class="lictext">{{dateFormatter.dateToSingle(holder.birth_date, 'year', 3)}}</p></div>
                <p class="btext">-</p>
                <div class="bcube"><p id="certBirth4" class="lictext">{{dateFormatter.dateToSingle(holder.birth_date, 'month', 0)}}</p></div>
                <div class="bcube"><p id="certBirth5" class="lictext">{{dateFormatter.dateToSingle(holder.birth_date, 'month', 1)}}</p></div>
                <p class="btext">-</p>
                <div class="bcube"><p id="certBirth6" class="lictext">{{dateFormatter.dateToSingle(holder.birth_date, 'date', 0)}}</p></div>
                <div class="bcube"><p id="certBirth7" class="lictext">{{dateFormatter.dateToSingle(holder.birth_date, 'date', 1)}}</p></div>
            </div>
            <div id="holderrest" style="margin-top:-0.7cm;">
                <div class="writingline"><p id="certNationality" class="inputtext lefttext" *ngIf="holder.nationality">{{strings[holder.nationality.country]}}</p></div>
                <p id="certificate-nationality" class="lefttext">{{strings['NATIONALITY']}}</p>
                <div class="writingline"><p id="certAddress" class="inputtext lefttext">&nbsp;</p></div>
                <p class="lefttext certificate-postaladdress">{{strings['POSTAL_ADDRESS']}}</p>
                <div class="writingline"><p id="certCity" class="inputtext lefttext certificate-city">&nbsp;</p></div>
                <p id="certificate-city" class="lefttext">{{strings['CITY']}}</p>
                <div class="writingline"><p id="certCountry" class="inputtext lefttext">&nbsp;</p></div>
                <p id="certificate-country" class="lefttext">{{strings['CITY']}} - {{strings['COUNTRY']}}</p>
                <div class="writingline" style="margin-top: -0.1cm;"></div>
                <p id="certificate-signature" class="lefttext" style="margin-top:-0.4cm;">{{strings['SIGNATURE']}}</p>
                <div id="photoholder">
                    <span class="helper"></span><img id="photo" *ngIf="this.holder.image" [src]="sanitizer.bypassSecurityTrustResourceUrl('data:application/jpg;base64,' + this.holder.image)" />
                </div>
            </div>
        </div>
    </div>
    <div id="right">
        <div id="images" style="margin-bottom:1.25cm">
            <img src="assets/img/LogoDB.png" style="height:1.5cm;float:left;margin-left:1.25cm;margin-top:-0.35cm;" />
            <img src="assets/img/logo-eu.png" style="height:2cm;float:right;margin-right:1.25cm;margin-top:-0.55cm;" />
        </div>
        <div style="clear:both;"></div>
        <div id="licensenumber" style="text-align:center;margin-left:0.4cm;">
            <div style="margin-right:0cm;margin-left:0.0cm;float:left;width:1.6cm;">
                <p id="certificate-referencelicense" style="font-size:0.2cm;">{{strings['REFERENCE_TO_LICENSE_NUMBER']}}</p>
            </div>
            <div style="margin:auto;">
                <div class="licensebox"><p id="certLicense0" class="lictext" *ngIf="holder.license.license_number">{{holder.license.license_number[0]}}</p></div>
                <div class="licensebox"><p id="certLicense1" class="lictext" *ngIf="holder.license.license_number">{{holder.license.license_number[1]}}</p></div>
                <div class="licensebox"><p id="certLicense2" class="lictext" *ngIf="holder.license.license_number">{{holder.license.license_number[2]}}</p></div>
                <div class="licensebox"><p id="certLicense3" class="lictext" *ngIf="holder.license.license_number">{{holder.license.license_number[3]}}</p></div>
                <div class="licensebox"><p id="certLicense4" class="lictext" *ngIf="holder.license.license_number">{{holder.license.license_number[4]}}</p></div>
                <div class="licensebox"><p id="certLicense5" class="lictext" *ngIf="holder.license.license_number">{{holder.license.license_number[5]}}</p></div>
                <div class="licensebox"><p id="certLicense6" class="lictext" *ngIf="holder.license.license_number">{{holder.license.license_number[6]}}</p></div>
                <div class="licensebox"><p id="certLicense7" class="lictext" *ngIf="holder.license.license_number">{{holder.license.license_number[7]}}</p></div>
                <div class="licensebox"><p id="certLicense8" class="lictext" *ngIf="holder.license.license_number">{{holder.license.license_number[8]}}</p></div>
                <div class="licensebox"><p id="certLicense9" class="lictext" *ngIf="holder.license.license_number">{{holder.license.license_number[9]}}</p></div>
                <div class="licensebox"><p id="certLicense10" class="lictext" *ngIf="holder.license.license_number">{{holder.license.license_number[10]}}</p></div>
                <div class="licensebox"><p id="certLicense11" class="lictext" *ngIf="holder.license.license_number">{{holder.license.license_number[11]}}</p></div>
            </div>
        </div>
        <div style="clear:both;"></div>
        <div class="writingline" style="margin-top:0.1cm;"></div>
        <div id="explanation">
            <div id="certificate-complementarycertificate" style="margin-top:-0.2cm;">{{strings['COMPLEMENTARY_CERTIFICATE']}}</div>
            <div id="explanationtext" style="margin-left:1cm;margin-right:1cm;margin-top:-0.4cm;">
                <p id="certificate-certificateexplanation">{{strings['CERTIFICATE_EXPLANATION']}}</p>
            </div>
        </div>
        <div id="name" style="margin-top:0.5cm;">
            <div class="writingline" style="margin-top: -0.3cm;"><p id="certLastname" class="inputtext" style="font-size:0.4cm;">{{holder.last_name}}</p></div>
            <p id="certificate-surnames" style="margin-top:-0.375cm;margin-bottom:15px;">{{strings['SURNAMES']}}</p>
            <div class="writingline" style="margin-top: -0.3cm;"><p id="certFirstname" class="inputtext" style="font-size:0.4cm;">{{holder.first_name}}</p></div>
            <p id="certificate-firstnames" style="margin-top:-0.375cm;">{{strings['FIRSTNAMES']}}</p>
        </div>
        <div id="reference" style="text-align:center;margin-top:-0.2cm;">
            <p id="certificate-referencenumberbyemployer">{{strings['REFERENCE_NUMBER_BY_EMPLOYER']}}</p>
            <div id="referencenumber" style="margin-top:-0.3cm;">
                <div class="licensebox"><p id="certRef0" class="lictext" *ngIf="holder.employee_number">{{holder.employee_number[0] || '*'}}</p></div>
                <div class="licensebox"><p id="certRef1" class="lictext" *ngIf="holder.employee_number">{{holder.employee_number[1] || '*'}}</p></div>
                <div class="licensebox"><p id="certRef2" class="lictext" *ngIf="holder.employee_number">{{holder.employee_number[2] || '*'}}</p></div>
                <div class="licensebox"><p id="certRef3" class="lictext" *ngIf="holder.employee_number">{{holder.employee_number[3] || '*'}}</p></div>
                <div class="licensebox"><p id="certRef4" class="lictext" *ngIf="holder.employee_number">{{holder.employee_number[4] || '*'}}</p></div>
                <div class="licensebox"><p id="certRef5" class="lictext" *ngIf="holder.employee_number">{{holder.employee_number[5] || '*'}}</p></div>
                <div class="licensebox"><p id="certRef6" class="lictext" *ngIf="holder.employee_number">{{holder.employee_number[6] || '*'}}</p></div>
                <div class="licensebox"><p id="certRef7" class="lictext" *ngIf="holder.employee_number">{{holder.employee_number[7] || '*'}}</p></div>
                <div class="licensebox"><p id="certRef8" class="lictext" *ngIf="holder.employee_number">{{holder.employee_number[8] || '*'}}</p></div>
                <div class="licensebox"><p id="certRef9" class="lictext" *ngIf="holder.employee_number">{{holder.employee_number[9] || '*'}}</p></div>
                <div class="licensebox"><p id="certRef10" class="lictext" *ngIf="holder.employee_number">{{holder.employee_number[10] || '*'}}</p></div>
                <div class="licensebox"><p id="certRef11" class="lictext" *ngIf="holder.employee_number">{{holder.employee_number[11] || '*'}}</p></div>
            </div>
        </div>
        <div style="clear:both;"></div>
        <div id="issuingexpiring" style="margin-top:0.2cm;">
            <div id="issuingdate">
                <p id="certificate-issuingdate" class="btext1" style="margin-left:0.9cm;margin-right:0cm;">{{strings['ISSUING_DATE']}}</p>
                <div style="float:right;margin-right:0.9cm;">
                    <div class="bcube"><p id="certIssue0" class="lictext">{{dateFormatter.dateToSingle(holder.active_certificate.valid_from, 'year', 0)}}</p></div>
                    <div class="bcube"><p id="certIssue1" class="lictext">{{dateFormatter.dateToSingle(holder.active_certificate.valid_from, 'year', 1)}}</p></div>
                    <div class="bcube"><p id="certIssue2" class="lictext">{{dateFormatter.dateToSingle(holder.active_certificate.valid_from, 'year', 2)}}</p></div>
                    <div class="bcube"><p id="certIssue3" class="lictext">{{dateFormatter.dateToSingle(holder.active_certificate.valid_from, 'year', 3)}}</p></div>
                    <p class="btext">-</p>
                    <div class="bcube"><p id="certIssue4" class="lictext">{{dateFormatter.dateToSingle(holder.active_certificate.valid_from, 'month', 0)}}</p></div>
                    <div class="bcube"><p id="certIssue5" class="lictext">{{dateFormatter.dateToSingle(holder.active_certificate.valid_from, 'month', 1)}}</p></div>
                    <p class="btext">-</p>
                    <div class="bcube"><p id="certIssue6" class="lictext">{{dateFormatter.dateToSingle(holder.active_certificate.valid_from, 'date', 0)}}</p></div>
                    <div class="bcube"><p id="certIssue7" class="lictext">{{dateFormatter.dateToSingle(holder.active_certificate.valid_from, 'date', 1)}}</p></div>
                </div>
            </div>
            <div style="clear:both;"></div>
            <div id="expirydate" style="margin-bottom:0.8cm;">
                <p id="certificate-expiredate" class="btext1" style="margin-left:0.9cm;">{{strings['EXPIRE_DATE']}}</p>
                <div style="float:right;margin-right:0.9cm;">
                    <div class="bcube"><p id="certExpire0" class="lictext">{{dateFormatter.dateToSingle(holder.active_certificate.valid_to, 'year', 0, '*')}}</p></div>
                    <div class="bcube"><p id="certExpire1" class="lictext">{{dateFormatter.dateToSingle(holder.active_certificate.valid_to, 'year', 1, '*')}}</p></div>
                    <div class="bcube"><p id="certExpire2" class="lictext">{{dateFormatter.dateToSingle(holder.active_certificate.valid_to, 'year', 2, '*')}}</p></div>
                    <div class="bcube"><p id="certExpire3" class="lictext">{{dateFormatter.dateToSingle(holder.active_certificate.valid_to, 'year', 3, '*')}}</p></div>
                    <p class="btext">-</p>
                    <div class="bcube"><p id="certExpire4" class="lictext">{{dateFormatter.dateToSingle(holder.active_certificate.valid_to, 'month', 0, '*')}}</p></div>
                    <div class="bcube"><p id="certExpire5" class="lictext">{{dateFormatter.dateToSingle(holder.active_certificate.valid_to, 'month', 1, '*')}}</p></div>
                    <p class="btext">-</p>
                    <div class="bcube"><p id="certExpire6" class="lictext">{{dateFormatter.dateToSingle(holder.active_certificate.valid_to, 'date', 0, '*')}}</p></div>
                    <div class="bcube"><p id="certExpire7" class="lictext">{{dateFormatter.dateToSingle(holder.active_certificate.valid_to, 'date', 1, '*')}}</p></div>
                </div>
            </div>
            <div style="clear:both;"></div>
            <div style="text-align:left;">
                <div class="writingline" style="border-bottom:solid;margin-bottom:0.6cm;"></div>
                <div class="writingline"><p id="certIssuer" class="inputtext lefttext">{{holder.active_certificate.issuer.name}}</p></div>
                <p id="certificate-issuingorganisation" style="margin-top:-0.375cm;margin-left:0.625cm;">{{strings['ISSUING_ORGANISATION']}}</p>
                <div class="writingline"><p id="certIssuerAddress" class="inputtext lefttext">{{holder.active_certificate.issuer.address}} {{holder.active_certificate.issuer.zipcode}} {{holder.active_certificate.issuer.city}} {{strings[holder.active_certificate.issuer.country.toString()]}}</p></div>
                <p class="certificate-postaladdress" style="margin-top:-0.375cm;margin-left:0.625cm;">{{strings['POSTAL_ADDRESS']}}</p>
                <div class="writingline"><p id="certInternalReference" class="inputtext lefttext" style="color:white;">&nbsp;</p></div>
                <p id="certificate-internalreferencenumber" style="margin-top:-0.375cm;margin-left:0.625cm;">{{strings['INTERNAL_REFERENCE_NUMBER']}}</p>
                <div class="writingline" style="margin-top:0.7cm;"></div>
            </div>
        </div>
    </div>
</div>