import * as moment from 'moment';
import { IReport } from '../interfaces/i-report';
import { CertificateHolder } from './certificateHolder';

export class Report {

    private _id: number;
    private _opened_by_person_id: number;
    private _closed_by_person_id: number;
    private _opened_time: Date;
    private _closed_time: Date;
    private _comment: string;
    private _response: string;
    private _status_id: number;
    private _closed_by_opener: number;
    private _reopened: number;
    private _opened_by_person: CertificateHolder;
    private _closed_by_person: CertificateHolder;

    constructor();
    constructor(obj?: IReport);
    constructor(obj?: IReport) {
        if (!obj) return;
        this._id = obj.id;
        this._opened_by_person_id = obj.opened_by_person_id;
        this._closed_by_person_id = obj.closed_by_person_id;
        this._opened_time = (obj.opened_time === null) ? null : moment(obj.opened_time).toDate();
        this._closed_time = (obj.closed_time === null) ? null : moment(obj.closed_time).toDate();
        this._comment = obj.comment;
        this._response = obj.response;
        this._status_id = obj.status_id;
        this._closed_by_opener = obj.closed_by_opener;
        this._reopened = obj.reopened;
        this._opened_by_person = new CertificateHolder(obj.opened_by_person);
        this._closed_by_person = new CertificateHolder(obj.closed_by_person);
    }
    public toJSON(): Object {
        return {
            id: this._id, opened_by_person_id: this._opened_by_person_id,
            closed_by_person_id: this._closed_by_person_id,
            opened_time: moment(this._opened_time).format("YYYY-MM-DD"),
            closed_time: moment(this._closed_time).format("YYYY-MM-DD"),
            comment: this._comment, response: this._response,
            status: this._status_id, closed_by_opener: this._closed_by_opener,
            reopened: this._reopened
        }
    }

    /**
     * Getter id
     * @return {number}
     */
	public get id(): number {
		return this._id;
	}

    /**
     * Getter opened_by_person_id
     * @return {number}
     */
	public get opened_by_person_id(): number {
		return this._opened_by_person_id;
	}

    /**
     * Getter closed_by_person_id
     * @return {number}
     */
	public get closed_by_person_id(): number {
		return this._closed_by_person_id;
	}

    /**
     * Getter opened_time
     * @return {Date}
     */
	public get opened_time(): Date {
		return this._opened_time;
	}

    /**
     * Getter closed_time
     * @return {Date}
     */
	public get closed_time(): Date {
		return this._closed_time;
	}

    /**
     * Getter comment
     * @return {string}
     */
	public get comment(): string {
		return this._comment;
	}

    /**
     * Getter response
     * @return {string}
     */
	public get response(): string {
		return this._response;
	}

    /**
     * Getter status_id
     * @return {number}
     */
	public get status_id(): number {
		return this._status_id;
	}

    /**
     * Getter closed_by_opener
     * @return {number}
     */
	public get closed_by_opener(): number {
		return this._closed_by_opener;
	}

    /**
     * Getter reopened
     * @return {number}
     */
	public get reopened(): number {
		return this._reopened;
	}

    /**
     * Getter opened_by_person
     * @return {Person}
     */
	public get opened_by_person(): CertificateHolder {
		return this._opened_by_person;
	}

    /**
     * Getter closed_by_person
     * @return {Person}
     */
	public get closed_by_person(): CertificateHolder {
		return this._closed_by_person;
	}

    /**
     * Setter id
     * @param {number} value
     */
	public set id(value: number) {
		this._id = value;
	}

    /**
     * Setter opened_by_person_id
     * @param {number} value
     */
	public set opened_by_person_id(value: number) {
		this._opened_by_person_id = value;
	}

    /**
     * Setter closed_by_person_id
     * @param {number} value
     */
	public set closed_by_person_id(value: number) {
		this._closed_by_person_id = value;
	}

    /**
     * Setter opened_time
     * @param {Date} value
     */
	public set opened_time(value: Date) {
		this._opened_time = value;
	}

    /**
     * Setter closed_time
     * @param {Date} value
     */
	public set closed_time(value: Date) {
		this._closed_time = value;
	}

    /**
     * Setter comment
     * @param {string} value
     */
	public set comment(value: string) {
		this._comment = value;
	}

    /**
     * Setter response
     * @param {string} value
     */
	public set response(value: string) {
		this._response = value;
	}

    /**
     * Setter status_id
     * @param {number} value
     */
	public set status_id(value: number) {
		this._status_id = value;
	}

    /**
     * Setter closed_by_opener
     * @param {number} value
     */
	public set closed_by_opener(value: number) {
		this._closed_by_opener = value;
	}

    /**
     * Setter reopened
     * @param {number} value
     */
	public set reopened(value: number) {
		this._reopened = value;
	}

    /**
     * Setter opened_by_person
     * @param {Person} value
     */
	public set opened_by_person(value: CertificateHolder) {
		this._opened_by_person = value;
	}

    /**
     * Setter closed_by_person
     * @param {Person} value
     */
	public set closed_by_person(value: CertificateHolder) {
		this._closed_by_person = value;
	}

}
