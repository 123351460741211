//This service is used to fetch certificates/exports and send it to the stream certificate component
import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HeaderService } from '../services/header.service';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class StreamCertificateService {

  public isLoading: boolean;
  private api: string;

  constructor(private http: HttpClient,
              private headers: HeaderService,
              private sanitizer: DomSanitizer) {
                this.api = this.headers.API_LOCATION;
              }

  @Output() onChange: EventEmitter<string> = new EventEmitter();
  @Output() onLoading: EventEmitter<boolean> = new EventEmitter();

  public toggle(certificate: string) {
    this.onChange.emit(certificate);
  }

  public toggleLoading(loading: boolean) {
    this.onLoading.emit(loading);
  }

  public streamCertificate(personId: number, certificateId: number, actionId: number) {
    return this.http.get<any>(`${this.api}print-out/certificate/${personId}/${certificateId}/${actionId}`, this.headers.getOptions())
  }

  public streamExport(personId: number, languageId: number, ceasedDate?: string) {
    let params = new HttpParams().set('person_id', personId.toString()).set('language_id', languageId.toString())
    if (ceasedDate) params = params.append('ceased_date', ceasedDate || '');
    return this.http.get<any>(`${this.api}print-out/export`, this.headers.getOptionsWithParams(params))
  }

  public streamScan(filename: string) {
    return this.http.get<any>(`${this.api}scans/${filename}`, this.headers.getOptions())
  }
}
