//This service is used to format dates to correct formats used in certificate and export
import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateFormatterService {

  constructor() { }

  public dateToStr(d: any, f: string = 'yyyymmdd'): string {
    if (d != null && d instanceof Date) {
      if (f === 'yyyymmdd') {
        return moment(d).format('YYYY-MM-DD');
      } else if (f === 'ddmmyyyy') {
        return moment(d).format('DD-MM-YYYY');
      } else if (f === 'ddmmyyyyhhmm') {
        return moment(d).format('DD-MM-YYYY HH:mm');
      }
    } else if (typeof d === 'string') {
      return d.replace("T", " ").replace(/\.\d\d\dZ/, "");
    }
    return "";
  }

  public dateToSingle(d: Date, period: string, index: number, altChar: string = ""): string {
    if (d!= null && d instanceof Date && period != undefined && index != undefined) {
      if (period === "year") {
          return d.getFullYear().toString()[index];
      } else if (period === "month") {
          let month = (d.getMonth()+1 < 10) ? "0" + (d.getMonth()+1) : (d.getMonth()+1).toString();
          return month[index];
      } else if (period === "date") {
          let date = (d.getDate() < 10) ? "0" + d.getDate() : d.getDate().toString();
          return date[index];
      }
    }
    return altChar;
  }

  public isValidDate(to: Date, from?: Date): boolean {
    let today = new Date();
    if (from != null) {
        if (today >= from && today <= to) {
            return true;
        }
    } else {
        if (today <= to) {
            return true;
        }
    }
    return false;
  }
}
