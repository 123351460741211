import * as moment from 'moment';
import { ILanguage } from '../interfaces/i-language';

export class Language {

    private _id: number;
    private _language: string;
    private _valid_from: Date;
    private _note: string;
    private _iso1_code: string;
    private _iso2_code: string;
    private _amount: number;
    private _label: string;

    constructor();
    constructor(obj?: ILanguage);
    constructor(obj?: ILanguage) {
        if (!obj) return;
        this._id = obj.id;
        this._language = obj.language;
        this._valid_from = (obj._pivot_valid_from === undefined) ? null : moment(obj._pivot_valid_from).toDate();
        this._note = obj._pivot_note;
        this._iso1_code = obj.iso1_code;
        this._iso2_code = obj.iso2_code;
        this._amount = obj.amount;
        this._label = obj.label;
    }
    public toJSON(): Object {
        return {
            id: this._id, language: this._language,
            iso1_code: this._iso1_code, iso2_code: this._iso2_code,
            valid_from: moment(this._valid_from).format("YYYY-MM-DD"),
            note: this._note || null
        }
    }

    public isEqualTo(language: Language): boolean {
        return (
            this.id === language.id && 
            this.language === language.language && 
            this.iso1_code === language.iso1_code && 
            this.iso2_code === language.iso2_code && 
            this.valid_from === language.valid_from && 
            this.note === language.note
        )
    }

    get id(): number {
        return this._id;
    }
    set id(id: number) {
        this._id = id;
    }
    get language(): string {
        return this._language;
    }
    set language(language: string) {
        this._language = language;
    }
    get iso1_code(): string {
        return this._iso1_code;
    }
    set iso1_code(iso1_code: string) {
        this._iso1_code = iso1_code;
    }
    get iso2_code(): string {
        return this._iso2_code;
    }
    set iso2_code(iso2_code: string) {
        this._iso2_code = iso2_code;
    }
    get valid_from(): Date {
        return this._valid_from;
    }
    set valid_from(valid_from: Date) {
        this._valid_from = valid_from;
    }
    get note(): string {
        return this._note;
    }
    set note(note: string) {
        this._note = note;
    }
    get label(): string {
        return this._label;
    }
    set label(label: string) {
        this._label = label;
    }
    get amount(): number {
        return this._amount;
    }
    set amount(amount: number) {
        this._amount = amount;
    }
    public toString() {
        return this._iso2_code
    }
}
