//Main component
import { Component, OnInit, HostListener } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { LoginStateService } from './services/login-state.service';
import { StringService } from './services/string.service';
import { CertificateHolderService } from './services/certificate-holder.service';
import { CertificateHolder } from './classes/certificateHolder';
import { AuthenticationService } from './services/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public userPage: boolean = false;
  public showLogin: boolean = false;
  private loginStateSub: Subscription;
  private holderSub: Subscription;
  private routerSub: Subscription;
  private intervalSub: Subscription;

  constructor(private auth: AuthenticationService,
              private router: Router,
              private titleService: Title,
              public loginStateService: LoginStateService,
              public strings: StringService,
              public holderService: CertificateHolderService) {}

  //On focus check if client is valid
  @HostListener('window:focus', ['$event'])
  onFocus(): void {
    if (this.router.url.indexOf('/login/access-denied') === -1 &&
        this.router.url.indexOf('/validate') === -1) {
      this.auth.validateSession();
    } else {
      this.loginStateService.toggle(false)
    }
  }


  ngOnInit() {
    //CHeck if client is valid
    this.auth.validateSession();
    //Get strings
    this.strings.getAppStrings();
    this.strings.getConnectionStrings();

    //On change to holder, set title of page to holder name and get strings if certificate language different from application language
    this.holderSub = this.holderService.onChange.subscribe((holder: CertificateHolder) => {
      this.titleService.setTitle(holder.full_name);
      if (holder.active_certificate.language !== undefined) {
        if (holder.active_certificate.language.iso2_code !== localStorage.getItem("language")) {
          this.strings.getCertificateStrings(holder.active_certificate.language.iso1_code);
        }
      }
    })

    //On route change, if user page, get certificate holder id and retrieve data
    this.routerSub = this.router.events.subscribe((q: NavigationEnd) => {
      if (q instanceof NavigationEnd) {
        this.userPage = this.isUserPage(q);
        if (this.userPage && this.holderService.holder.id === undefined) {
          let path = q.url.split("/");
          let holderId: number = Number(path[2]);
          if (!Number.isNaN(holderId)) {
            this.holderService.getCertificateHolder(holderId).subscribe((holder) => {
              this.holderService.toggleChange(new CertificateHolder(holder));
            })
          }
        }
      }
    });
    //Check if client is valid every 5 minutes
    const i = interval(1000 * 60 * 5);
    this.intervalSub = i.subscribe(() => { 
      if (this.loginStateService.loggedIn) {
        this.auth.validateSession(); 
      }
    })
  }

  //Unsubscribe on route change
  ngOnDestroy() {
    if (this.loginStateSub) this.loginStateSub.unsubscribe();
    if (this.routerSub) this.routerSub.unsubscribe();
    if (this.holderSub) this.holderSub.unsubscribe();
    if (this.intervalSub) this.intervalSub.unsubscribe();
  }

  //Determine if current page is main page or user page
  private isUserPage(route: any): boolean {
    if (route === undefined || route instanceof NavigationEnd === false || route.url === undefined) return false;
    let path = route.url.split("/");
    if (path.length > 2 && path[1] === "user") return true;
    return false;
  }
}
