//This component displays statistics for which and how many changes were made to certificates in a user defined period
import { Component, OnInit, ViewChild, ElementRef, ContentChild } from '@angular/core';
import { StreamCertificateService } from '../../../services/stream-certificate.service';
import { SortService } from '../../../services/sort.service';
import { LoginStateService } from '../../../services/login-state.service';
import { DateFormatterService } from '../../../services/date-formatter.service';
import { StringService } from '../../../services/string.service';
import { ConstantsService } from '../../../services/constants.service';
import { StatisticsService } from 'src/app/services/statistics.service';
import { Subscription } from 'rxjs';
import { ChangeLog } from 'src/app/classes/certificateHolder';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {
  public objectKeys = Object.keys;
  public statistics: Object = {};
  public overall: Object = {};
  public loggedIn: boolean = false;
  public showSubStatistics: boolean = false;
  public periodTypes: Array<Object> = [];
  public periods: Array<Object> = [];
  public subStatistics: Array<Object> = [];
  public selectedAmount: Object = {};
  public startDate: Date = null;
  public selectedPeriodType: Object = {};
  private sortAsc: boolean = true;
  private stringsSub: Subscription;
  private statisticsSub: Subscription;
  private loginStateSub: Subscription;

  constructor( private streamService: StreamCertificateService,
               private loginStateService: LoginStateService,
               public dateFormatter: DateFormatterService,
               public strings: StringService,
               public constantsService: ConstantsService,
               private statisticsService: StatisticsService ) { 
      this.initialiseSelection();
      this.loggedIn = loginStateService.loggedIn;
  }

  ngOnInit() {
    //Watch for changes in strings and initialize
    this.strings.onArtesChange.subscribe(() => { this.initialiseSelection(); });
    //Fetch main statistics values
    this.statisticsService.getOveralls().subscribe((overall: Object) => { this.overall = overall });
    //Watch for change in login state
    this.loginStateService.loggedInChange.subscribe((state: boolean) => { this.loggedIn = state; });
  }

  //Unsubscripte on route change
  ngOnDestroy() {
    if (this.stringsSub) this.stringsSub.unsubscribe();
    if (this.statisticsSub) this.statisticsSub.unsubscribe();
    if (this.loginStateSub) this.loginStateSub.unsubscribe();
  }

  //Setup initial statistics period
  private initialiseSelection() {
    this.setPeriodTypes();
    this.selectedPeriodType = this.periodTypes[0];
    let d = new Date();
    let weekday = (d.getDay() > 0) ? d.getDay() - 1 : 6;
    d.setDate(d.getDate() - ((4-1) * 7) - weekday);
    this.startDate = d;
    this.setSelectedPeriodType(this.periodTypes[0]);
    this.calculatePeriodStart(this.selectedPeriodType, this.selectedAmount);
    
    this.getStatistics(this.dateFormatter.dateToStr(this.startDate, 'yyyymmdd'), this.selectedPeriodType['index'], this.selectedAmount['value']);
  }

  //Fetch amount of different actions in given period defined by start time, type (eg week, month year), and amount (eg 1 week, 2 weeks etc)
  public getStatistics(start: string, type: number, amount: number): void {
    this.statisticsService.getStatistics(start, type, amount).subscribe((statistics: any) => { this.statistics = statistics.data })
  }

  //Fetch events that occurred in the given period
  public getSubStatisticsByPeriodAndType(selected: Array<Object>): void {
    this.subStatistics = selected;
    this.showSubStatistics = true;
  }

  //Display events for the given period. called by clicking a cell in the statistics table
  public getSubStatisticsByPeriod(selected: Object): void {
    for (let action in selected) {
      this.subStatistics = this.subStatistics.concat(selected[action]);
    }
    this.showSubStatistics = true;
  }

  //Old version saved comments as delimited strings, return arr to html
  public split(text: string, delimiter: string): Array<string> {
    if (text) return text.split(delimiter)
    else return []
  }

  //New version saves changes/comments as json, return arr to html
  public splitArr(arr: string): Array<ChangeLog> {
    let changes = JSON.parse(arr);
    return changes
  }

  //Update period start date
  public updateStartDate(event: any) {
    this.startDate = new Date(event);
  }

  //Scroll to element
  public scrollTo(el: HTMLElement) {
    el.scrollIntoView();
  }

  //Set allowed period types
  private setPeriodTypes() {
    this.periodTypes = [
      {value: this.strings.artesStrings['WEEK'], index: 1},
      {value: this.strings.artesStrings['MONTH'], index: 2},
      {value: this.strings.artesStrings['YEAR'], index: 3}
    ];
  }

  //Set allowed amounts for different period types (eg 52 for week)
  public setSelectedPeriodType(periodType: any): void {
    this.periods = [];
    let type = periodType.index;
    if (!type) type = 1;
    let index: number = 0;
    if (type === 1) index = 52;
    else if (type === 2) index = 12;
    else if (type === 3) index = 10;
    for (let i=0; i<index; i++) {
      this.periods.push({value: (i+1).toString()});
    }
    this.selectedAmount = this.periods[3];
  }

  //Auto update period start if period type or amount is changed
  public calculatePeriodStart(periodType: any, periodAmount: any): void {
    let d = new Date();
    if (periodType === undefined) {
      return;
    }
    if (periodType.index === 1) {
      let weekday = (d.getDay() > 0) ? d.getDay()-1 : 6;
      d.setDate(d.getDate() - ((periodAmount.value-1) * 7) - weekday);
    } else if (periodType.index === 2) {
      d.setMonth(d.getMonth() + 1 - periodAmount.value);
      d.setDate(1);
    } else if (periodType.index === 3) {
      d.setFullYear(d.getFullYear() - (periodAmount.value - 1));
      d.setMonth(0);
      d.setDate(1);
    }

    let dd = '';
    let mm = '';
    let yyyy = d.getFullYear();
    if (d.getDate() < 10) dd = '0' + d.getDate().toString();
    else dd = d.getDate().toString();
    if (d.getMonth()+1 < 10) mm = '0' + (d.getMonth()+1).toString();
    else mm = (d.getMonth()+1).toString();
    this.startDate = new Date(yyyy + '-' + mm + '-' + dd);
  }

  //Stream certificate from events
  public streamCertificate(action: any) {
    if (action.id && action.certificate_id && action.person_id) {
      this.streamService.toggleLoading(true);
      this.streamService.streamCertificate(action.person_id, action.certificate_id, action.id)
      .subscribe(
        (data) => {
          if (data && data['data']) {
            this.streamService.toggle(data['data']);
          }
          this.streamService.toggleLoading(false);
        },
        () => { this.streamService.toggleLoading(false); }
      )
    } else {
      alert(this.strings.artesStrings['NO_ACTIVE_CERTIFICATE']);
    }
  }

  //Sort table
  public sortTable(key: string) {
    this.sortAsc = !this.sortAsc;
    let sorter: SortService = new SortService(this.subStatistics);
    this.subStatistics = sorter.sort(key, this.sortAsc);
  }
}
