<div class="holder" *ngIf="loggedIn">
    <table class="maintable" *ngIf="type=='scans'">
        <thead>
            <tr>
                <th style="width:25%">{{strings.artesStrings['SIGNED_CERTIFICATE']}}</th>
                <th style="width:15%" (click)="sortScans('uploaded')">{{strings.artesStrings['REGISTERED']}}</th>
                <th style="width:40%">{{strings.artesStrings['ASSIGN_CERTIFICATE_HOLDER']}}</th>
                <th style="width:10%"></th>
                <th style="width:10%"></th>
            </tr>
        </thead>
        <tbody *ngFor="let scan of eventsService.scans">
            <tr>
                <td class="btnholder"><button (click)="streamScan(scan.name)">{{strings.artesStrings['CERTIFICATE']}}</button></td>
                <td>{{dateFormat.dateToStr(scan.uploaded)}}</td>
                <td class="btnholder">
                    <mat-select [(value)]="scan.assignedPerson">
                        <mat-option [value]="null"></mat-option>
                        <mat-option *ngFor="let person of (constantsService.observeConstants() | async)?.holders" [value]="person.id">{{person.full_name}} - {{person.license.license_number}}</mat-option>
                    </mat-select>
                    <!--<ng-select [items]="constantsService.constants.people"
                                bindLabel="full_name"
                                bindValue="id"
                                placeholder="{{strings['ASSIGN_CERTIFICATE_HOLDER']}}"
                                [loading]="constantsService.isLoading"
                                [(ngModel)]="scan.assignedPerson">
                        <ng-template ng-option-tmp let-item="item" let-index="index">
                            <span style="float:left;">{{item.full_name}}</span>
                            <b style="float:right;">{{item.license.license_number}}</b>
                        </ng-template>
                    </ng-select>-->
                </td>
                <td class="btnholder"><button (click)="respondScan(scan.name, 1, scan.assignedPerson)">{{strings.artesStrings['ACCEPT']}}</button></td>
                <td class="btnholder reject"><button (click)="respondScan(scan.name, 2)">{{strings.artesStrings['REJECT']}}</button></td>
            </tr>
        </tbody>
    </table>

    <table class="maintable" *ngIf="type=='reported-issues'">
        <tr>
            <th style="width:18%" (click)="sortIssues('openedTime')">{{strings.artesStrings['REGISTERED']}}</th>
            <th style="width:18%" (click)="sortIssues('name')">{{strings.artesStrings['NAME']}}</th>
            <th style="width:18%" (click)="sortIssues('licenseNumber')">{{strings.artesStrings['LICENSE_NUMBER']}}</th>
            <th style="width:18%" (click)="sortIssues('comment')">{{strings.artesStrings['COMMENT']}}</th>
            <th style="width:18%">{{strings.artesStrings['REPLY_TO_CASE']}}</th>
            <th style="width:10%"></th>
        </tr>
        <tbody *ngFor="let report of eventsService.reports">
            <tr>
                <td>{{dateFormat.dateToStr(report.opened_time, 'ddmmyyyyhhmm')}}</td>
                <td>{{report.opened_by_person.full_name}}</td>
                <td>{{report.opened_by_person.license.license_number}}</td>
                <td>{{report.comment}}</td>
                <td class="btnholder"><textarea [(ngModel)]="report.response"></textarea></td>
                <td class="btnholder"><button (click)="respondReport(report)">{{strings.artesStrings['FINISH']}}</button></td>
            </tr>
        </tbody>
    </table>
</div>
