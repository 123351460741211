import { IStatus } from "../interfaces/i-status";

export class Status {

    private _id: number;
    private _status: string;

    constructor(obj?: IStatus) {
        if (!obj) return;
        this._id = obj.id;
        this._status = obj.status;
    }

    get id(): number {
        return this._id;
    }
    set id(id: number) {
        this._id = id;
    }
    get status(): string {
        return this._status;
    }
    set status(status: string) {
        this._status = status;
    }
}
