//Application variables
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public readonly APPLICATION = "artes-go";
  public readonly PLATFORM = "browser";
  public readonly LOCATION = environment.apiUrl;

  //AUTH
  public readonly VERIFY = `${this.LOCATION}/verify`;

  constructor() { }
}
