import { Pipe, PipeTransform } from '@angular/core';
import { ActionType } from 'src/app/classes/action-type';

@Pipe({name: 'filterActionType'})
export class FilterActionTypePipe implements PipeTransform {

  transform(value: Array<ActionType>): Array<ActionType> {

    if(!!!value) return value;

    return value.filter(a => a.action !== 'EXPORTED')
  }
}
