//OBS: THIS COMPONENT IS NOT IN USE AND MAY NOT EVER BE
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ConstantsService } from '../../../services/constants.service';
import { SortService } from '../../../services/sort.service';
import { Stretch } from '../../../classes/stretch';
import { Station } from '../../../classes/station';
import { Engine } from '../../../classes/engine';
import { LoginStateService } from '../../../services/login-state.service';
import { StringService } from '../../../services/string.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  private allowedTypes: Array<string> = ["stretches", "stations", "engines"];
  private allowedStates: Array<string> = ["list", "edit"];
  public type: string = null;
  public state: string = null;
  private objectId: string = null;
  private sortAsc: boolean = true;
  public loggedIn: boolean = false;
  public editMode: boolean = false;
  public editId: number;
  public isLoading: boolean = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private loginStateService: LoginStateService,
              public constantsService: ConstantsService,
              public strings: StringService,
              private userService: UserService) { 

    this.loggedIn = loginStateService.loggedIn;
  }

  ngOnInit() {
    //Update type when strings are received
    this.strings.onArtesChange.subscribe(() => {
      if (this.type !== null) {
        this.type = this.strings.artesStrings[this.type.toUpperCase()];
      }
    });
    //Update login state if changed
    this.loginStateService.loggedInChange.subscribe(state => {
      if (this.loggedIn !== state) {
        this.loggedIn = state;
      }
    });
    //When constants change, validate that route params are valid
    this.constantsService.onChange.subscribe(() => { this.validateRoute() });
    //If route changes, validate that route params are valid
    this.router.events.subscribe(q => {
      if (q instanceof NavigationEnd) {
        this.validateRoute();
      }
    });
  }
  //If type or state is invalid, redirect to front page
  private validateRoute(): void {
    this.type = this.route.snapshot.paramMap.get('type');
    if (this.type === null || this.allowedTypes.indexOf(this.type) === -1) {
      this.router.navigate(['start']);
    }
    this.state = this.route.snapshot.paramMap.get('state');
    this.objectId = this.route.snapshot.paramMap.get('object-id');
    if (this.state === null || this.allowedStates.indexOf(this.state) === -1) {
      this.router.navigate(['start']);
    } else {
      if (this.state === this.allowedStates[0]) {
        this.checkTableData();
      } else if (this.state === this.allowedStates[1]) {
        this.checkEditData();
      }
    }
  }

  //If type is stretches get users if not already there
  private checkEditData(): void {
    if (this.objectId !== null) {
      if (this.type.toLowerCase() === this.allowedTypes[0]) {
        if (this.constantsService.constants[this.allowedTypes[0]] === undefined) {
          this.userService.getUsers().subscribe(response => {
            this.userService.toggleUsersChange(response);
          });
        }
      }
    }
  }


  private checkTableData(): void {
    if (this.type === this.allowedTypes[0]) {
      if (this.constantsService.constants[this.allowedTypes[0]] === undefined) {
        this.userService.getUsers().subscribe(response => {
          this.userService.toggleUsersChange(response);
        });
      }
    }
  }

  public saveChanges(type: string, obj: any): void {
    if (!confirm(this.strings.artesStrings['ARE_YOU_SURE'])) return;
    if (obj === undefined || obj.id === undefined) {
      //this.artesDB.getConstants().subscribe(constants => {this.artesDB.toggleConstantsChange(constants);});
      //this.constantsService.getConstants();
    }
    if (type !== 'stretches' && type !== 'stations' && type !== 'engines') {
      //this.artesDB.getConstants().subscribe(constants => {this.artesDB.toggleConstantsChange(constants);});
      //this.constantsService.getConstants();
    } else {
      this.isLoading = true;
      if (obj.id === 0) {
        switch(type) {
          case 'stretches':
            this.constantsService.createStretch(obj as Stretch).subscribe(
              () => { this.updateConstants(); },
              () => { this.isLoading = false; }
            );
            break;
          case 'stations':
            this.constantsService.createStation(obj as Station).subscribe(
              () => { this.updateConstants(); },
              () => { this.isLoading = false; }
            );
            break;
          case 'engines':
            this.constantsService.createEngine(obj as Engine).subscribe(
              () => { this.updateConstants(); },
              () => { this.isLoading = false; }
            );
            break;
        }
      } else {
        switch(type) {
          case 'stretches':
            this.constantsService.updateStretch(obj as Stretch).subscribe(
              () => { this.updateConstants(); },
              () => { this.discardChanges(); }
            );
            break;
          case 'stations':
            this.constantsService.updateStation(obj as Station).subscribe(
              () => { this.updateConstants(); },
              () => { this.discardChanges(); }
            );
            break;
          case 'engines':
            this.constantsService.updateEngine(obj as Engine).subscribe(
              () => { this.updateConstants(); },
              () => { this.discardChanges(); }
            );
            break;
        }
      }
    }
    
  }

  private updateConstants() {
    //this.constantsService.getConstants();
    this.isLoading = false;
    this.editMode = false;
  }

  public discardChanges(): void {
    //this.artesDB.getConstants().subscribe( constants => { this.artesDB.toggleConstantsChange(constants); });
    //this.constantsService.getConstants();
    this.editMode = false;
    this.isLoading = false;
  }

  public deleteConstant(type: string, obj: any): void {
    if (!confirm(this.strings.artesStrings['ARE_YOU_SURE'])) return;
    this.isLoading = true;
    if (type !== 'stretches' && type !== 'stations' && type !== 'engines') {
      //this.artesDB.getConstants().subscribe(constants => {this.artesDB.toggleConstantsChange(constants);});
      //this.constantsService.getConstants();
    } else {
      this.constantsService.deleteConstant(obj, type).subscribe(response => {
        //this.artesDB.getConstants().subscribe(constants => {this.artesDB.toggleConstantsChange(constants);});
        //this.constantsService.getConstants();
        this.isLoading = false;
      });
    }
  }

  public addNewObject(type: string) {
    switch (type) {
      case 'stretches':
        let stretch: Stretch = new Stretch();
        this.editId = 0;
        this.editMode = true;
        //this.constantsService.constants.stretches.unshift(stretch);
        break;
      case 'stations':
        let station: Station = new Station();
        this.editId = 0;
        this.editMode = true;
        //this.constantsService.constants.stations.unshift(station);
        break;
      case 'engines':
        let engine: Engine = new Engine();
        this.editId = 0;
        this.editMode = true;
        //this.constantsService.constants.engines.unshift(engine);
        break;
      default:
        break;
    }
  }

  public sortTable(key: string, key2?: string) {
    this.sortAsc = !this.sortAsc;
    let sorter: SortService = new SortService(this.constantsService.constants[this.type]);
    this.constantsService.constants[this.type] = sorter.sort(key, this.sortAsc, key2);
  }
}
