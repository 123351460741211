//This service is used to get headers for application backend
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  public API_LOCATION = environment.apiUrl;

  constructor() { }

  public getOptions() {
    let headers: HttpHeaders = new HttpHeaders()
      .append('Content-Type', 'application/json; charset=utf-8')
    return { headers: headers };
  }

  public getOptionsWithParams(params: HttpParams) {
    let headers: HttpHeaders = new HttpHeaders()
      .append('Content-Type', 'application/json; charset=utf-8')
    return { headers: headers, params: params };
  }
}
