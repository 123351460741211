//This component is the front page and displays an animation that shows some text
import { Component, OnInit, HostListener } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

//Define animation for front page
@Component({
  selector: 'app-start',
  animations: [
    trigger('fadeInOut', [
      state('hide', style({
        opacity: 0
      })),
      state('show', style({
        opacity: 1
      })),
      transition('hide => show', animate("2s ease-in-out")),
      transition('show => hide', animate("2s ease-in-out")),
    ]),
  ],
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit {

  public simply: string = 'hide';
  public beyond: string = 'hide';
  public go: string = 'hide'; 

  constructor() {
               }

  //Start animation when front page receives focus
  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    this.showSlogan(true);
  }

  //Start animation on component load
  ngOnInit() {
    this.showSlogan(true);
    setTimeout(() => {
      this.showSlogan(true);
    }, 50000);
  }

  //Display animation
  private showSlogan(repeat: boolean) {
    setTimeout(() => {
      this.toggleSimply();
      setTimeout(() => {
        this.toggleBeyond();
        setTimeout(() => {
          this.toggleGo();
          if (repeat) {
            setTimeout(() => {
              this.showSlogan(false);
            }, 5000);
          }
        }, 2000);
      }, 2000);
    }, 2000);
  }

  private toggleSimply() {
    this.simply = this.simply === 'show' ? 'hide' : 'show';
  }

  private toggleBeyond() {
    this.beyond = this.beyond === 'show' ? 'hide' : 'show';
  }

  private toggleGo() {
    this.go = this.go === 'show' ? 'hide' : 'show';
  }
}
