//Signed certificates can be sent to the system. 
//This component allows admins to accept incoming scanned pdf-files.
//It also allows admins to respond to issues reported by certificate holder through the Android app
import { Component, OnInit } from '@angular/core';
import { SortService } from '../../../services/sort.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { LoginStateService } from '../../../services/login-state.service';
import { DateFormatterService } from '../../../services/date-formatter.service';
import { StringService } from '../../../services/string.service';
import { ConstantsService } from '../../../services/constants.service';
import { EventService } from '../../../services/event.service';
import { Report } from 'src/app/classes/report';
import { Subscription } from 'rxjs';
import { StreamCertificateService } from 'src/app/services/stream-certificate.service';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit {

  public loggedIn: boolean = false;
  public type: string = null;
  private sortAsc: boolean = true;
  private acceptedTypes: Array<string> = ["scans", "reported-issues"];
  private routerSub: Subscription;
  private loginStateSub: Subscription;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private loginStateService: LoginStateService,
              public strings: StringService,
              public constantsService: ConstantsService,
              public eventsService: EventService,
              public dateFormat: DateFormatterService,
              private streamService: StreamCertificateService) { 

                this.loggedIn = loginStateService.loggedIn;
              }

  ngOnInit() {
    //Redirect to front page if type is not valid
    this.type = this.route.snapshot.paramMap.get('type');
    if (this.type === null || this.type === undefined || this.acceptedTypes.indexOf(this.type) === -1) {
      this.router.navigate(['start']);
      return;
    }
    //Watch out for changes in login state
    this.loginStateSub = this.loginStateService.loggedInChange.subscribe(state => { this.loggedIn = state; });

    //Watch out for changes in route and check if type is still valid
    this.routerSub = this.router.events.subscribe(q => {
      if (q instanceof NavigationEnd) {
        this.type = this.route.snapshot.paramMap.get('type');
        if (this.type === null || this.type === undefined || this.acceptedTypes.indexOf(this.type) === -1) {
          this.router.navigate(['start']);
          return;
        }
      }
    })
  }

  //Unsubscribe on route change
  ngOnDestroy() {
    if (this.loginStateSub) this.loginStateSub.unsubscribe();
    if (this.routerSub) this.routerSub.unsubscribe();
  }

  //Send response to scanned object. Fetch new scans after.
  public respondScan(filename: string, response: number, person?: number): void {
    if (response === 1) {
      if (person !== null && person !== undefined) {
        let resp = confirm(this.strings.artesStrings['ARE_YOU_SURE']);
        if (resp) {
          this.eventsService.approveScan(filename, person).subscribe(() => {
            this.eventsService.getScans();
          });
        }
      } else {
        alert(this.strings.artesStrings['NO_PERSON_SELECTED']);
      }
    } else {
      let resp = confirm(this.strings.artesStrings['ARE_YOU_SURE']);
      if (resp) {
        this.eventsService.rejectScan(filename).subscribe(() => {
          this.eventsService.getScans();
        });
      }
    }
  }

  //Send response to issue report
  public respondReport(report: Report): void {
    this.eventsService.updateReport(report).subscribe(() => {
      this.eventsService.getReports();
    })
  }

  //Sort reported issues
  public sortIssues(key: string): void {
    this.sortAsc = !this.sortAsc;
    let sorter: SortService = new SortService(this.eventsService.reports);
    this.eventsService.reports = sorter.sort(key, this.sortAsc);
  }

  //Sort reported scans
  public sortScans(key: string): void {
    this.sortAsc = !this.sortAsc;
    let sorter: SortService = new SortService(this.eventsService.scans);
    this.eventsService.scans = sorter.sort(key, this.sortAsc);
  }

  public streamScan(filename: string) {
    this.streamService.streamScan(filename)
      .subscribe(
        (data) => {
          if (data && data['data']) {
            this.streamService.toggle(data['data']);
          }
          this.streamService.toggleLoading(false);
        },
        () => { this.streamService.toggleLoading(false); }
      )
  }
}
