//This component display the navigation/search bar for both pages (main + user)
import { Component, OnInit, Input } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { LoginStateService } from '../../../services/login-state.service';
import { StringService } from '../../../services/string.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { EventService } from 'src/app/services/event.service';
import { environment } from 'src/environments/environment';
import { Role } from 'src/app/classes/role';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() showLogin: boolean;

  public isMainPage: boolean = true;
  private routerSub: Subscription;
  public artesLogoSrc: string = environment.envName === 'production' ? 'assets/img/arteslogo.png' : 'assets/img/arteslogo_test.png'

  constructor( public router: Router,
               public loginStateService: LoginStateService,
               public strings: StringService,
               public auth: AuthenticationService,
               public eventsService: EventService ) 
  { }

  ngOnInit() {
    //On route change check if current page is main page or user page
    this.router.events.subscribe(q => {
      if (q instanceof NavigationEnd) {
        if (q.url !== undefined) {
          let path = q.url.split("/");
          if (path[1] === "user") {
            this.isMainPage = false;
          } else {
            this.isMainPage = true;
          }
        }
      }
    });
  }

  //Unsubscribe on route change
  ngOnDestroy() {
    if (this.routerSub) this.routerSub.unsubscribe();
  }

  //Logout
  public logout() {
    this.auth.logout();
  }

  //Redirect to search if enter is pressed in search input
  public search(event: any) {
    if (event.keyCode === 13) {
      this.router.navigate(['/search/', event.target.value]);
    }
  }

  //Close window
  public close() {
    window.close();
  }

  //navigate based on navigation clicks
  public navigate(destination: string) {
    let path = this.router.url.split("/");
    this.router.navigate(['/' + path[1] + '/' + path[2] + destination]);
  }
}
