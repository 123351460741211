//This service is used to fetch strings in a given language
//conStrings are used for auth sites
//appStrings are used in the general application
//certificateStrings are used to translate the certificate (can be another language then application)
//exportStrings can also be a different language for the export form
import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HeaderService } from './header.service';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class StringService {

  public conStrings: Object = {};
  public artesStrings: Object = {};
  public certificateStrings: Object = {};
  public exportStrings: Object = {};
  public CONNECTION: number = 1;
  public ARTES: number = 2;
  public CERTIFICATE: number = 3;
  public EXPORT: number = 4;

  constructor(private http: HttpClient,
              private headers: HeaderService,
              private api: ApiService) {
              }

  @Output() onConChange: EventEmitter<Object> = new EventEmitter();
  @Output() onCertChange: EventEmitter<Object> = new EventEmitter();
  @Output() onArtesChange: EventEmitter<Object> = new EventEmitter();
  @Output() onExportChange: EventEmitter<Object> = new EventEmitter();

  private toggleConChange(strings: Object) {
    this.conStrings = strings;
    this.onConChange.emit(this.conStrings);
  }

  private toggleArtesChange(strings: Object) {
    this.artesStrings = strings;
    if (Object.keys(this.certificateStrings).length === 0) this.toggleCertChange(strings);
    if (Object.keys(this.exportStrings).length === 0) this.toggleExportChange(strings);
    this.onArtesChange.emit(this.artesStrings);
  }

  private toggleCertChange(strings: Object) {
    this.certificateStrings = strings;
    this.onCertChange.emit(this.certificateStrings);
  }

  private toggleExportChange(strings: Object) {
    this.exportStrings = strings;
    this.onExportChange.emit(this.exportStrings);
  }

  public getAppStrings(language?: string) {
    if (!language) {
      language = localStorage.getItem("language");
      if (!language) {
        language = navigator.language;
        language = language.split('-')[0];
      }
    }
    localStorage.setItem("language", language || '');
    return this.http.get<any>(`${this.api.LOCATION}strings/${language}`, this.headers.getOptions())
      .subscribe((strings: any) => {
        this.toggleArtesChange(strings.data);
      })
  }

  public getConnectionStrings(language?: string) {
    if (!language) {
      language = localStorage.getItem("language");
      if (!language) {
        language = navigator.language;
        language = language.split('-')[0];
      }
    }
    localStorage.setItem("language", language || '');
    return this.http.get<any>(`${this.api.LOCATION}connection-strings/${language}`, this.headers.getOptions())
      .subscribe((strings: any) => {
        this.toggleConChange(strings);
      })
  }

  public getCertificateStrings(language?: string) {
    if (!language) {
      language = localStorage.getItem("language");
      if (!language) {
        language = navigator.language;
        language = language.split('-')[0];
      }
    }
    return this.http.get<any>(`${this.api.LOCATION}strings/${language}`, this.headers.getOptions())
      .subscribe((strings: any) => {
        this.toggleCertChange(strings.data);
      })
  }

  public getExportStrings(language?: string) {
    if (!language) {
      language = localStorage.getItem("language");
      if (!language) {
        language = navigator.language;
        language = language.split('-')[0];
      }
    }
    return this.http.get<any>(`${this.api.LOCATION}strings/${language}`, this.headers.getOptions())
      .subscribe((strings: any) => {
        this.toggleExportChange(strings.data);
      })
  }
}
