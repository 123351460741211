//This service is used to keep track of whether the user is currently logged in
import { Injectable } from '@angular/core';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoginStateService {

  public loggedIn: boolean = false;
  public showLogin: boolean = false;

  constructor() {
  
  }

  @Output() loggedInChange: EventEmitter<any> = new EventEmitter();

  public toggle(loggedIn: boolean) {
    this.loggedIn = loggedIn;
    this.loggedInChange.emit(this.loggedIn);
  }
}
