//This component allows admins to change skills for a certificate holder
import { Component, OnInit } from '@angular/core';
import { ConstantsService } from '../../../services/constants.service';
import { StringService } from '../../../services/string.service';
import { CertificateHolderService } from '../../../services/certificate-holder.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LoginStateService } from 'src/app/services/login-state.service';
import { Category } from 'src/app/classes/category';
import { Observable } from 'rxjs';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { Language } from 'src/app/classes/language';
import { Station } from 'src/app/classes/station';
import { Stretch } from 'src/app/classes/stretch';
import { Engine } from 'src/app/classes/engine';
import { now } from 'moment';

@Component({
  selector: "app-data",
  templateUrl: "./data.component.html",
  styleUrls: ["./data.component.scss"],
})
export class DataComponent implements OnInit {
  toggleStretch(stretch: Stretch) {

    const index = this.selectedStretches.indexOf(stretch);

    if(index === -1) { // Stretch doesnt exists in selected-array. Add the stretch
      this.selectedStretches.push(stretch);
      return;
    }

    this.selectedStretches.splice(index, 1) // Remove stretch at index (1 indicates one item to be removed)
  }

  stretchId: number;
  stationId: number;
  engineId: number;
  languageId: number;
  filteredCategories: Observable<Category[]>;
  filteredLanguages: Observable<Language[]>;
  filteredEngines: Observable<Engine[]>;
  filteredStations: Observable<Station[]>;
  filteredStretches: Observable<Stretch[]>;
  selectedCategory: Category;
  selectedEngine: Engine;
  selectedLanguage: Language;
  selectedStation: Station;
  selectedStretches: Stretch[] = [];
  validFromAdd: Date;
  form: FormGroup;

  constructor(
    public constantsService: ConstantsService,
    public strings: StringService,
    public holderService: CertificateHolderService,
    public sanitizer: DomSanitizer,
    public auth: AuthenticationService,
    public loginStateService: LoginStateService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    //initialize data
    this.setupForm();
  }

  private setupForm() {
    //setup form
    this.form = this.fb.group({
      categories: new FormControl(),
      languages: new FormControl(),
      engines: new FormControl(),
      stations: new FormControl(),
      stretches: new FormControl(),
    });

    this.validFromAdd = new Date();

    //auto complete for the different skills
    this.filteredCategories = this.form.controls.categories.valueChanges.pipe(
      startWith(""),
      map((value: any) => {
        if (value instanceof Category) return;
        return this.constantsService?.constants.categories?.filter(
          (category) => {
            return (
              category.category
                .toLowerCase()
                .trim()
                .indexOf(value.toLowerCase().trim()) !== -1
            );
          }
        );
      })
    );
    this.filteredLanguages = this.form.controls.languages.valueChanges.pipe(
      startWith(""),
      map((value: any) => {
        if (value instanceof Language) return;
        return this.constantsService?.constants.languages?.filter(
          (language) => {
            return (
              this.strings.artesStrings[language.iso2_code]
                ?.toLowerCase()
                .trim()
                .indexOf(value.toLowerCase().trim()) !== -1
            );
          }
        );
      })
    );
    this.filteredEngines = this.form.controls.engines.valueChanges.pipe(
      startWith(""),
      map((value: any) => {
        if (value instanceof Engine) return;
        return this.constantsService?.constants.engines?.filter((engine) => {
          return (
            engine.engine
              .toLowerCase()
              .trim()
              .indexOf(value.toLowerCase().trim()) !== -1
          );
        });
      })
    );
    this.filteredStations = this.form.controls.stations.valueChanges.pipe(
      startWith(""),
      map((value: any) => {
        if (value instanceof Station) return;
        return this.constantsService?.constants.stations?.filter((station) => {
          return (
            `${station.code}${station.station}${station.full_name}`
              .toLowerCase()
              .trim()
              .indexOf(value.toLowerCase().trim()) !== -1
          );
        });
      })
    );
    this.filteredStretches = this.form.controls.stretches.valueChanges.pipe(
      startWith(""),
      map((value: any) => {
        if (value instanceof Stretch) return;
        return this.constantsService?.constants.stretches?.filter((stretch) => {
          return (
            `${stretch.code}${stretch.stretch}${stretch.full_name}`
              .toLowerCase()
              .trim()
              .indexOf(value.toLowerCase().trim()) !== -1
          );
        });
      })
    );
  }

  //check if license is active
  public licenseIsActive(start: Date, end: Date): boolean {
    if (end === null || end === undefined || end instanceof Date === false)
      return false;
    if (end < new Date()) return false;
    if (start !== undefined && start !== null && start instanceof Date) {
      if (start > new Date()) return false;
    }
    return true;
  }

  //check date is valid on input change
  public parseDate(dateString: string): Date {
    if (dateString) {
      return new Date(dateString);
    } else {
      return null;
    }
  }

  // Clears valid_to if unchecked
  public onValidToChange($event: any) {
    if (!$event.target.checked) {
      this.holderService.holder.certificate_valid_to = null;
    }
  }
}
