//This component allows admins to see how many certificate holders has a specific property or skill and search for them
import { Component, OnInit } from '@angular/core';
import { ConstantsService } from '../../../services/constants.service';
import { Router } from '@angular/router';
import { SortService } from '../../../services/sort.service';
import { IConstants } from '../../../interfaces/i-constants';
import { LoginStateService } from '../../../services/login-state.service';
import { StringService } from '../../../services/string.service';
import { Subscription } from 'rxjs';

enum ConstantType {
  Stretches = 'STRETCHES',
  Stations = 'STATIONS',
  Categories = 'CATEGORIES',
  Engines = 'ENGINES',
  Languages = 'LANGUAGES',
  Employers = 'EMPLOYERS'
}

interface Constant {
  id: number;
  value: ConstantType;
}

@Component({
  selector: 'app-stat-skills',
  templateUrl: './stat-skills.component.html',
  styleUrls: ['./stat-skills.component.scss']
})
export class StatSkillsComponent implements OnInit {

  public loggedIn: boolean = false;
  public selectedConstantArr: Array<any> = [];
  public selectedConstant: Constant;
  private sortAsc: boolean = true;
  private constantsSub: Subscription;
  private loginStateSub: Subscription;
  public constantTypes: Constant[] = [
    {id: 1, value: ConstantType.Stretches}, 
    {id: 2, value: ConstantType.Stations}, 
    {id: 3, value: ConstantType.Categories}, 
    {id: 4, value: ConstantType.Engines}, 
    {id: 5, value: ConstantType.Languages},
    {id: 6, value: ConstantType.Employers}
  ];

  constructor( private router: Router,
               private loginStateService: LoginStateService,
               private constantsService: ConstantsService,
               public strings: StringService ) { 
    this.loggedIn = loginStateService.loggedIn;
    this.setSelected(this.constantTypes[0]);
    this.selectedConstant = this.constantTypes[0];
  }

  ngOnInit() {
    //On constants change, load values to selected var
    this.constantsSub = this.constantsService.onChange.subscribe((constants: IConstants) => {
      this.selectedConstant = this.constantTypes[0];
      this.setSelected(this.selectedConstant);
    });

    //Watch for change in login state
    this.loginStateSub = this.loginStateService.loggedInChange.subscribe(state => {
      if (this.loggedIn !== state) {
        this.loggedIn = state;
      }
    });
  }

  //Unsubscribe on route change
  ngOnDestroy() {
    if (this.constantsSub) this.constantsSub.unsubscribe();
    if (this.loginStateSub) this.loginStateSub.unsubscribe();
  }

  //Set selected type of skills
  public setSelected(selectedConstant: any): void {
    this.selectedConstantArr = this.constantsService.constants[selectedConstant.value.toLowerCase()];
    if (selectedConstant.value.toUpperCase() === this.constantTypes[4].value) {
      for (let lang of this.constantsService.constants[selectedConstant.value.toLowerCase()]) {
        lang['label'] = this.strings.artesStrings[lang['iso2_code'].toUpperCase()];
      }
    }
  }

  //Redirect to search component to show relevant certificate holders
  public searchSkill(constantId: number): void {
    switch (this.selectedConstant.value) {
      case ConstantType.Stretches:
        this.router.navigate([`/search/skills/`], { queryParams: { stretch: constantId } });
        break;
      case ConstantType.Stations:
        this.router.navigate([`/search/skills/`], { queryParams: { station: constantId } });
        break;
      case ConstantType.Languages:
        this.router.navigate([`/search/skills/`], { queryParams: { language: constantId } });
        break;
      case ConstantType.Engines:
        this.router.navigate([`/search/skills/`], { queryParams: { engine: constantId } });
        break;
      case ConstantType.Categories:
        this.router.navigate([`/search/skills/`], { queryParams: { category: constantId } });
        break;
      case ConstantType.Employers:
        this.router.navigate([`/search/skills/`], { queryParams: { employer: constantId } });
        break;
    }
  }

  //Sort table
  public sortTable(key: string) {
    this.sortAsc = !this.sortAsc;
    let sorter: SortService = new SortService(this.selectedConstantArr);
    this.selectedConstantArr = sorter.sort(key, this.sortAsc);
  }

}
