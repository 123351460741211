//This component displays the third page of the export form
import { Component, OnInit, Input } from '@angular/core';
import { DateFormatterService } from '../../../../services/date-formatter.service';
import { CertificateHolder } from 'src/app/classes/certificateHolder';

@Component({
  selector: 'app-export-page3',
  templateUrl: './export-page3.component.html',
  styleUrls: ['../export-page1/export-page1.component.scss']
})
export class ExportPage3Component implements OnInit {

  //Get holder and strings from export component
  @Input() holder: CertificateHolder;
  @Input() strings: Object;

  constructor(public dateFormatter: DateFormatterService) { }

  ngOnInit() {
  }

}
