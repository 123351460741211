//This component allows for searching for certificate holders
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { StreamCertificateService } from '../../../services/stream-certificate.service';
import { SortService } from '../../../services/sort.service';
import { LoginStateService } from '../../../services/login-state.service';
import { CertificateHolder } from 'src/app/classes/certificateHolder';
import { StringService } from '../../../services/string.service';
import { SearchService } from 'src/app/services/search.service';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})

export class SearchComponent implements OnInit {

  private query: string = null;
  public isLoading: boolean = false;
  public people: Array<CertificateHolder> = [];
  public loggedIn: boolean = false;
  private sortAsc: boolean = true;
  private routerSubscription: any;

  constructor( private route: ActivatedRoute,
               private router: Router,
               private streamService: StreamCertificateService,
               private loginStateService: LoginStateService,
               public strings: StringService,
               private searchService: SearchService ) 
  { 
    if (loginStateService.loggedIn !== null) {
      this.loggedIn = loginStateService.loggedIn;
    }
  }

  ngOnInit() {
    //Watch for route change, grab search query and send search request
    this.routerSubscription = this.router.events.subscribe(q => {
      if (q instanceof NavigationEnd) {
        if (q.url !== undefined) {
          let path = q.url.split("/");
          if (path.length > 0) {
            this.query = encodeURIComponent(path[path.length-1]);
            this.search(this.query);
          }
        }
      }
    });
    
    //Get query on first load of component and send search request
    this.query = this.route.snapshot.paramMap.get('query');
    if (this.query !== null && this.query !== "" && this.query !== "skills") {
      this.search(this.query);
    } else {
      const params = this.route.snapshot.queryParamMap;
      if (params && Object.keys(params).length > 0) {
        let httpParams = new HttpParams();
        for (let key of params.keys) {
          httpParams = httpParams.append(key, params.get(key))
        }
        this.searchSkills(httpParams);
      }
    }

    //Watch for changes in login state
    this.loginStateService.loggedInChange.subscribe((state: boolean) => {
      if (this.loggedIn !== state) {
        this.loggedIn = state;
      }
    });
  }

  //Unsubscribe on route change
  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  //Enable loading gif and send search request
  private search(query: string): void {
    this.isLoading = true;
    this.searchService.search(query).subscribe(
      (response: any) => {
        this.people = []
        if (response && response.data && response.data instanceof Array) {
          response.data.forEach(element => {
            this.people.push(new CertificateHolder(element));
          });
        }
        this.isLoading = false;
      },
      () => { this.isLoading = false; }
    )
  }

  private searchSkills(params: HttpParams) {
    this.isLoading = true;
    this.searchService.searchSkills(params).subscribe(
      (response: any) => {
        this.people = []
        if (response && response.data && response.data instanceof Array) {
          response.data.forEach(element => {
            this.people.push(new CertificateHolder(element));
          });
        }
        this.isLoading = false;
      },
      () => { this.isLoading = false; }
    )
  }

  //Get newest certificate from certificate holder and display thorugh stream certificate component
  public streamCertificate(person: CertificateHolder) {
    let actionId = person.newest_issue_action_id;
    if (actionId) {
      this.streamService.toggleLoading(true);
      this.streamService.streamCertificate(person.id, person.newest_certificate.id, person.newest_issue_action_id)
      .subscribe(
        (data) => {
          if (data && data['data']) {
            this.streamService.toggle(data['data']);
          }
          this.streamService.toggleLoading(false);
        },
        () => { this.streamService.toggleLoading(false); }
      )
    } else {
      alert(this.strings.artesStrings['NO_ACTIVE_CERTIFICATE']);
    }
  }

  //Sort search results
  public sortTable(key: string, key2?: string) {
    this.sortAsc = !this.sortAsc;
    let sorter: SortService = new SortService(this.people);
    this.people = sorter.sort(key, this.sortAsc, key2);
  }

  //Open certificate holder page
  public openPerson(person: any) {
    this.router.navigate([]).then(result => { window.open("user/" + person.id + "/data", "_blank") });
  }
}
