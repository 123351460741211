import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '../components/connection/login/login.component';
import { StartComponent } from '../components/main-page/start/start.component';
import { SearchComponent } from '../components/main-page/search/search.component';
import { StatisticsComponent } from '../components/main-page/statistics/statistics.component';
import { StatSkillsComponent } from '../components/main-page/stat-skills/stat-skills.component';
import { EventComponent } from '../components/main-page/event/event.component';
import { AdminComponent } from '../components/main-page/admin/admin.component';
import { DataComponent } from '../components/user-page/data/data.component';
import { CertificateComponent } from '../components/user-page/certificate/certificate.component';
import { HistoryComponent } from '../components/user-page/history/history.component';
import { ExportComponent } from '../components/user-page/export/export.component';
import { ValidateComponent } from '../components/main-page/validate/validate.component';
import { AuthenticationGuard } from '../guards/authentication.guard';

const routes: Routes = [
  { path: '', redirectTo: 'start', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, canActivate: [AuthenticationGuard] },
  { path: 'login/access-denied', component: LoginComponent },
  { path: 'login/:code/:state', component: LoginComponent },
  { path: 'start', component: StartComponent, canActivate: [AuthenticationGuard] },
  { path: 'search/:query', component: SearchComponent, canActivate: [AuthenticationGuard] },
  { path: 'search/skills', component: SearchComponent, canActivate: [AuthenticationGuard] },
  { path: 'statistics/actions', component: StatisticsComponent, canActivate: [AuthenticationGuard] },
  { path: 'statistics/skills', component: StatSkillsComponent, canActivate: [AuthenticationGuard] },
  { path: 'events/:type', component: EventComponent, canActivate: [AuthenticationGuard] },
  { path: 'admin/:type/:state', component: AdminComponent, canActivate: [AuthenticationGuard] },
  { path: 'admin/:type/:state/:object-id', component: AdminComponent, canActivate: [AuthenticationGuard] },
  { path: 'user/:person-id/data', component: DataComponent, canActivate: [AuthenticationGuard] },
  { path: 'user/:person-id/certificate', component: CertificateComponent, canActivate: [AuthenticationGuard] },
  { path: 'user/:person-id/history', component: HistoryComponent, canActivate: [AuthenticationGuard] },
  { path: 'user/:person-id/export', component: ExportComponent, canActivate: [AuthenticationGuard] },
  { path: 'validate', component: ValidateComponent }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
