<div class="holder" *ngIf="loginStateService.loggedIn">
    <app-status></app-status>
  <div id="datacontainer">
      <form [formGroup]="form">
          <fieldset [disabled]="!auth.isAdmin">
            <div class="data-container left half" style="text-align:left;">
                <div style="width:100%;">
                    <label class="form-label inline" for="valid-from" style="width:35%;">{{strings.artesStrings['VALID_FROM']}}</label>
                    <input class="valid" type="date" id="valid-from" name="certificate-valid-from" (input)="holderService.holder.certificate_valid_from=parseDate($event.target.value)" [value]="holderService.holder.certificate_valid_from | date:'yyyy-MM-dd'">
                </div>

                <div *ngIf="holderService.holder.temporary" style="width:100%;">
                    <label class="form-label inline" for="valid-to" style="width:35%;">{{strings.artesStrings['VALIDTO']}}</label>
                    <input class="valid" type="date" id="valid-to" name="certificate-valid-to" (input)="holderService.holder.certificate_valid_to=parseDate($event.target.value)" [value]="holderService.holder.certificate_valid_to | date:'yyyy-MM-dd'">
                </div>
                <div style="width:100%;">
                    <label class="form-label inline" for="valid-to-check" style="width:35%;">{{strings.artesStrings['TEMPORARY']}}</label>
                    <input type="checkbox" name="certificate-valid-to-check" [(ngModel)]="holderService.holder.temporary" [ngModelOptions]="{standalone: true}" id="valid-to-check" (change)="onValidToChange($event)" >
                </div>

                <div class="form-label inline" style="width: 100%;">
                    <p *ngIf="licenseIsActive(holderService.holder.license.valid_from, holderService.holder.license.valid_to)">{{strings.artesStrings['LICENSE_STATUS']}}: <span style="color:green;">{{strings.artesStrings['ACTIVE']}} (expires {{holderService.holder.license.valid_to | date : 'yyyy-MM-dd'}})</span></p>
                    <p *ngIf="!licenseIsActive(holderService.holder.license.valid_from, holderService.holder.license.valid_to)">{{strings.artesStrings['LICENSE_STATUS']}}: <span style="color:red;">{{strings.artesStrings['INACTIVE']}}
                        <span *ngIf="holderService.holder.license.valid_to">(expired {{holderService.holder.license.valid_to | date : 'yyyy-MM-dd'}})</span>
                        <span *ngIf="!holderService.holder.license.valid_to">(expiry unknown)</span>
                    </span></p>
                </div>
            </div>
            <div style="clear:both;"></div>

            <div class="item-container half left">
                <label class="container-label">3. {{strings.artesStrings['CATEGORIES_OF_DRIVING'] | titlecase}}</label>
                <div style="position:relative">
                    <div style="width:calc(100% - 40px)">
                        <mat-form-field class="form-group col-sm">
                            <input
                                matInput
                                type="text"
                                name="categories"
                                class="data-input"
                                placeholder="{{strings.artesStrings['SEARCH']}}"
                                formControlName="categories"
                                [matAutocomplete]="categoriesAuto"
                                [(value)]="selectedCategory">
                            <mat-autocomplete
                                #categoriesAuto="matAutocomplete"
                                (optionSelected)="selectedCategory=$event.option.value;">
                                <mat-option *ngFor="let category of filteredCategories | async" [value]="category">
                                    <strong>{{category.category}}</strong>
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div *ngIf="auth.isAdmin" class="add-item" (click)="holderService.holder.addCategory(selectedCategory); selectedCategory=null">&#10003;</div>
                </div>

                <div *ngFor="let c of holderService.holder.categories" class="item-holder">
                    <div class="sub-item-holder">
                        <div class="list-container left">
                            <input type="text" name="category-label" value="{{c.category}}" style="width:90%;" class="input-item" disabled>
                        </div>
                        <div class="remove-holder">
                            <div *ngIf="auth.isAdmin" class="right remove-item" (click)="holderService.holder.removeCategory(c)">X</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="item-container half right">
                <label class="container-label">4. {{strings.artesStrings['ADDITIONAL_INFORMATION'] | titlecase}}</label>
                <textarea style="overflow:hidden;" [(ngModel)]="holderService.holder.additional_information" [ngModelOptions]="{standalone: true}" class="txtarea" name="certificate-additional-information" maxlength="125"></textarea>
            </div>
            <div style="clear:both;"></div>

            <div class="item-container half left">
                <label class="container-label">5. {{strings.artesStrings['LANGUAGE_SKILLS'] | titlecase}}</label>
                <div style="position: relative;">
                    <div style="width:calc(100% - 40px)">
                        <mat-form-field class="form-group col-sm">
                            <input
                                matInput
                                type="text"
                                name="languages"
                                class="data-input"
                                placeholder="{{strings.artesStrings['SEARCH']}}"
                                formControlName="languages"
                                [matAutocomplete]="languagesAuto"
                                [(value)]="selectedLanguage">
                            <mat-autocomplete
                                #languagesAuto="matAutocomplete"
                                (optionSelected)="selectedLanguage=$event.option.value;">
                                <mat-option *ngFor="let language of filteredLanguages | async" [value]="language">
                                    <strong>{{strings.artesStrings[language.iso2_code.toUpperCase()]}}</strong>
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div *ngIf="auth.isAdmin" class="add-item" (click)="holderService.holder.addLanguage(selectedLanguage); selectedLanguage=null">&#10003;</div>
                </div>

                <div *ngFor="let l of holderService.holder.languages" class="item-holder">
                    <div class="sub-item-holder">
                        <div class="list-container left" style="text-align: left;">
                            <div>
                                <label for="language_name" class="lbl left">{{strings.artesStrings['NAME']}}</label>
                                <input id="language_name" type="text" name="language-label" value="{{strings.artesStrings[l.iso2_code.toUpperCase()]}}" class="input-item right" disabled>
                            </div>
                            <div style="clear: both;"></div>
                            <div>
                                <label for="language_valid_from" class="lbl left">{{strings.artesStrings['VALID_FROM']}}</label>
                                <input id="language_valid_from" type="date" name="language-validfrom" [value]="l.valid_from  | date:'yyyy-MM-dd'" (input)="l.valid_from=parseDate($event.target.value);holderService.holder.updateLanguage(l)" placeholder="yyyy-mm-dd" class="input-item-date right">
                            </div>
                            <div style="clear: both;"></div>
                            <div>
                                <label for="language_note" class="lbl left">{{strings.artesStrings['NOTE']}}</label>
                                <input id="language_note" type="text" name="language-note" placeholder="{{strings.artesStrings['NOTE']}}" (input)="l.note = $event.target.value;holderService.holder.updateLanguage(l)" class="input-item-note right" maxlength="25">
                            </div>
                            <div style="clear: both;"></div>
                        </div>
                        <div class="remove-holder">
                            <div *ngIf="auth.isAdmin" class="right remove-item" (click)="holderService.holder.removeLanguage(l)">X</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item-container half right">
                <label class="container-label">6. {{strings.artesStrings['RESTRICTIONS'] | titlecase}}</label>
                <textarea class="txtarea" name="certificate-restrictions" [(ngModel)]="holderService.holder.restrictions" [ngModelOptions]="{standalone: true}" maxlength="125"></textarea>
            </div>
        </fieldset>

        <fieldset [disabled]="!auth.isAdmin">
            <div class="item-container full">
                <label class="container-label">7. {{strings.artesStrings['ROLLING_STOCK'] | titlecase}}</label>
                <div style="position:relative">
                    <div style="width:calc(100% - 40px)">
                        <mat-form-field class="form-group col-sm">
                            <input
                                matInput
                                type="text"
                                name="engines"
                                class="data-input"
                                placeholder="{{strings.artesStrings['SEARCH']}}"
                                formControlName="engines"
                                [matAutocomplete]="enginesAuto"
                                [(value)]="selectedEngine">
                            <mat-autocomplete
                                #enginesAuto="matAutocomplete"
                                (optionSelected)="selectedEngine=$event.option.value;">
                                <mat-option *ngFor="let engine of filteredEngines | async" [value]="engine">
                                    <strong>{{engine.engine}}</strong>
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div *ngIf="auth.isAdmin" class="add-item" (click)="holderService.holder.addEngine(selectedEngine); selectedEngine=null">&#10003;</div>
                </div>

                <div *ngFor="let e of holderService.holder.engines" class="item-holder">
                    <div class="sub-item-holder">
                        <div class="list-container left" style="text-align: left;">
                            <div>
                                <label for="engine_name" class="lbl left">{{strings.artesStrings['NAME']}}</label>
                                <input type="text" name="engine-label" value="{{e.engine}}" class="input-item right" disabled>
                            </div>
                            <div style="clear: both;"></div>
                            <div>
                                <label for="engine_valid_from" class="lbl left">{{strings.artesStrings['VALID_FROM']}}</label>
                                <input id="engine_valid_from" type="date" style="float:right" name="engine-valid-from" [value]="e.valid_from  | date:'yyyy-MM-dd'" (input)="e.valid_from=parseDate($event.target.value);holderService.holder.updateEngine(e)" placeholder="yyyy-mm-dd" class="input-item-date right">
                            </div>
                            <div style="clear: both;"></div>
                            <div>
                                <label for="engine_note" class="lbl left">{{strings.artesStrings['NOTE']}}</label>
                                <input id="engine_note" type="text" name="engine-note" [value]="e.note" (input)="e.note = $event.target.value;holderService.holder.updateEngine(e)" placeholder="{{strings.artesStrings['NOTE']}}" class="input-item-note right" maxlength="25">
                            </div>
                            <div style="clear: both;"></div>
                            <div>
                                <label for="engine_last_used" class="lbl left">{{strings.artesStrings['LAST_USED']}}</label>
                                <input id="engine_last_used" type="date" name="engine-last-used" [value]="e.last_used  | date:'yyyy-MM-dd'" (input)="e.last_used=parseDate($event.target.value);holderService.holder.updateEngine(e)" placeholder="yyyy-mm-ss" class="input-item-date right">
                            </div>
                            <div style="clear: both;"></div>
                        </div>
                        <div class="remove-holder">
                            <div *ngIf="auth.isAdmin" class="right remove-item" (click)="holderService.holder.removeEngine(e)">X</div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="clear:both"></div>
        </fieldset>

        <fieldset [disabled]="!auth.isAdmin">
            <div class="full txtcenter">
                <label class="container-label">8. {{strings.artesStrings['AUTHORISED_INFRASTRUCTURE'] | titlecase}}</label>
            </div>
            <div class="item-container half left">
                <label class="form-label">{{strings.artesStrings['STATIONS']}}</label>
                <div style="position:relative">
                    <div style="width:calc(100% - 40px)">
                        <mat-form-field class="form-group col-sm">
                            <input
                                matInput
                                type="text"
                                name="stations"
                                class="data-input"
                                placeholder="{{strings.artesStrings['SEARCH']}}"
                                formControlName="stations"
                                [matAutocomplete]="stationsAuto"
                                [(value)]="selectedStation">
                            <mat-autocomplete
                                #stationsAuto="matAutocomplete"
                                (optionSelected)="selectedStation=$event.option.value;">
                                <mat-option *ngFor="let station of filteredStations | async" [value]="station">
                                    <strong>{{station.code}}</strong> {{station.full_name}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div *ngIf="auth.isAdmin" class="add-item" (click)="holderService.holder.addStation(selectedStation); selectedStation=null">&#10003;</div>
                </div>

                <div *ngFor="let s of holderService.holder.stations" class="item-holder">
                    <div class="sub-item-holder">
                        <div class="list-container left" style="text-align: left;">
                            <div>
                                <label for="station_name" class="lbl left">{{strings.artesStrings['NAME']}}</label>
                                <input id="station_name" type="text" name="station-label" value={{s.label}} class="input-item right" disabled>
                            </div>
                            <div style="clear:both"></div>
                            <div>
                                <label for="station_valid_from" class="lbl left">{{strings.artesStrings['VALID_FROM']}}</label>
                                <input id="station_valid_from" type="date" name="station-valid-from" [value]="s.valid_from  | date:'yyyy-MM-dd'" (input)="s.valid_from=parseDate($event.target.value);holderService.holder.updateStation(s)" placeholder="yyyy-mm-dd" class="input-item-date right">
                            </div>
                            <div style="clear: both;"></div>
                            <div>
                                <label for="station_note" class="lbl left">{{strings.artesStrings['NOTE']}}</label>
                                <input id="station_note" type="text" name="station-note" [value]="s.note" (input)="s.note = $event.target.value;holderService.holder.updateStation(s)" placeholder="{{strings.artesStrings['NOTE']}}" class="input-item-note right" maxlength="25">
                            </div>
                            <div style="clear: both;"></div>
                            <div>
                                <label for="station_last_used" class="lbl left">{{strings.artesStrings['LAST_USED']}}</label>
                                <input id="station_last_used" type="date" name="station-last-used" [value]="s.last_used  | date:'yyyy-MM-dd'" (input)="s.last_used=parseDate($event.target.value);holderService.holder.updateStation(s)" placeholder="yyyy-mm-ss" class="input-item-date right">
                            </div>
                            <div style="clear:both;"></div>
                            <div>
                                <label for="station_last_used_outside" class="lbl left">{{strings.artesStrings['LAST_USED_OUTSIDE_SECURE_AREA']}}</label>
                                <input id="station_last_used_outside" type="date" name="station-last-used-outside" [value]="s.last_used_outside_secure_area  | date:'yyyy-MM-dd'" (input)="s.last_used_outside_secure_area=parseDate($event.target.value);holderService.holder.updateStation(s)" placeholder="yyyy-mm-ss" class="input-item-date right">
                            </div>
                            <div style="clear:both;"></div>
                        </div>
                        <div class="remove-holder">
                            <div *ngIf="auth.isAdmin" class="right remove-item" (click)="holderService.holder.removeStation(s)">X</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="item-container half right">
                <label class="form-label">{{strings.artesStrings['STRETCHES']}}</label>
                <div style="position:relative;">
                    <div style="width:calc(100% - 40px)">
                        <mat-form-field class="form-group col-sm">
                            <input
                                matInput
                                type="text"
                                name="stretches"
                                class="data-input"
                                placeholder="{{strings.artesStrings['SEARCH']}}"
                                formControlName="stretches"
                                [matAutocomplete]="stretchesAuto"
                                [(value)]="selectedStretches" />
                            <mat-autocomplete
                                #stretchesAuto="matAutocomplete">

                                <div>
                                  <label for="stretch_valid_from_add" class="lbl left">{{strings.artesStrings['VALID_FROM']}}</label>
                                  <input id="stretch_valid_from_add" type="date" name="stretch-valid-from" [value]="validFromAdd  | date:'yyyy-MM-dd'" (input)="validFromAdd=parseDate($event.target.value)"  placeholder="yyyy-mm-ss" class="input-item-date">
                                </div>

                                <mat-option *ngFor="let stretch of filteredStretches | async" [value]="stretch">
                                    <mat-checkbox [checked]="false" (change)="toggleStretch(stretch)" (click)="$event.stopPropagation()">
                                      <strong>{{stretch.code}}</strong> {{stretch.full_name}}
                                    </mat-checkbox>
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div *ngIf="auth.isAdmin" class="add-item" (click)="holderService.holder.addStretches(selectedStretches, validFromAdd); selectedStretches=null">&#10003;</div>
                </div>

                <div *ngFor="let s of holderService.holder.stretches" class="item-holder">
                    <div class="sub-item-holder">
                        <div class="list-container left" style="text-align: left;">
                            <div>
                                <label for="stretch_name" class="lbl left">{{strings.artesStrings['NAME']}}</label>
                                <input id="stretch_name" type="text" value="{{s.label}}" class="input-item right" disabled>
                            </div>
                            <div style="clear:both;"></div>
                            <div>
                                <label for="stretch_valid_from" class="lbl left">{{strings.artesStrings['VALID_FROM']}}</label>
                                <input id="stretch_valid_from" type="date" name="stretch-valid-from" [value]="s.valid_from  | date:'yyyy-MM-dd'" (input)="s.valid_from=parseDate($event.target.value);holderService.holder.updateStretch(s)" placeholder="yyyy-mm-ss" class="input-item-date right">
                            </div>
                            <div style="clear:both;"></div>
                            <div>
                                <label for="stretch_note" class="lbl left">{{strings.artesStrings['NOTE']}}</label>
                                <input id="stretch_note" type="text" placeholder="{{strings.artesStrings['NOTE']}}" name="stretch-note" [value]="s.note" (input)="s.note = $event.target.value;holderService.holder.updateStretch(s)" class="input-item-note right" maxlength="25">
                            </div>
                            <div style="clear:both;"></div>
                        </div>
                        <div class="remove-holder">
                            <div *ngIf="auth.isAdmin" class="right remove-item" (click)="holderService.holder.removeStretch(s)">X</div>
                        </div>
                    </div>
                </div>
            </div>
        </fieldset>
      </form>
  </div>
</div>
