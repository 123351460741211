<!--IKKE I BRUG-->
<div class="holder" *ngIf="loggedIn">
    <form>
      <fieldset [disabled]="isLoading">
        <table class="maintable" *ngIf="type=='stretches' && state=='list'">
          <tr>
            <th style="width:26.5%" (click)="sortTable('code')">{{strings.artesStrings['CODE']}}</th>
            <th style="width:26.5%" (click)="sortTable('infrastructure')">{{strings.artesStrings['SHORT_NAME']}}</th>
            <th style="width:26.5%" (click)="sortTable('full_name')">{{strings.artesStrings['FULL_NAME']}}</th>
            <th style="width:10%"></th>
            <th class="btnholder" style="width:10%"><button (click)="addNewObject('stretches')">{{strings.artesStrings['CREATE']}}</button></th> 
          </tr>
          
          <tbody *ngFor="let stretch of constantsService.constants.stretches">
            <tr *ngIf="!editMode || editMode && editId !== stretch.id">
              <td><span>{{stretch.code}}</span></td>
              <td><span>{{stretch.infrastructure}}</span></td>
              <td><span>{{stretch.full_name}}</span></td>
              <td class="btnholder"><button (click)="editMode=true; editId=stretch.id">{{strings.artesStrings['EDIT']}}</button></td>
              <td class="btnholder"><button (click)="deleteConstant('stretches', stretch)">{{strings.artesStrings['DELETE']}}</button></td>
            </tr>
            <tr *ngIf="editMode && editId === stretch.id">
              <td><input type="text" name="stretch-code" [(ngModel)]="stretch.code"></td>
              <td><input type="text" name="stretch-infrastructure" [(ngModel)]="stretch.infrastructure"></td>
              <td><input type="text" name="stretch-fullname" [(ngModel)]="stretch.full_name"></td>
              <td class="btnholder"><button (click)="discardChanges()">{{strings.artesStrings['CANCEL']}}</button></td>
              <td class="btnholder"><button (click)="saveChanges('stretches', stretch)">{{strings.artesStrings['SAVE']}}</button></td>
            </tr>
          </tbody>
        </table>
      </fieldset>
    </form>
   
    <form>
      <fieldset [disabled]="isLoading">
        <table class="maintable" *ngIf="type=='stations' && state=='list'">
          <tr>
            <th style="width:26.5%" (click)="sortTable('code')">{{strings.artesStrings['CODE']}}</th>
            <th style="width:26.5%" (click)="sortTable('station')">{{strings.artesStrings['SHORT_NAME']}}</th>
            <th style="width:26.5%" (click)="sortTable('full_name')">{{strings.artesStrings['FULL_NAME']}}</th>
            <th style="width:10%"></th>
            <th class="btnholder" style="width:10%"><button (click)="addNewObject('stations')">{{strings.artesStrings['CREATE']}}</button></th> 
          </tr>
          <tbody *ngFor="let station of constantsService.constants.stations">
            <tr *ngIf="!editMode || editMode && editId !== station.id">
              <td>{{station.code}}</td>
              <td>{{station.station}}</td>
              <td>{{station.full_name}}</td>
              <td class="btnholder"><button (click)="editMode=true; editId=station.id">{{strings.artesStrings['EDIT']}}</button></td>
              <td class="btnholder"><button (click)="deleteConstant('stations', station)">{{strings.artesStrings['DELETE']}}</button></td>
            </tr>
            <tr *ngIf="editMode && editId === station.id">
              <td><input type="text" name="station-code" [(ngModel)]="station.code"></td>
              <td><input type="text" name="station-station" [(ngModel)]="station.station"></td>
              <td><input type="text" name="station-fullname" [(ngModel)]="station.full_name"></td>
              <td class="btnholder"><button (click)="saveChanges('stations', station)">{{strings.artesStrings['SAVE']}}</button></td>
              <td class="btnholder"><button (click)="discardChanges()">{{strings.artesStrings['CANCEL']}}</button></td>     
            </tr>
          </tbody>
        </table>
      </fieldset>
    </form>
  
    <form>
      <fieldset [disabled]="isLoading">
        <table class="maintable" *ngIf="type=='engines' && state=='list'">
          <tr>
            <th style="width:80%" (click)="sortTable('engine')">{{strings.artesStrings['NAME']}}</th>
            <th style="width:10%"></th>
            <th class="btnholder" style="width:10%"><button (click)="addNewObject('engines')">{{strings.artesStrings['CREATE']}}</button></th> 
          </tr>
          <tbody *ngFor="let engine of constantsService.constants.engines">
            <tr *ngIf="!editMode || editMode && editId !== engine.id">
              <td>{{engine.engine}}</td>
              <td class="btnholder"><button (click)="editMode=true; editId=engine.id">{{strings.artesStrings['EDIT']}}</button></td>
              <td class="btnholder"><button (click)="deleteConstant('engines', engine)">{{strings.artesStrings['DELETE']}}</button></td>
            </tr>
            <tr *ngIf="editMode && editId === engine.id">
              <td><input type="text" name="engine-engine" [(ngModel)]="engine.engine"></td>
              <td class="btnholder"><button (click)="saveChanges('engines', engine)">{{strings.artesStrings['SAVE']}}</button></td>
              <td class="btnholder"><button (click)="discardChanges()">{{strings.artesStrings['CANCEL']}}</button></td>
            </tr>
          </tbody>
        </table>
      </fieldset>
    </form>
  </div>
  