<div id="pagetwo" class="pagetwo">
    <div id="left">
        <div id="topbar">
            <p class="certificate-header">{{strings['COMPLEMENTARY_CERTIFICATE_FOR_TRAIN_DRIVERS']}}</p>
            <div class="languageline" style="margin-top:-0.2cm"></div>
        </div>
        <div  style="padding-left:0.2cm;padding-right:0.2cm;">
            <div class="section-header" style="margin-left: 0.2cm;margin-right:0.2cm;">8. {{strings['ROLLING_STOCK']}}</div>
            <p class="inputtext lefttext certificate-date" style="color:black;margin-left:0.25cm;float:left;width:20%;">{{strings['DATE']}}</p>
            <p class="inputtext lefttext certificate-description" style="color:black;position:relative;float:left;">{{strings['DESCRIPTION']}}</p>
            <p class="inputtext lefttext certificate-notes" style="color:black;float:right;margin-right:0.45cm;">{{strings['NOTES']}}</p>

        </div>
        <div style="margin-top: 1cm;">
            <!--<div>
                <div class="infrastructureline" *ngIf="holder.engines.length > 0">
                    <p class="inputtext lefttext infraline" style="margin-left:0cm;width:20%;float:left;">{{dateFormatter.dateToStr(holder.engines[0].validFrom, 'ddmmyyyy')}}</p>
                    <p class="inputtext lefttext infraline" style="float:left;width:40%;">{{holder.engines[0].engine}}</p>
                    <p class="inputtext lefttext infraline" style="float:right;width:40%;">{{holder.engines[0].note}}</p>
                </div>
                <div style="clear:both;"></div>
            </div>-->

            <div *ngFor="let idx of lines" style="margin-top: 0.265cm;">
                <div class="infrastructureline">
                    <div *ngIf="holder?.engines[idx] !== undefined">
                        <p class="inputtext lefttext infraline" style="margin-left:0cm;width:20%;float:left;">{{dateFormatter.dateToStr(holder?.engines[idx]['valid_from'], 'ddmmyyyy')}}</p>
                        <p class="inputtext lefttext infraline" style="float:left;width:40%;">{{holder?.engines[idx].engine}}</p>
                        <p class="inputtext righttext infraline" style="float:right;width:40%;line-height:50%;">{{holder?.engines[idx]['note']}}</p>
                    </div>
                    <div *ngIf="holder?.engines[idx] === undefined">
                        <p class="inputtext lefttext infraline" style="margin-left:0cm;width:20%;float:left;">&nbsp;</p>
                        <p class="inputtext lefttext infraline" style="float:left;width:40%;">&nbsp;</p>
                        <p class="inputtext righttext infraline" style="float:right;width:40%;line-height:50%;">&nbsp;</p>
                    </div>
                </div>
                <div style="clear:both;"></div>
            </div>
        </div>
    </div>

    <div id="middle">
        <div id="topbar">
            <p class="certificate-header">{{strings['COMPLEMENTARY_CERTIFICATE_FOR_TRAIN_DRIVERS']}}</p>
            <div class="languageline" style="margin-top:-0.2cm"></div>
        </div>
        <div  style="padding-left:0.2cm;padding-right:0.2cm;">
            <div class="section-header" style="margin-left: 0.2cm;margin-right:0.2cm;">8. {{strings['AUTHORISED_INFRASTRUCTURE']}}</div>
            <p class="inputtext lefttext certificate-date" style="color:black;margin-left:0.25cm;float:left;width:20%;">{{strings['DATE']}}</p>
            <p class="inputtext lefttext certificate-description" style="color:black;position:relative;float:left;">{{strings['DESCRIPTION']}}</p>
            <p class="inputtext lefttext certificate-notes" style="color:black;float:right;margin-right:0.45cm;">{{strings['NOTES']}}</p>

        </div>
        <div style="margin-top: 1cm;">

            <div *ngFor="let idx of lines" style="margin-top: 0.265cm;">
                <div class="infrastructureline">
                    <div *ngIf="all_infrastructures()[idx] !== undefined">
                        <p class="inputtext lefttext infraline" style="margin-left:0cm;width:20%;float:left;">{{dateFormatter.dateToStr(all_infrastructures()[idx]['valid_from'], 'ddmmyyyy')}}</p>
                        <p class="inputtext lefttext infraline" style="float:left;width:40%;">{{all_infrastructures()[idx]['code']}} {{all_infrastructures()[idx]['name']}}</p>
                        <p class="inputtext righttext infraline" style="float:right;width:40%;line-height:50%;">{{all_infrastructures()[idx]['note']}}</p>
                    </div>
                    <div *ngIf="all_infrastructures()[idx] === undefined">
                        <p class="inputtext lefttext infraline" style="margin-left:0cm;width:20%;float:left;">&nbsp;</p>
                        <p class="inputtext lefttext infraline" style="float:left;width:40%">&nbsp;</p>
                        <p class="inputtext righttext infraline" style="float:right;width:40%;line-height:50%">&nbsp;</p>
                    </div>
                </div>
                <div style="clear:both;"></div>
            </div>
        </div>
    </div>

    <div id="right">
        <div id="topbar">
            <p class="certificate-header">{{strings['COMPLEMENTARY_CERTIFICATE_FOR_TRAIN_DRIVERS']}}</p>
            <div class="languageline" style="margin-top:-0.2cm"></div>
        </div>
        <div  style="padding-left:0.2cm;padding-right:0.2cm;">
            <div class="section-header" style="margin-left: 0.2cm;margin-right:0.2cm;">8. {{strings['AUTHORISED_INFRASTRUCTURE']}}</div>
            <p class="inputtext lefttext certificate-date" style="color:black;margin-left:0.25cm;float:left;width:20%;">{{strings['DATE']}}</p>
            <p class="inputtext lefttext certificate-description" style="color:black;position:relative;float:left;">{{strings['DESCRIPTION']}}</p>
            <p class="inputtext lefttext certificate-notes" style="color:black;float:right;margin-right:0.45cm;">{{strings['NOTES']}}</p>

        </div>
        <div style="margin-top: 1cm;">
            <div *ngFor="let idx of lines" style="margin-top: 0.265cm;">
                <div class="infrastructureline">
                    <div *ngIf="all_infrastructures()[idx+22] !== undefined">
                        <p class="inputtext lefttext infraline" style="margin-left:0cm;width:20%;float:left;">{{dateFormatter.dateToStr(all_infrastructures()[idx+22]['valid_from'], 'ddmmyyyy')}}</p>
                        <p class="inputtext lefttext infraline" style="float:left;width:40%;">{{all_infrastructures()[idx+22]['code']}} {{all_infrastructures()[idx+22]['name']}}</p>
                        <p class="inputtext righttext infraline" style="float:right;width:40%;line-height:50%;">{{all_infrastructures()[idx+22]['note']}}</p>
                    </div>
                    <div *ngIf="all_infrastructures()[idx+22] === undefined">
                        <p class="inputtext lefttext infraline" style="margin-left:0cm;width:20%;float:left;">&nbsp;</p>
                        <p class="inputtext lefttext infraline" style="float:left;width:40%">&nbsp;</p>
                        <p class="inputtext righttext infraline" style="float:right;width:40%;line-height:50%">&nbsp;</p>
                    </div>
                </div>
                <div style="clear:both;"></div>
            </div>
        </div>
    </div>
</div>