//This component displays past changes to certificates for a certificate holder
import { Component, OnInit } from '@angular/core';
import { Action } from 'src/app/classes/action';
import { StringService } from '../../../services/string.service';
import { CertificateHolderService } from '../../../services/certificate-holder.service';
import { Certificate } from 'src/app/classes/certificate';
import { CertificateHolder } from 'src/app/classes/certificateHolder';
import { DateFormatterService } from '../../../services/date-formatter.service';
import { IChange } from 'src/app/interfaces/i-change';
import { LoginStateService } from '../../../services/login-state.service';
import { StreamCertificateService } from '../../../services/stream-certificate.service';
import { SortService } from 'src/app/services/sort.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {

  public selectedCertificate: Certificate;
  public selectedCertificateId: number;
  private sortAsc: boolean = true;
  private holderSub: Subscription;

  constructor(public holderService: CertificateHolderService,
              public strings: StringService,
              public dateFormatter: DateFormatterService,
              public loginState: LoginStateService,
              private streamService: StreamCertificateService) {
    
  }

  ngOnInit() {
    //Set initial certificate to newest. Only actions to 1 certificate are shown at a time
    let newestCertificate: Certificate = this.holderService.holder.newest_certificate;
    if (newestCertificate !== null) {
      this.setSelectedCertificate(newestCertificate.id);
    }
    //Watch for changes to holder, then set selected certificate to the newest
    this.holderSub = this.holderService.onChange.subscribe((holder: CertificateHolder) => {
      let newestCertificate: Certificate = holder.newest_certificate;
      if (newestCertificate !== null) {
        this.setSelectedCertificate(newestCertificate.id);
      }
    });
  }

  //Unsubscribe on route change
  ngOnDestroy() {
    if (this.holderSub) this.holderSub.unsubscribe();
  }

  //Change selected certificate
  setSelectedCertificate(certificateId: number) {
    this.selectedCertificate = this.holderService.holder.getCertificateById(certificateId);
    if (this.selectedCertificate !== null) {
      this.selectedCertificateId = this.selectedCertificate.id;
    }
  }

  //Stream certificate at a given point in time
  public streamCertificate(action: Action) {
    if (action.id) {
      this.streamService.toggleLoading(true);
      this.streamService.streamCertificate(action.person_id, action.certificate_id, action.id)
      .subscribe(
        (data) => {
          if (data && data['data']) {
            this.streamService.toggle(data['data']);
          }
          this.streamService.toggleLoading(false);
        },
        () => { this.streamService.toggleLoading(false); }
      )
    } else {
      alert(this.strings.artesStrings['NO_ACTIVE_CERTIFICATE']);
    }
  }

  //Split comment by delimiter, used for backward compatibility where comments are delimited
  split(text: string, delimiter: string): Array<string> {
    if (text === null) return [];
    return text.split(delimiter);
  }

  //New version saves comments in json, parse and return to html
  parseChanges(text: string): IChange {
    if (text === undefined || text === null) return;
    return JSON.parse(text);
  }

  //Sort table
  public sortTable(key: string, key2?: string) {
    this.sortAsc = !this.sortAsc;
    let sorter: SortService = new SortService(this.selectedCertificate.actions);
    this.selectedCertificate.actions = sorter.sort(key, this.sortAsc, key2);
  }
}
