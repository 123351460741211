//This service is used to validate a certificate against a Android app verson
//Not currently used
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HeaderService } from './header.service';

@Injectable({
  providedIn: 'root'
})
export class ValidateService {

  private api: string;

  constructor(private http: HttpClient,
              private headers: HeaderService) {
                this.api = headers.API_LOCATION;
              }

  
  getValidationCode(licenseNumber: string) {
    let data = { license_number: licenseNumber }
    return this.http.put<any>(`${this.api}validate/code`, data, this.headers.getOptions())
  }
}
