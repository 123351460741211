<img *ngIf="isLoading" src="assets/img/loading.gif" class="loading">
<div class="holder" *ngIf="loggedIn">
  <div id="resultcontainer">
    <table id="searchtable" class="maintable">
        <tr>
            <th (click)="sortTable('first_name', 'last_name')">{{strings.artesStrings['NAME']}}</th>
            <th (click)="sortTable('license_number')">{{strings.artesStrings['LICENSE_NUMBER']}}</th>
            <th (click)="sortTable('employee_number')">{{strings.artesStrings['EMPNUMBER']}}</th>
            <th></th>
        </tr>
        
        <tbody *ngFor="let person of people" (click)="openPerson(person)">
          <tr>
            <td>{{person.first_name}} {{person.last_name}}</td>
            <td>{{person.license.license_number}}</td>
            <td>{{person.employee_number}}</td>
            <td class="btnholder"><button (click)="$event.stopPropagation();streamCertificate(person)">{{strings.artesStrings['CERTIFICATE']}}</button></td>
          </tr>
        </tbody>
      
    </table>
  </div>
</div>
