//This component displays the export form and allows admins to export it
import { Component, OnInit } from '@angular/core';
import { CertificateHolderService } from '../../../services/certificate-holder.service';
import { StringService } from '../../../services/string.service';
import { LoginStateService } from '../../../services/login-state.service';
import { ConstantsService } from '../../../services/constants.service';
import { StreamCertificateService } from '../../../services/stream-certificate.service';
import * as moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss']
})
export class ExportComponent implements OnInit {

  public state: number = 1;
  public ceasedDate: Date;
  public selectedLanguageId: number;
  private stringsSub: Subscription;
  private constantsSub: Subscription;

  constructor(public holderService: CertificateHolderService,
              public strings: StringService,
              public loginStateService: LoginStateService,
              public constantsService: ConstantsService,
              private streamService: StreamCertificateService) { }

  ngOnInit() {
    //If strings are available, setup translations
    if (Object.keys(this.strings.artesStrings).length > 0) {
      this.setupLanguages();
    }
    //when strings change or constants change, setup translations
    this.stringsSub = this.strings.onArtesChange.subscribe(() => { this.setupLanguages() })
    this.constantsSub = this.constantsService.onChange.subscribe(() => { this.setupLanguages() })
    //Set initial language of export, default to danish
    this.selectedLanguageId = this.holderService.holder.active_certificate.language_id || 1;
  }

  //Unsubscribe on route change
  ngOnDestroy() {
    if (this.stringsSub) this.stringsSub.unsubscribe();
    if (this.constantsSub) this.constantsSub.unsubscribe();
  }

  //Set label for languages
  private setupLanguages(): void {
    if (Object.keys(this.constantsService.constants).length === 0) return;
    for (let language of this.constantsService.constants.languages) {
      language.label = this.strings.artesStrings[language.iso2_code.toUpperCase()];
    }
    this.selectedLanguageId = this.holderService.holder.active_certificate.language_id || 1;
  }

  //Export ceertificate, then stream result
  public exportCertificate() {
    let personId = this.holderService.holder.id;
    if (!personId) alert(this.strings.artesStrings['AN_ERROR_OCCURRED']);
    else {
      const ceasedDate = this.ceasedDate ? moment(this.ceasedDate).format('YYYY-MM-DD') : null;
      this.streamService.streamExport(personId, this.selectedLanguageId, ceasedDate)
      .subscribe(
        (data) => {
          if (data && data['data']) {
            this.streamService.toggle(data['data']);
          }
          this.streamService.toggleLoading(false);
        },
        () => { this.streamService.toggleLoading(false); }
      )
    }
  }

  //Update language of export form, fetch translations
  public setExportLanguage(languageId: any): void {
    let language = this.constantsService.constants.languages.find(elem => elem.id === languageId);
    this.holderService.holder.active_certificate.language = language;
    this.strings.getExportStrings(language.iso2_code);
  }

}
