//This component displays the second page of the export form
import { Component, OnInit, Input } from '@angular/core';
import { CertificateHolder } from 'src/app/classes/certificateHolder';
import { DateFormatterService } from '../../../../services/date-formatter.service';
import { Stretch } from 'src/app/classes/stretch';
import { Station } from 'src/app/classes/station';

@Component({
  selector: 'app-export-page2',
  templateUrl: './export-page2.component.html',
  styleUrls: ['../export-page1/export-page1.component.scss']
})
export class ExportPage2Component implements OnInit {

  //Get holder and strings form export component
  @Input() holder: CertificateHolder;
  @Input() strings: Object;
  //Available lines for engine entries in export form
  public engine_lines: Array<number> = [
    0,1,2,3,4,5,6,7,8,9
  ];
  //Available lines for infrastructure in export form
  public infrastructure_lines: Array<number> = [
    0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,
    23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,
    43,44,45,46,47,48,49,50,51,52,53,54
  ];

  constructor(public dateFormatter: DateFormatterService) { }

  ngOnInit() {
  }

  //Combine stations and stretches as they are displayed together in export form
  public all_infrastructures(): Array<Object> {
    let stretches = this.holder.stretches
    .filter((s) => s.show_in_certificate)
    .map((s: Stretch) => { return { id: s.id, name: `${s.code} ${s.full_name}`, valid_from: s.valid_from, note: s.note } });
    let stations = this.holder.stations.map((s: Station) => { return { id: s.id, name: `${s.code} ${s.full_name}`, valid_from: s.valid_from, note: s.note } })
    return stretches.concat(stations);
}

}
