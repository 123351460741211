import { IActionType } from "../interfaces/i-action-type";

export class ActionType {

    private _id: number;
    private _action: string;
    private _label: string;

    constructor(obj?: IActionType) {
        if (!obj) return;
        this._id = obj.id;
        this._action = obj.action;
    }

    /**
     * Getter id
     * @return {number}
     */
	public get id(): number {
		return this._id;
	}

    /**
     * Getter action
     * @return {string}
     */
	public get action(): string {
		return this._action;
	}

    /**
     * Getter label
     * @return {string}
     */
	public get label(): string {
		return this._label;
	}

    /**
     * Setter id
     * @param {number} value
     */
	public set id(value: number) {
		this._id = value;
	}

    /**
     * Setter action
     * @param {string} value
     */
	public set action(value: string) {
		this._action = value;
	}

    /**
     * Setter label
     * @param {string} value
     */
	public set label(value: string) {
		this._label = value;
	}

}
