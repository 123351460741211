//This service is used to fetch statistics data
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CertificateHolder } from '../classes/certificateHolder';
import { HeaderService } from './header.service';

@Injectable({
  providedIn: 'root'
})

export class StatisticsService {

  public scans: any = [];
  public reports: any = [];
  public tabletRequests: any = [];
  public people: any = [];
  public users: Array<CertificateHolder> = [];
  public holder: CertificateHolder = new CertificateHolder();
  private api: string;

  constructor(private http: HttpClient, 
              private headers: HeaderService) { 
                this.api = this.headers.API_LOCATION;
  }

  public getOveralls() {
    return this.http.get<any>(this.api + "statistics/overall", this.headers.getOptions())
  }

  public getStatistics(periodStart: string, periodType: number, periodAmount: number) {
    return this.http.get<any>(`${this.api}statistics/period/${periodStart}/${periodType}/${periodAmount}`, this.headers.getOptions())
  }

  public getSubStatistics(periodStart: string, periodType: number, periodAmount?: number, actionType?: number) {
    return this.http.get<any>(this.api + "sub-statistics/" + periodStart + "/" + periodType + "/" + periodAmount + "/" + actionType, this.headers.getOptions())
  }
}
