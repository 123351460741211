<div class="holder" *ngIf="loginStateService.loggedIn">
    <div id="export-holder">
        <div class="select-title">{{strings.artesStrings['LANGUAGE']}}</div>
        <div class="export-input" id="select-language">
          <!--<ng-select *ngIf="constantsService.constants!==undefined" 
            [items]="constantsService.constants.languages"
            bindLabel="label"
            bindValue="id"
            placeholder="{{strings.artesStrings['SEARCH']}}"
            name="certificate-language"
            id="select-language"
            (change)="setExportLanguage(selectedLanguageId)"
            [(ngModel)]="selectedLanguageId">
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <span class="left">{{item.label}}</span>
            </ng-template>  
          </ng-select>-->
        </div>
        <label class="form-label inline" id="ceased-label" for="ceased" style="width:35%;">{{strings.artesStrings['CEASED_DATE']}}</label>
        <div style="clear:both"></div>
        <input class="valid" type="date" id="ceased" [(ngModel)]="ceasedDate">
        <div style="clear:both"></div>
        <button id="action-button" (click)="exportCertificate()">{{strings.artesStrings['EXPORT']}}</button>
        <div style="clear:both"></div>
        <div id="export">
          <app-export-page1 [holder]="holderService.holder" [strings]="strings.exportStrings" *ngIf="state===1" class="export-page" (click)="state=2"></app-export-page1>
          <app-export-page2 [holder]="holderService.holder" [strings]="strings.exportStrings" *ngIf="state===2" class="export-page" (click)="state=3"></app-export-page2>
          <app-export-page3 [holder]="holderService.holder" [strings]="strings.exportStrings" *ngIf="state===3" class="export-page" (click)="state=1"></app-export-page3>
        </div>
    </div>
  </div>