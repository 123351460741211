//This service is used to sort an array by 1 or 2 given keys
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SortService {

  private array: any[] = [];

  constructor(private arr: Array<any>) { 
    this.array = arr;
  }

  public sort(key: string, order: boolean, key2?: string): Array<any> {
    this.array.sort(function(a, b) {
      if (key2 === undefined) {
        if (order) {
          if (a[key] < b[key]) return -1;
          if (a[key] > b[key]) return 1;
        } else {
          if (a[key] < b[key]) return 1;
          if (a[key] > b[key]) return -1;
        }
      } else {
        if (order) {
          if (a[key] + " " + a[key2] < b[key] + " " + b[key2]) return -1;
          if (a[key] + " " + a[key2] > b[key] + " " + b[key2]) return 1;
        } else {
          if (a[key] + " " + a[key2] < b[key] + " " + b[key2]) return 1;
          if (a[key] + " " + a[key2] > b[key] + " " + b[key2]) return -1;
        }
      }
      
      return 0;
    });
    return this.array;
  }
}
