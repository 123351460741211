//This component displays the current state of the certificate with changes made
//It also allows for updating the data or changing certificate status
import { Component, OnInit } from '@angular/core';
import { ConstantsService } from '../../../services/constants.service';
import { LoginStateService } from '../../../services/login-state.service';
import { ActionType } from 'src/app/classes/action-type';
import { Language } from 'src/app/classes/language';
import { StringService } from '../../../services/string.service';
import { CertificateHolderService } from '../../../services/certificate-holder.service';
import { CertificateService } from '../../../services/certificate.service';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.scss']
})
export class CertificateComponent implements OnInit {

  //Used to determine which side of the certificate is shown. Click on certificate to change
  public state: number = 1;
  public actions: Array<ActionType> = [];
  public selectedAction: ActionType;
  public selectedLanguageId: number;
  private holderSub: Subscription;
  private stringsSub: Subscription;
  private constantsSub: Subscription;

  constructor(public loginStateService: LoginStateService,
              public constantsService: ConstantsService,
              public strings: StringService,
              public holderService: CertificateHolderService,
              private updateCertificateService: CertificateService,
              public auth: AuthenticationService) 
  { }

  ngOnInit() {
    this.setup();
    //When either holder, strings, or constants change, setup translations from strings
    this.holderService.onChange.subscribe(() => { this.setup(); })
    this.strings.onArtesChange.subscribe(() => { this.setup(); });
    this.constantsService.onChange.subscribe(() => { this.setup(); });
  }
  
  //Unsubscribe on route change
  ngOnDestroy() {
    if (this.holderSub) this.holderSub.unsubscribe();
    if (this.stringsSub) this.stringsSub.unsubscribe();
    if (this.constantsSub) this.constantsSub.unsubscribe();
  }

  //Perform update to certificate
  public performAction(action: ActionType) {
    switch(action.action) {
      case 'ISSUED':
        if (this.holderService.holder.active_certificate.valid_from === undefined) {
          alert(this.strings.artesStrings['MISSING_VALID_FROM']);
          return;
        }
        if (!this.holderService.holder.isValidForUpdate()) {
          alert(this.strings.artesStrings['REQUIRED_INFORMATION_MISSING']);
          return;
        }
        if (confirm(this.strings.artesStrings['ARE_YOU_SURE'])) {
          this.updateCertificateService.issue(this.holderService.holder);
        }
        break;
      case 'UPDATED':
        if (!this.holderService.holder.dirty) {
          alert(this.strings.artesStrings['NO_CHANGES_MADE'])
          return;
        }
        if (!this.holderService.holder.isValidForUpdate()) {
          alert(this.strings.artesStrings['REQUIRED_INFORMATION_MISSING']);
          return;
        }
        if (confirm(this.strings.artesStrings['ARE_YOU_SURE'])) {
          this.updateCertificateService.update(this.holderService.holder);
        }
        break;
      case 'CHANGED':
      case 'EXPORTED':
      case 'SUSPENDED':
      case 'WITHDRAWN':
      case 'LOST':
      case 'REMOVED_SUSPENSION':
      case 'RECOVERED':
        if (this.holderService.holder.active_certificate.id === undefined) {
          alert(this.strings.artesStrings['NO_ACTIVE_CERTIFICATE']);
          return;
        }
        if (confirm(this.strings.artesStrings['ARE_YOU_SURE'])) {
          this.updateCertificateService.updateStatus(this.holderService.holder.active_certificate.id, action.action);
        }
        break;
    }
  }

  //Call setup functions
  private setup(): void {
    if (Object.keys(this.strings.artesStrings).length > 0) {
      this.setupLanguages();
      if (this.constantsService.constants.actionTypes && this.constantsService.constants.actionTypes.length > 0) {
        this.setupActions();
      }
    }
  }

  private setupLanguages(): void {
    if (Object.keys(this.constantsService.constants).length === 0) return;
    //add translated label to language object
    for (let language of this.constantsService.constants.languages) {
      if (language.iso2_code) {
        language.label = this.strings.artesStrings[language.iso2_code.toUpperCase()];
        break;
      }
    }
    //Set which language the certificate is issued in. default to danish
    this.holderService.holder.active_certificate.language = this.constantsService.constants.languages.find((lang: Language) => {
      return this.holderService.holder.active_certificate.language_id == lang.id;
    })
    if (!this.holderService.holder.active_certificate.language) {
      let langCode = navigator.language;
      this.holderService.holder.active_certificate.language = this.constantsService.constants.languages.find((lang: Language) => {
        return lang.iso1_code = langCode;
      })
    }
    if (!this.holderService.holder.active_certificate.language) {
      this.holderService.holder.active_certificate.language = this.constantsService.constants.languages.find((lang) => {
        return lang.iso2_code == 'dan'
      })
    }
    this.selectedLanguageId = this.holderService.holder.active_certificate.language_id || 1;
  }

  //Setup translated labels of different actions
  private setupActions(): void {
    this.actions = [];
    if (this.holderService.holder.active_certificate.status_id === undefined) { //Unknown status
      let issue = this.constantsService.constants.actionTypes.find(elem => elem.action === 'ISSUED')
      issue.label = this.strings.artesStrings['ISSUE'];
      this.actions.push(issue);
    } else if (this.holderService.holder.active_certificate.status_id === 1) { //Active status
      let update = this.constantsService.constants.actionTypes.find(elem => elem.action === 'UPDATED')
      update.label = this.strings.artesStrings['UPDATE'];
      this.actions.push(update);
      let withdraw = this.constantsService.constants.actionTypes.find(elem => elem.action === 'WITHDRAWN')
      withdraw.label = this.strings.artesStrings['WITHDRAW'];
      this.actions.push(withdraw);
      let lost = this.constantsService.constants.actionTypes.find(elem => elem.action === 'LOST')
      lost.label = this.strings.artesStrings['LOST'];
      this.actions.push(lost);
      let suspend = this.constantsService.constants.actionTypes.find(elem => elem.action === 'SUSPENDED')
      suspend.label = this.strings.artesStrings['SUSPEND'];
      this.actions.push(suspend);
    } else if (this.holderService.holder.active_certificate.status_id === 2) { //Inactive status
      let issue = this.constantsService.constants.actionTypes.find(elem => elem.action === 'ISSUED')
      issue.label = this.strings.artesStrings['ISSUE'];
      this.actions.push(issue);
    } else if (this.holderService.holder.active_certificate.status_id === 3) { //Suspended status
      let withdraw = this.constantsService.constants.actionTypes.find(elem => elem.action === 'WITHDRAWN')
      withdraw.label = this.strings.artesStrings['WITHDRAW'];
      this.actions.push(withdraw);
      let removeSuspension = this.constantsService.constants.actionTypes.find(elem => elem.action === 'REMOVED_SUSPENSION')
      removeSuspension.label = this.strings.artesStrings['REMOVE_SUSPENSION'];
      this.actions.push(removeSuspension);
    } else if (this.holderService.holder.active_certificate.status_id === 6) { //Lost status
      let recovered = this.constantsService.constants.actionTypes.find(item => item.action === 'RECOVERED')
      recovered.label = this.strings.artesStrings['RECOVERED'];
      this.actions.push(recovered);
      let issue = this.constantsService.constants.actionTypes.find(elem => elem.action === 'ISSUED')
      issue.label = this.strings.artesStrings['ISSUE'];
      this.actions.push(issue);
    }
    this.selectedAction = this.actions[0];
  }

  //If certificate is changed by user, get relevant translation
  public setCertificateLanguage(language: Language): void {
    this.holderService.holder.certificate_language = language;
    this.strings.getCertificateStrings(language.iso2_code);
  }
}
