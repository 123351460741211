<div class="expage">
    <div style="clear:both;"></div>
    <div class="smalljump"></div>
    
    <div id="enddate">
        <div class="bold blue leftcontainer largeheight">
            <p style="font-size:0.33cm;" class="lefttext textdown">{{strings['DATE_WHEN_CEASED']}}</p>
        </div>
        <div class="fullborder rightcontainer largeheight">
        </div>
        
        <div style="clear:both;"></div>
    </div>
    
    <div style="clear:both"></div>
    <div class="smalljump"></div>
    
    <div id="issuingorg">
        <div class="bold blue leftcontainer mediumheight">
            <p class="lefttext textdown">{{strings['ISSUING_ORGANISATION']}}</p>
        </div>
        <div class="fullborder rightcontainer mediumheight">
            <p class="lefttext textdown">{{holder.active_certificate.issuer.name}}</p>
        </div>
        
        <div style="clear:both;"></div>
        
        <div class="leftcontainer smallheight">
            <p class="lefttext textdown">{{strings['POSTAL_ADDRESS']}}</p>
        </div>
        <div class="fullborder rightcontainer smallheight" style="border-top:none;">
            <p class="lefttext textdown">{{holder.active_certificate.issuer.address}}</p>
        </div>
        
        <div style="clear:both;"></div>
        
        <div class="leftcontainer smallheight">
            <p class="lefttext textdown">{{strings['TELEPHONE']}}</p>
        </div>
        <div class="fullborder rightcontainer smallheight" style="border-top:none;width:10cm;">
            <p class="lefttext textdown">{{holder.active_certificate.issuer.phone_number}}</p>
        </div>
        <div class="fullborder rightcontainer smallheight" style="border-top:none;border-left:none;width:4.9cm;">
            <p class="lefttext textdown">{{strings['FAX']}}</p>
        </div>
        <div class="fullborder rightcontainer smallheight" style="border-top:none;border-left:none;width:10cm;">
        </div>
        
        <div style="clear:both;"></div>
        
        <div class="leftcontainer smallheight">
            <p class="lefttext textdown">{{strings['EMAIL']}}</p>
        </div>
        <div class="fullborder rightcontainer smallheight" style="border-top:none;">
            <p class="lefttext textdown">{{holder.active_certificate.issuer.email}}</p>
        </div>
        
        <div style="clear:both;"></div>
        
        <div class="leftcontainer smallheight">
        </div>
        <div class="fullborder rightcontainer smallheight" style="border-top:none;">
        </div>
        
        <div style="clear:both;"></div>
        
        <div class="smallheight" style="width:100%;border-bottom:1px solid black;"></div>
    </div>
    
    <div style="clear:both;"></div>
    <div class="smalljump"></div>
    
    <div id="endpart">
        <div class="left" style="width:10cm;text-align:center">
            <p>{{strings['ISSUING_DATE']}}</p>
            <div class="fullborder" style="height:1cm;text-align:center">
                <p class="textdown">{{dateFormatter.dateToStr(holder.active_certificate.valid_from, 'ddmmyyyy')}}</p>
            </div>
        </div>
        <div class="right" style="width:10cm;text-align:center">
            <div style="height:1cm;border-bottom:1px solid black;"></div>
            <p>{{strings['SIGNATURE']}}</p>
        </div>
        
        <div style="clear:both"></div>
        <div class="smalljump"></div>
        
        <div class="left" style="width:10cm; text-align:center;">
            <p>{{strings['INTERNAL_REFERENCE_NUMBER']}}</p>
            <div class="fullborder" style="height:1cm;"></div>
        </div>
        <div class="right" style="width:10cm;text-align:center">
            <div class="fullborder" style="height:2cm;">
                <p id="exportstamp">{{strings['STAMP']}}</p>
            </div>
        </div>
    </div>
</div>