//This component is used to display the certificate status and name of the certificate holder on the user page
import { Component, OnInit } from '@angular/core';
import { CertificateHolderService } from '../../../services/certificate-holder.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ConstantsService } from '../../../services/constants.service';
import { StringService } from '../../../services/string.service';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit {

  constructor(public holderService: CertificateHolderService,
              public sanitizer: DomSanitizer,
              public constants: ConstantsService,
              public strings: StringService) { }

  ngOnInit(): void {
  }

}
