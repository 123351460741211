//This service is used to fetch current users of the application
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { CertificateHolder } from '../classes/certificateHolder';
import { HeaderService } from './header.service';

const application = "Artes GO";
const platform = "Browser";

@Injectable({
  providedIn: 'root'
})

export class UserService {

  public scans: any = [];
  public reports: any = [];
  public tabletRequests: any = [];
  public people: any = [];
  public users: Array<CertificateHolder> = [];
  public holder: CertificateHolder = new CertificateHolder();
  private api: string;

  constructor(private http: HttpClient, 
              private headers: HeaderService) { 
                this.api = this.headers.API_LOCATION;
  }

  @Output() usersChange: EventEmitter<any> = new EventEmitter();

  public toggleUsersChange(users: any) {
    this.users = users;
    this.usersChange.emit(users);
  }

  public getUsers(): any {
    let data = { application: application, platform: platform };
    return this.http.post<any>(this.api + "/read/get_users", data, this.headers.getOptions())
  }
}
