<div id="change-log" *ngIf="loginStateService.loggedIn && holderService.holder.change_log.log.length > 0">
    <div id="outer-change-log">
        <img src="assets/img/arrow.png" style="height:30px;width:30px;" />
        <p>{{holderService.holder.change_log.log.length}} 
            <span *ngIf="holderService.holder.change_log.log.length === 1">{{strings['CHANGE']}}</span>
            <span *ngIf="holderService.holder.change_log.log.length > 1">{{strings['CHANGES']}}</span>
        </p>
    </div>
    <div class="inner-change-log" *ngFor="let change of holderService.holder.change_log.log">
        - {{strings[change.action]}} {{strings[change.constant].toLowerCase()}} {{change.object.label}}
    </div>
    <div id="reset" (click)="reset()">reset</div>
</div>
