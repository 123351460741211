import { ActionType } from './action-type';
import { User } from './user';
import * as moment from 'moment';
import { IAction } from '../interfaces/i-action';

export class Action {

    private _id: number;
    private _action_type_id: number;
    private _action_type: ActionType;
    private _date: Date;
    private _person_id: number;
    private _certificate_id: number;
    private _user_id: number;
    private _report_reference: string;
    private _comment: string;
    private _data: string;
    private _user: User;

    constructor(obj?: IAction) {
        if (!obj) return;
        this._id = obj.id;
        this._action_type_id = obj.action_type_id;
        this._action_type = new ActionType(obj.action_type);
        this._date = (obj.date === null) ? null : moment.utc(obj.date).toDate();
        this._person_id = obj.person_id;
        this._certificate_id = obj.certificate_id;
        this._user_id = obj.user_id;
        this._report_reference = obj.report_reference;
        this._comment = obj.comment;
        this._data = obj.data;
        if (obj.user !== undefined) {
            this._user = new User(obj.user);
        }
    }

    /**
     * Getter id
     * @return {number}
     */
	public get id(): number {
		return this._id;
	}

    /**
     * Getter action_type_id
     * @return {number}
     */
	public get action_type_id(): number {
		return this._action_type_id;
	}

    /**
     * Getter action_type
     * @return {ActionType}
     */
	public get action_type(): ActionType {
		return this._action_type;
	}

    /**
     * Getter date
     * @return {Date}
     */
	public get date(): Date {
		return this._date;
	}

    /**
     * Getter person_id
     * @return {number}
     */
	public get person_id(): number {
		return this._person_id;
	}

    /**
     * Getter certificate_id
     * @return {number}
     */
	public get certificate_id(): number {
		return this._certificate_id;
	}

    /**
     * Getter user_id
     * @return {number}
     */
	public get user_id(): number {
		return this._user_id;
	}

    /**
     * Getter report_reference
     * @return {string}
     */
	public get report_reference(): string {
		return this._report_reference;
	}

    /**
     * Getter comment
     * @return {string}
     */
	public get comment(): string {
		return this._comment;
	}

    /**
     * Getter data
     * @return {string}
     */
	public get data(): string {
		return this._data;
	}

    /**
     * Getter user
     * @return {User}
     */
	public get user(): User {
		return this._user;
	}

    /**
     * Setter id
     * @param {number} value
     */
	public set id(value: number) {
		this._id = value;
	}

    /**
     * Setter action_type_id
     * @param {number} value
     */
	public set action_type_id(value: number) {
		this._action_type_id = value;
	}

    /**
     * Setter action_type
     * @param {ActionType} value
     */
	public set action_type(value: ActionType) {
		this._action_type = value;
	}

    /**
     * Setter date
     * @param {Date} value
     */
	public set date(value: Date) {
		this._date = value;
	}

    /**
     * Setter person_id
     * @param {number} value
     */
	public set person_id(value: number) {
		this._person_id = value;
	}

    /**
     * Setter certificate_id
     * @param {number} value
     */
	public set certificate_id(value: number) {
		this._certificate_id = value;
	}

    /**
     * Setter user_id
     * @param {number} value
     */
	public set user_id(value: number) {
		this._user_id = value;
	}

    /**
     * Setter report_reference
     * @param {string} value
     */
	public set report_reference(value: string) {
		this._report_reference = value;
	}

    /**
     * Setter comment
     * @param {string} value
     */
	public set comment(value: string) {
		this._comment = value;
	}

    /**
     * Setter data
     * @param {string} value
     */
	public set data(value: string) {
		this._data = value;
	}

    /**
     * Setter user
     * @param {User} value
     */
	public set user(value: User) {
		this._user = value;
	}

}
