<div class="holder" *ngIf="loggedIn">
    <div style="width: 30%;margin:0 auto;">
      <select id="statselect" [(ngModel)]="selectedConstant" (change)="setSelected(selectedConstant)">
          <option *ngFor="let type of constantTypes" [ngValue]="type">{{strings.artesStrings[type.value]}}</option>
      </select>
    </div>
  
    <table id="tablettable" class="maintable">
      <tr>
          <th style="width:12.5%" (click)="sortTable('label')">{{strings.artesStrings['NAME']}}</th>
          <th style="width:12.5%" (click)="sortTable('amount')">{{strings.artesStrings['AMOUNT']}}</th>
      </tr>
      <tbody *ngFor="let i of selectedConstantArr">
          <tr (click)="searchSkill(i.id)">
            <td>{{i.code}} {{i.infrastructure || i.station || i.category || i.engine || i.label || i.name}}</td>
            <td>{{i.amount}}</td>
          </tr>
      </tbody>
    </table>
  </div>
  