//This service is used to fetch search results
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CertificateHolder } from '../classes/certificateHolder';
import { HeaderService } from './header.service';
import { ParamMap } from '@angular/router';
import { ICertificateHolder } from '../interfaces/i-certificate-holder';

@Injectable({
  providedIn: 'root'
})

export class SearchService {

  public scans: any = [];
  public reports: any = [];
  public tabletRequests: any = [];
  public people: any = [];
  public users: Array<CertificateHolder> = [];
  public holder: CertificateHolder = new CertificateHolder();
  private api: string;

  constructor(private http: HttpClient, 
              private headers: HeaderService) { 
                this.api = this.headers.API_LOCATION;
  }

  public search(query: string) {
    return this.http.get<ICertificateHolder>(`${this.api}search/${query}`, this.headers.getOptions())
  }

  public searchSkills(params: HttpParams) {
    return this.http.get<ICertificateHolder>(`${this.api}search`, this.headers.getOptionsWithParams(params))
  }
}
