import { IUser } from "../interfaces/i-user";

export class User {

    private _id: number;
    private _employee_number: string;
    private _first_name: string;
    private _last_name: string;
    private _nationality_id: number;
    private _work_email: string;

    constructor(obj?: IUser) {
        if (!obj) return;
        this._id = obj.id;
        this._employee_number = obj.employee_number;
        this._first_name = obj.first_name;
        this._last_name = obj.last_name;
        this._nationality_id = obj.nationality_id;
        this._work_email = obj.work_email;
    }

    get id(): number {
        return this._id;
    }
    set id(id: number) {
        this._id = id;
    }
    get employee_number(): string {
        return this._employee_number;
    }
    set employee_number(employee_number: string) {
        this._employee_number = employee_number;
    }
    get first_name(): string {
        return this._first_name;
    }
    set first_name(first_name: string) {
        this._first_name = first_name;
    }
    get last_name(): string {
        return this._last_name;
    }
    set last_name(last_name: string) {
        this._last_name = last_name;
    }
    get nationality_id(): number {
        return this._nationality_id;
    }
    set nationality_id(nationality_id: number) {
        this._nationality_id = nationality_id;
    }
    get work_email(): string {
        return this._work_email;
    }
    set work_email(work_email: string) {
        this._work_email = work_email;
    }
}
