<div class="holder" *ngIf="loggedIn">
    <div id="overall">
      <div class="overallstat">
          <div class="overallupper">
              <p *ngIf="strings.artesStrings">{{strings.artesStrings['ACTIVE']}}</p>
          </div>
          <div class="overalllower">
              <p *ngIf="overall" id="statactive">{{overall['1'] || 0}}</p>
          </div>
      </div>
      <div class="overallstat">
          <div class="overallupper">
              <p *ngIf="strings.artesStrings">{{strings.artesStrings['EXPIRED']}}</p>
          </div>
          <div class="overalllower">
              <p *ngIf="overall" id="statexpired">{{overall['2'] || 0}}</p>
          </div>
      </div>
      <div class="overallstat">
          <div class="overallupper">
              <p *ngIf="strings.artesStrings">{{strings.artesStrings['SUSPENDED']}}</p>
          </div>
          <div class="overalllower">
              <p *ngIf="overall" id="statsuspended">{{overall['3'] || 0}}</p>
          </div>
      </div>
      <div class="overallstat">
          <div class="overallupper">
              <p *ngIf="strings.artesStrings">{{strings.artesStrings['WITHDRAWN']}}</p>
          </div>
          <div class="overalllower">
              <p *ngIf="overall" id="statwithdrawn">{{overall['4'] || 0}}</p>
          </div>
      </div>
      <div class="overallstat" style="border-right: 1px solid black;">
          <div class="overallupper">
              <p *ngIf="strings.artesStrings">{{strings.artesStrings['LOST']}}</p>
          </div>
          <div class="overalllower">
              <p *ngIf="overall" id="statlost">{{overall['5'] || 0}}</p>
          </div>
      </div>
    </div>
  
    <div id="statselect">
      <div class="statselectitemtitle">
          <p *ngIf="strings.artesStrings" id="statselecttypeLabel">{{strings.artesStrings['PERIOD_TYPE']}}</p>
      </div>
      <div class="statselectitem">
          <select id="periodtype" name="periodtypes" class="statselectdrop" [(ngModel)]="selectedPeriodType" (change)="calculatePeriodStart(selectedPeriodType, selectedAmount); setSelectedPeriodType(selectedPeriodType)">
              <option *ngFor="let type of periodTypes" [ngValue]="type">{{type.value}}</option>
          </select>
      </div>
      <div class="statselectitemtitle">
          <p *ngIf="strings.artesStrings" id="statselectamountLabel">{{strings.artesStrings['PERIOD_AMOUNT']}}</p>
      </div>
      <div class="statselectitem" >
          <select *ngIf="selectedAmount" id="periodnum" name="periodnumber" [(ngModel)]="selectedAmount" class="statselectdrop" (change)="calculatePeriodStart(selectedPeriodType, selectedAmount)">
              <option *ngFor="let value of periods" [ngValue]="value">{{value.value}}</option>
          </select>
      </div>
      <div class="statselectitemtitle">
          <p *ngIf="strings.artesStrings" id="statselectstartLabel">{{strings.artesStrings['PERIOD_START']}}</p>
      </div>
      <div class="statselectitem">
          <input id="statweek" [ngModel]="startDate | date:'yyyy-MM-dd'" (ngModelChange)="updateStartDate($event)"  type="date" placeholder="yyyy-mm-dd" class="statselectdate" />
      </div>
      <div class="statselectitem">
          <button *ngIf="strings.artesStrings" id="generatestat" class="statselectdate" style="margin:0;line-height:0%;" (click)="getStatistics(dateFormatter.dateToStr(startDate, 'yyyymmdd'), selectedPeriodType['index'], selectedAmount['value'])">{{strings.artesStrings['GENERATE']}}</button>
      </div>
  </div>
  
  <div id="stattablecontainer">
      <table id="stattable" class="maintable">
          <tr>
              <th *ngIf="strings.artesStrings" class="headerRow">{{strings.artesStrings['PERIOD']}}</th>
              <th class="headerRow" *ngFor="let action of (constantsService.constants.actionTypes | filterActionType)"><span>{{strings.artesStrings[action['action']]}}</span></th>
          </tr>
  
          <tbody *ngFor="let key of objectKeys(statistics)">
              <td *ngIf="objectKeys(statistics).length > 0" (click)="getSubStatisticsByPeriod(statistics[key])"><span *ngIf="key">{{key}}</span></td>
              <td *ngFor="let action of (constantsService.constants.actionTypes | filterActionType)" (click)="getSubStatisticsByPeriodAndType(statistics[key][action.action])"><span *ngIf="action">{{statistics[key][action.action].length}}</span></td>
          </tbody>
      </table>
  </div>
  
  <div id="substattablecontainer" *ngIf="showSubStatistics" #substat>
      <table id="substattable" class="maintable">
          <tr>
              <th (click)="sortTable('date')">{{strings.artesStrings['DATE']}}</th>
              <th (click)="sortTable('holderName')">{{strings.artesStrings['PERSON']}}</th>
              <th (click)="sortTable('action')">{{strings.artesStrings['ACTION']}}</th>
              <th (click)="sortTable('userName')">{{strings.artesStrings['USER']}}</th>
              <th (click)="sortTable('comment')">{{strings.artesStrings['COMMENT']}}</th>
              <th></th>
          </tr>
  
          <tbody *ngFor="let i of subStatistics">
              <tr>
                  <td>{{dateFormatter.dateToStr(i['date'], 'yyyymmdd')}}</td>
                  <td>{{i['person']['first_name']}} {{i['person']['last_name']}}</td>
                  <td>{{strings.artesStrings[i['action_type']['action']]}}</td>
                  <td>{{i['user']['first_name']}} {{i['user']['last_name']}}</td>
                  <td>
                      <div *ngIf="i['comment'] && !['data']">
                          <div *ngFor="let line of split(i['comment'],'\\n')">
                              <span *ngFor="let entry of split(line, ';'); let i = index">
                                  <span *ngIf="strings.artesStrings[entry]"><span *ngIf="i==0">{{strings.artesStrings[entry] | titlecase}}</span><span *ngIf="i>0">{{strings.artesStrings[entry] | lowercase}}</span></span>
                                  <span *ngIf="!strings.artesStrings[entry]">{{entry}}</span>
                              </span>
                          </div>
                      </div>
                      <div *ngIf="i['data'] && !i['comment']">
                          <div *ngFor="let line of splitArr(i['data'])">
                              {{strings.artesStrings[line.action] | titlecase}} {{strings.artesStrings[line.constant] | lowercase}}
                              <span *ngIf="line.constant === 'ENGINE'">{{line.object.engine}}</span>
                              <span *ngIf="line.constant === 'STATION'">{{line.object.code}} {{line.object.station}}</span>
                              <span *ngIf="line.constant === 'STRETCH'">{{line.object.code}} {{line.object.stretch}}</span>
                              <span *ngIf="line.constant === 'CATEGORY'">{{line.object.category}}</span>
                              <span *ngIf="line.constant === 'LANGUAGE' || line.constant === 'CERTIFICATE_LANGUAGE'">{{line.object.language}}</span>
                              <span 
                                  *ngIf="line.constant === 'VALID_FROM' || 
                                          line.constant === 'VALID_TO' ||
                                          line.constant === 'ADDITIONAL_INFORMATION' ||
                                          line.constant === 'CATEGORY_REMARK' ||
                                          line.constant === 'RESTRICTIONS'">
                                  {{line.object}}
                              </span>
                              
                          </div>
                      </div>
                  </td>
                  <td class="btnholder"><button (click)="streamCertificate(i)">{{strings.artesStrings['CERTIFICATE']}}</button></td>
              </tr>
          </tbody>
      </table>
    </div>
  </div>
  