//This service is used to fetch constants and allow components to subscribe to changes
import { Injectable, EventEmitter, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HeaderService } from './header.service';
import { Station } from '../classes/station';
import { Stretch } from '../classes/stretch';
import { Engine } from '../classes/engine';
import { Category } from '../classes/category';
import { IConstants } from '../interfaces/i-constants';
import { ActionType } from '../classes/action-type';
import { Employer } from '../classes/employer';
import { Country } from '../classes/country';
import { Status } from '../classes/status';
import { CertificateHolder } from '../classes/certificateHolder';
import { Language } from '../classes/language';
import { Observable } from 'rxjs';
import { IStretch } from '../interfaces/i-stretch';
import { IStation } from '../interfaces/i-station';
import { IEngine } from '../interfaces/i-engine';
import { ICertificateHolder } from '../interfaces/i-certificate-holder';
import { Constants } from '../classes/constants';
import { StretchSorter } from 'src/helpers/stretchSorter';

@Injectable({
  providedIn: 'root'
})
export class ConstantsService {

  private _constants: Constants = <Constants>{};
  public wasLoaded: boolean = false;
  public isLoading: boolean = false;
  private api: string;

  constructor(private http: HttpClient,
              private headers: HeaderService) {
                this.api = this.headers.API_LOCATION;
              }

  @Output() onChange: EventEmitter<any> = new EventEmitter();

  private toggleChange(constants: Constants) {
    this._constants = constants;
    this.wasLoaded = true;
    this.isLoading = false;
    this.onChange.emit(constants);
  }

  public observeConstants(): Observable<Constants> {
    if (!this._constants || Object.keys(this._constants).length === 0 && !this.isLoading) {
      this.isLoading = true;
      this.get();
    }
    return new Observable((observer) => {
      observer.next(this._constants);
      this.onChange.subscribe(() => {
        observer.next(this._constants);
      })
    })
  }

  public get constants(): Constants {
    if (!this._constants || Object.keys(this._constants).length === 0 && !this.isLoading) {
      this.isLoading = true;
      this.get();
    }
    return this._constants;
  }

  private get() {
    return this.http.get<IConstants>(`${this.api}constants`, this.headers.getOptions())
    .subscribe((constants: IConstants) => { 
      this.toggleChange(this.build(constants));
      this.isLoading = false;
    }, () => {
      this.isLoading = false;
    })
  }

  //Create, update and delete functions are not currently used and may be removed
  public createStretch(stretch: Stretch) {
    let data = { stretch: stretch.toJSON() };
    return this.http.post<IStretch>(this.api + "stretches", data, this.headers.getOptions())
  }

  public createStation(station: Station) {
    let data = { station: station.toJSON() };
    return this.http.post<IStation>(this.api + "stretches", data, this.headers.getOptions())
  }

  public createEngine(engine: Engine) {
    let data = { engine: engine.toJSON() };
    return this.http.post<IEngine>(this.api + "engines", data, this.headers.getOptions())
  }

  public updateStretch(stretch: Stretch) {
    let data = { stretch: stretch.toJSON() };
    return this.http.put<IStretch>(this.api + "stretches", data, this.headers.getOptions())
  }

  public updateStation(station: Station) {
    let data = { station: station.toJSON() };
    return this.http.put<IStation>(this.api + "stations", data, this.headers.getOptions())
  }

  public updateEngine(engine: Engine) {
    let data = { engine: engine.toJSON() };
    return this.http.put<IEngine>(this.api + "engines", data, this.headers.getOptions())
  }

  public deleteConstant(obj: Object, type: string) {
    return this.http.delete<any>(`${this.api}${type}/${obj['id']}`, this.headers.getOptions())
  }

  public byId(constants: Array<any>, id: number): any {
    for (let c of constants) {
      if (c.id === id) return c;
    }
    return null;
  }

  public build(obj: IConstants): Constants {
    let constants: Constants = <any>{};
    constants.stations = []; constants.stretches = []; constants.languages = [];
    constants.categories = []; constants.engines = []; constants.actionTypes = [];
    constants.employers = []; constants.countries = []; constants.status = [];
    constants.holders = [];
    if (obj.stations && obj.stations instanceof Array) {
      for (let station of obj.stations) constants.stations.push(new Station(station));
      constants.stations.sort(StretchSorter.customSorter);
    }
    if (obj.stretches && obj.stretches instanceof Array) {
      for (let stretch of obj.stretches) constants.stretches.push(new Stretch(stretch));
      constants.stretches.sort(StretchSorter.customSorter);
    }
    if (obj.languages && obj.languages instanceof Array) {
      for (let language of obj.languages) constants.languages.push(new Language(language));
    }
    if (obj.categories && obj.categories instanceof Array) {
      for (let category of obj.categories) constants.categories.push(new Category(category));
    }
    if (obj.engines && obj.engines instanceof Array) {
      for (let engine of obj.engines) constants.engines.push(new Engine(engine));
    }
    if (obj.actions && obj.actions instanceof Array) {
      for (let actionType of obj.actions) constants.actionTypes.push(new ActionType(actionType));
    }
    if (obj.employers && obj.employers instanceof Array) {
      for (let employer of obj.employers) constants.employers.push(new Employer(employer));
    }
    if (obj.countries && obj.countries instanceof Array) {
      for (let country of obj.countries) constants.countries.push(new Country(country));
    }
    if (obj.status && obj.status instanceof Array) {
      for (let status of obj.status) constants.status.push(new Status(status));
    }
    if (obj.holders && obj.holders instanceof Array) {
      for (let person of obj.holders) constants.holders.push(new CertificateHolder(person));
    }
    return constants;
  }
}
