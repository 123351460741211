<div class="holder" *ngIf="loginState.loggedIn">

    <div class="history-input">
        <div class="action-container"> 
            <mat-select [(value)]="selectedCertificate">
              <mat-option *ngFor="let certificate of holderService.holder.certificates" [value]="certificate">{{certificate}}</mat-option>
            </mat-select>
      </div>
      <!--<ng-select *ngIf="holderService.holder.certificates!==undefined" 
            [items]="holderService.holder.certificates"
            bindLabel="label"
            bindValue="id"
            placeholder="{{strings.artesStrings['SEARCH']}}"
            name="certificate"
            id="select-certificate"
            (change)="setSelectedCertificate(selectedCertificateId)"
            [(ngModel)]="selectedCertificateId">
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <span class="left">{{item.label}}</span>
            </ng-template>  
        </ng-select>-->
    </div>
  
    <div *ngIf="selectedCertificate !== undefined">
        <table id="substattable" class="maintable">
            <tr>
                <th (click)="sortTable('date')">{{strings.artesStrings['DATE']}}</th>
                <th (click)="sortTable('action')">{{strings.artesStrings['ACTION']}}</th>
                <th (click)="sortTable('userName')">{{strings.artesStrings['USER']}}</th>
                <th (click)="sortTable('comment')">{{strings.artesStrings['COMMENT']}}</th>
                <th></th>
            </tr>
    
            <tbody *ngFor="let action of selectedCertificate.actions">
                <tr>
                    <td>{{dateFormatter.dateToStr(action.date, 'ddmmyyyyhhmm')}}</td>
                    <td>{{strings.artesStrings[action.action_type.action]}}</td>
                    <td>{{action.user.first_name}} {{action.user.last_name}}</td>
                    <td *ngIf="action.data === null">
                      <span *ngIf="action.action_type?.action === 'ISSUED'">
                          {{strings.artesStrings['ISSUED']}}
                      </span>
                      <span *ngIf="action.action_type?.action !== 'ISSUED'">
                          <div *ngFor="let line of split(action.comment, '\\n')">
                              <span *ngFor="let entry of split(line, ';'); let i = index">
                                    <span *ngIf="strings.artesStrings[entry]"><span *ngIf="i==0">{{strings.artesStrings[entry] | titlecase}}</span><span *ngIf="i>0">{{strings.artesStrings[entry] | lowercase}}</span></span>
                                    <span *ngIf="!strings.artesStrings[entry]">{{entry}}</span>
                              </span>
                          </div>
                      </span>
                    </td>
                    <td *ngIf="action.data !== null">
                        <div *ngFor="let change of parseChanges(action.data)">
                              <span>{{strings.artesStrings[change.action]}} {{strings.artesStrings[change.constant] | lowercase}} 
                                  <span *ngIf="change.object !== undefined">
                                      <span *ngIf="change.constant === 'CERTIFICATE_LANGUAGE'">{{strings.artesStrings[change.object['iso2_code']?.toString().toUpperCase()]}}</span>
                                      <span *ngIf="change.constant === 'LANGUAGE'">{{strings.artesStrings[change.object['iso2_code']?.toString().toUpperCase()]}}</span>
                                      <span *ngIf="change.constant === 'CATEGORY'">{{change.object['category']}}</span>
                                      <span *ngIf="change.constant === 'STATION'">{{change.object['code']}} {{change.object['station']}}</span>
                                      <span *ngIf="change.constant === 'STRETCH'">{{change.object['code']}} {{change.object['stretch']}}</span>
                                      <span *ngIf="change.constant === 'ENGINE'">{{change.object['engine']}}</span>
                                      <span *ngIf="change.constant === 'CERTIFICATE_STATUS'">{{strings.artesStrings[change.object['status']]}}</span>
                                  </span>
                              </span>
                        </div>
                    </td>
                    <td class="btnholder"><button (click)="streamCertificate(action)">{{strings.artesStrings['CERTIFICATE']}}</button></td>
                </tr>
            </tbody>
        </table>
    </div>
  
  </div>
  