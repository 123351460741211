//OBS: This component is not currently in use. 
//It will allow others to validate that a certificate is updated to the version shown in Android app
import { Component, OnInit } from '@angular/core';
import { StringService } from '../../../services/string.service';
import { ValidateService } from '../../../services/validate.service';

@Component({
  selector: 'app-validate',
  templateUrl: './validate.component.html',
  styleUrls: ['./validate.component.scss']
})
export class ValidateComponent implements OnInit {

  public licenseNumber: string;
  public code: string;

  constructor(public strings: StringService,
              private validate: ValidateService) { }

  ngOnInit(): void {
  }

  getCode() {
    if (this.licenseNumber && this.licenseNumber.length === 12) {
      this.validate.getValidationCode(this.licenseNumber).subscribe((data: any) => {
        if (data && data.code) this.code = data.code;
        else alert(this.strings.artesStrings['AN_ERROR_OCCURRED']);
      })
    } else {
      alert("license number must be 12 characters");
    }
  }
}
