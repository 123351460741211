
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { HttpClientModule } from '@angular/common/http'
import { AppComponent } from './app.component';
import { LoginComponent } from './components/connection/login/login.component';
import { AdminComponent } from './components/main-page/admin/admin.component';
import { EventComponent } from './components/main-page/event/event.component';
import { SearchComponent } from './components/main-page/search/search.component';
import { StartComponent } from './components/main-page/start/start.component';
import { StatSkillsComponent } from './components/main-page/stat-skills/stat-skills.component';
import { StatisticsComponent } from './components/main-page/statistics/statistics.component';
import { ValidateComponent } from './components/main-page/validate/validate.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { StreamCertificateComponent } from './components/shared/stream-certificate/stream-certificate.component';
import { CertificateComponent } from './components/user-page/certificate/certificate.component';
import { ChangeLogComponent } from './components/user-page/change-log/change-log.component';
import { DataComponent } from './components/user-page/data/data.component';
import { ExportComponent } from './components/user-page/export/export.component';
import { HistoryComponent } from './components/user-page/history/history.component';
import { StatusComponent } from './components/user-page/status/status.component';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { CertificateBackComponent } from './components/user-page/certificate/certificate-back/certificate-back.component';
import { CertificateFrontComponent } from './components/user-page/certificate/certificate-front/certificate-front.component';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyCheckboxModule as MatCheckboxModule } from "@angular/material/legacy-checkbox";

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { AuthenticationGuard } from './guards/authentication.guard';
import { ExportPage1Component } from './components/user-page/export/export-page1/export-page1.component';
import { ExportPage2Component } from './components/user-page/export/export-page2/export-page2.component';
import { ExportPage3Component } from './components/user-page/export/export-page3/export-page3.component';
import { FilterActionTypePipe } from './components/main-page/statistics/temp.pipe';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AdminComponent,
    EventComponent,
    SearchComponent,
    StartComponent,
    StatSkillsComponent,
    StatisticsComponent,
    ValidateComponent,
    HeaderComponent,
    StreamCertificateComponent,
    CertificateComponent,
    ChangeLogComponent,
    DataComponent,
    ExportComponent,
    HistoryComponent,
    StatusComponent,
    CertificateBackComponent,
    CertificateFrontComponent,
    ExportPage1Component,
    ExportPage2Component,
    ExportPage3Component,
    FilterActionTypePipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    BrowserAnimationsModule,
    MatTabsModule,
    MatSelectModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    HttpClientModule,
    ReactiveFormsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    AuthenticationGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
