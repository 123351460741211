import * as moment from 'moment';
import { IStretch } from '../interfaces/i-stretch';

export class Stretch {

    private _id: number = 0;
    private _code: string;
    private _stretch: string;
    private _full_name: string;
    private _valid_from: Date;
    private _show_in_certificate: boolean;
    private _note: string;
    private _label: string;
    private _amount: number;

    constructor(obj?: IStretch)
    {
        if (!obj) return;
        this._id = obj.id;
        this._code = obj.code;
        this._stretch = obj.stretch;
        this._full_name = obj.full_name;
        this._valid_from = (obj._pivot_valid_from === undefined) ? null : moment(obj._pivot_valid_from).toDate();
        this._show_in_certificate = obj.show_in_certificate === true;
        this._note = obj._pivot_note;
        this._label = obj.label;
        this._amount = obj.amount;
    }

    public toJSON(): Object {
        return {
            id: this._id,
            code: this._code,
            stretch: this._stretch,
            full_name: this._full_name,
            valid_from: moment(this._valid_from).format("YYYY-MM-DD"),
            note: this._note || null
        }
    }

    public isEqualTo(stretch: Stretch): boolean {
        return (
            this.id === stretch.id &&
            this.code === stretch.code &&
            this.stretch === stretch.stretch &&
            this.full_name === stretch.full_name &&
            this.valid_from === stretch.valid_from &&
            this._show_in_certificate === stretch._show_in_certificate &&
            this.note === stretch.note
        )
    }

    get id(): number {
        return this._id;
    }
    set id(id: number) {
        this._id = id;
    }
    get code(): string {
        return this._code;
    }
    set code(code: string) {
        this._code = code;
    }
    get stretch(): string {
        return this._stretch;
    }
    set stretch(stretch: string) {
        this._stretch = stretch;
    }
    get full_name(): string {
        return this._full_name;
    }
    set full_name(full_name: string) {
        this._full_name = full_name;
    }
    get valid_from(): Date {
        return this._valid_from;
    }
    set valid_from(valid_from: Date) {
        this._valid_from = valid_from;
    }

    get show_in_certificate(): boolean {
        return this._show_in_certificate;
    }
    set show_in_certificate(show_in_certificate: boolean) {
        this._show_in_certificate = show_in_certificate;
    }

    get note(): string {
        return this._note;
    }
    set note(note: string) {
        this._note = note;
    }
    get label(): string {
        return this._label;
    }
    set label(label: string) {
        this._label = label;
    }
    get amount(): number {
        return this._amount;
    }
    set amount(amount: number) {
        this._amount = amount;
    }
    public toString(): string {
        return this._code + this._stretch
    }
}
