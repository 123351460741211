//This service is used to fetch issue reports and scanned pdf-files, and respond accordingly
import { Injectable, Output, EventEmitter } from '@angular/core';
import { Report } from '../classes/report';
import { HttpClient } from '@angular/common/http';
import { HeaderService } from './header.service';
import { IReport } from '../interfaces/i-report';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  public scans: Array<Object> = [];
  public reports: Array<Report> = [];
  private api: string;

  constructor(private http: HttpClient,
              private headers: HeaderService) {
                this.api = this.headers.API_LOCATION;
              }

  @Output() scansChange: EventEmitter<any> = new EventEmitter();
  @Output() reportsChange: EventEmitter<Array<Report>> = new EventEmitter();

  public toggleScansChange(scans: any) {
    this.scans = scans;
    this.scansChange.emit(scans);
  }

  public toggleReportsChange(reports: IReport[]) {
    this.reports = [];
    for (let report of reports) {
      this.reports.push(new Report(report));
    }
    this.reportsChange.emit(this.reports);
  }

  public getScans(): any {
    return this.http.get<any>(`${this.api}scans`, this.headers.getOptions())
      .subscribe((scans: any) => {
        if (scans && scans.data) {
          this.toggleScansChange(scans.data)
        }
      })
  }

  public getReports(): any {
    return this.http.get<IReport>(this.api + "reports/open", this.headers.getOptions())
      .subscribe((reports: any) => { 
        if (reports && reports.data) {
          this.toggleReportsChange(reports.data) 
        }
      })
  }

  public approveScan(filename: string, personId: number) {
    const data = { filename: filename, person_id: personId };
    return this.http.post<IReport>(`${this.api}scans`, data, this.headers.getOptions())
  }

  public rejectScan(filename: string) {
    const data = { filename: filename };
    return this.http.delete<IReport>(`${this.api}scans/${filename}`, this.headers.getOptions())
  }

  public updateReport(report: Report): any {
    let data = { report: report };
    return this.http.put<IReport>(`${this.api}reports/close`, data, this.headers.getOptions())
  }
}
