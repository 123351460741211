//This component is used to display which unsaved changes has been made to the certificate 
import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { CertificateHolderService } from '../../../services/certificate-holder.service';
import { LoginStateService } from '../../../services/login-state.service';
import { CertificateHolder } from '../../../classes/certificateHolder';

@Component({
  selector: 'app-change-log',
  templateUrl: './change-log.component.html',
  styleUrls: ['./change-log.component.scss']
})
export class ChangeLogComponent implements OnInit {

  @Input() strings: Object = {};
  private element: any;

  constructor(public holderService: CertificateHolderService,
              private elementRef: ElementRef,
              public loginStateService: LoginStateService) {
    this.element = this.elementRef.nativeElement;
  }

  //Add to body on init
  ngOnInit() {
    document.body.appendChild(this.element);
  }

  //Remove 
  public reset() {
    if (confirm(this.strings['ARE_YOU_SURE'])) {
      this.holderService.toggleChange(new CertificateHolder(this.holderService.holder.original))
    }
  }

}
