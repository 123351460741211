import { ICategory } from "../interfaces/i-category";

export class Category {

    private _id: number;
    private _category: string;
    private _amount: number;
    private _label: string;

    constructor(obj?: ICategory) {
        if (!obj) return;
        this._id = obj.id;
        this._category = obj.category;
        this._amount = obj.amount;
        this._label = obj.label;
    }

    public toJSON() {
        return {
            id: this._id, 
            category: this._category
        }
    }

    /**
     * Getter id
     * @return {number}
     */
	public get id(): number {
		return this._id;
	}

    /**
     * Getter category
     * @return {string}
     */
	public get category(): string {
		return this._category;
	}

    /**
     * Getter amount
     * @return {number}
     */
	public get amount(): number {
		return this._amount;
	}

    /**
     * Getter label
     * @return {string}
     */
	public get label(): string {
		return this._label;
	}

    /**
     * Setter id
     * @param {number} value
     */
	public set id(value: number) {
		this._id = value;
	}

    /**
     * Setter category
     * @param {string} value
     */
	public set category(value: string) {
		this._category = value;
	}

    /**
     * Setter amount
     * @param {number} value
     */
	public set amount(value: number) {
		this._amount = value;
	}

    /**
     * Setter label
     * @param {string} value
     */
	public set label(value: string) {
		this._label = value;
	}

    public toString(): string {
        return this._category;
    }
}
