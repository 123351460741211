import * as moment from 'moment';
import { ILicense } from '../interfaces/i-license';

export class License {

    private _person_id: number;
    private _license_number: string;
    private _valid_from : Date;
    private _valid_to: Date;

    constructor(obj?: ILicense) {
        if (!obj) return;
        this._person_id = obj.person_id;
        this._license_number = obj.license_number;
        this._valid_from = (obj.valid_from === null) ? null : moment(obj.valid_from).toDate();
        this._valid_to = (obj.valid_to === null) ? null : moment(obj.valid_to).toDate();
    }

    /**
     * Getter person_id
     * @return {number}
     */
	public get person_id(): number {
		return this._person_id;
	}

    /**
     * Getter license_number
     * @return {string}
     */
	public get license_number(): string {
		return this._license_number;
	}

    /**
     * Getter valid_from
     * @return {Date}
     */
	public get valid_from(): Date {
		return this._valid_from;
	}

    /**
     * Getter valid_to
     * @return {Date}
     */
	public get valid_to(): Date {
		return this._valid_to;
	}

    /**
     * Setter person_id
     * @param {number} value
     */
	public set person_id(value: number) {
		this._person_id = value;
	}

    /**
     * Setter license_number
     * @param {string} value
     */
	public set license_number(value: string) {
		this._license_number = value;
	}

    /**
     * Setter valid_from
     * @param {Date} value
     */
	public set valid_from(value: Date) {
		this._valid_from = value;
	}

    /**
     * Setter valid_to
     * @param {Date} value
     */
	public set valid_to(value: Date) {
		this._valid_to = value;
	}

}
