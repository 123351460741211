//This component displays the back side of the certificate with the changes made
import { Component, OnInit, Input } from '@angular/core';
import { DateFormatterService } from '../../../../services/date-formatter.service';
import { Stretch } from 'src/app/classes/stretch';
import { Station } from 'src/app/classes/station';

@Component({
  selector: 'app-certificate-back',
  templateUrl: './certificate-back.component.html',
  styleUrls: ['../certificate-front/certificate-front.component.scss']
})
export class CertificateBackComponent implements OnInit {

  //Holder and strings are sent by the certificate component
  @Input() holder: any;
  @Input() strings: Object = {};

  //Available lines for each column on the certificate
  public lines: Array<number> = [
    0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21
  ];

  constructor(public dateFormatter: DateFormatterService) { }

  ngOnInit() {
  }

  //Combine stations and stretches as they are displayed together in the certificate
  public all_infrastructures(): Array<Object> {
      let stretches = this.holder.stretches
      .filter((s) => s.show_in_certificate ) // Filter away stretches, which should not be shown on certificate
      .map((s: Stretch) => { return { id: s.id,  name: `${s.code} ${s.stretch}`, valid_from: s.valid_from, note: s.note } });

      let stations = this.holder.stations.map((s: Station) => { return { id: s.id, name: `${s.code} ${s.station}`, valid_from: s.valid_from, note: s.note } })
      return stretches.concat(stations);
  }

}
