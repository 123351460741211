import * as moment from 'moment';
import { IStation } from '../interfaces/i-station';

export class Station {

    private _id: number = 0;
    private _code: string;
    private _station: string;
    private _full_name: string;
    private _valid_from: Date;
    private _note: string;
    private _last_used: Date;
    private _last_used_outside_secure_area: Date;
    private _amount: number;
    private _label: string;

    constructor(obj?: IStation)
    {
        if (!obj) return;
        this._id = obj.id;
        this._code = obj.code;
        this._station = obj.station;
        this._full_name = obj.full_name;
        this._valid_from = obj._pivot_valid_from ? moment(obj._pivot_valid_from).toDate() : null;
        this._note = obj._pivot_note;
        this._last_used = obj._pivot_last_used ? moment(obj._pivot_last_used).toDate() : null;
        this._last_used_outside_secure_area = obj._pivot_last_used_outside_secure_area ? moment(obj._pivot_last_used_outside_secure_area).toDate() : null;
        this._amount = obj.amount;
        this._label = obj.label;
    }

    public toJSON(): Object {
        return {
            id: this._id,
            code: this._code,
            station: this._station,
            full_name: this._full_name,
            valid_from: moment(this._valid_from).format("YYYY-MM-DD"),
            note: this._note || null,
            last_used: this._last_used ? moment(this._last_used).format("YYYY-MM-DD") : null,
            last_used_outside_secure_area: this._last_used_outside_secure_area ? moment(this._last_used_outside_secure_area).format('YYYY-MM-DD') : null
        }
    }

    public isEqualTo(station: Station): boolean {
        return (
            this.id === station.id && 
            this.code === station.code && 
            this.station === station.station && 
            this.full_name === station.full_name && 
            this.valid_from === station.valid_from && 
            this.note === station.note && 
            this.last_used === station.last_used && 
            this.last_used_outside_secure_area === station.last_used_outside_secure_area
        )
    }

    get id(): number {
        return this._id;
    }
    set id(id: number) {
        this._id = id;
    }
    get code(): string {
        return this._code;
    }
    set code(code: string) {
        this._code = code;
    }
    get station(): string {
        return this._station;
    }
    set station(station: string) {
        this._station = station;
    }
    get full_name(): string {
        return this._full_name;
    }
    set full_name(full_name: string) {
        this._full_name = full_name;
    }
    get valid_from(): Date {
        return this._valid_from;
    }
    set valid_from(valid_from: Date) {
        this._valid_from = valid_from;
    }
    get note(): string {
        return this._note;
    }
    set note(note: string) {
        this._note = note;
    }
    get last_used(): Date {
        return this._last_used;
    }
    set last_used(last_used: Date) {
        this._last_used = last_used;
    }
    get last_used_outside_secure_area(): Date {
        return this._last_used_outside_secure_area;
    }
    set last_used_outside_secure_area(last_used_outside_secure_area: Date) {
        this._last_used_outside_secure_area = last_used_outside_secure_area;
    }
    get label(): string {
        return this._label;
    }
    set label(label: string) {
        this._label = label;
    }
    get amount(): number {
        return this._amount;
    }
    set amount(amount: number) {
        this._amount = amount;
    }
    public toString(): string {
        return this._code + this._station;
    }
}
