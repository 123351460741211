export class StretchSorter {
  /**
   * A static method to sort objects based on the 'code' property which contains alphanumeric values.
   * This sorter is tailored to handle codes that include sequences of letters followed by numbers,
   * which may also include decimal points, to ensure that they are sorted in a way that respects numerical values.
   *
   * @param a An object containing a 'code' property.
   * @param b Another object containing a 'code' property.
   * @returns A negative number if 'a' should come before 'b', a positive number if 'b' should come before 'a',
   * or zero if they are considered equal for sorting purposes.
   */
  public static customSorter(a, b) {
    // Extract the 'code' property from both objects
    const aCode = a?.code ?? "";
    const bCode = b?.code ?? "";

    // A and B matches, therefore there is not any reason to sort further.
    // return early to save compute power
    if(aCode == bCode) {
      return 0;
    }

    // Guard to protect against null values
    if(aCode == "" || bCode == "") {
      console.warn("Either A or B doesn't have 'code' property. Sorter will return 0.", a,b);

      return aCode == "" ? -1 : 1; // Move null/empty value to the end
    }

    // Initialize indices for comparing characters in the 'code' strings
    let indexA = 0;
    let indexB = 0;

    // Continue comparing characters while indices are within the string lengths
    while (indexA < aCode.length && indexB < bCode.length) {
      const charA = aCode[indexA];
      const charB = bCode[indexB];

      // Check if either character is not a digit
      if (isNaN(charA) || isNaN(charB)) {
        if (charA === charB) {
          indexA++;
          indexB++;
          continue; // Continue to next character if they are the same
        }

        // Compare non-digit characters lexicographically
        return charA.localeCompare(charB);
      }

      // If both characters are digits, identify the complete numbers that these digits start
      const aSubstring = aCode.substring(indexA);
      const bSubstring = bCode.substring(indexB);
      const numberRegex = /\d+/g; // Regex to match the sequence of digits

      // Extract the full number starting at the current index for both strings
      const aMatch = aSubstring.match(numberRegex);
      const bMatch = bSubstring.match(numberRegex);

      // Validate that any matches were found
      // If not, place the match with no entities first in the array
      if(aMatch.length < 1 || bMatch.length < 1) {
        return aMatch.length - bMatch.length;
      }

      // Parsing helps with 0-padding: "01" --> 1, "1" --> 1
      const aNumber = parseInt(aMatch[0]);
      const bNumber = parseInt(bMatch[0]);

      if (aNumber === bNumber) {
        indexA += aMatch[0].length;
        indexB += bMatch[0].length;
        continue; // Continue if the numbers are the same, and check the next character(s)
      }

      // If the numbers are different, return their numerical difference
      return aNumber - bNumber;
    }

    // After comparing the end of aCode and bCode, if one code is longer than the other, sort the shorter code first
    return aCode.length - bCode.length;
  }
}
