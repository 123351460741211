<div class="holder">
    <div id="mat-holder">
        <img src="assets/img/arteslogo.png" style="height:50px;float:left">
        <img src="assets/img/LogoDB.png" style="height:50px;float:right">
        <div style="clear:both;margin-bottom:20px;"></div>
        <div>{{strings.artesStrings['VALIDATE_DESC']}}</div>
        <mat-form-field class="center-align" >
            <mat-label>{{strings.artesStrings['LICENSE_NUMBER']}}</mat-label>
            <input matInput type="text" [(ngModel)]="licenseNumber" name="licenseNumber">
        </mat-form-field>
        <div></div>
        <button mat-raised-button (click)="getCode()">{{strings.artesStrings['VALIDATE_CERTIFICATE']}}</button>
        <div></div>
        <mat-form-field class="center-align" *ngIf="code">
            <mat-label>{{strings.artesStrings['CODE']}}</mat-label>
            <input matInput type="text" [(ngModel)]="code" name="code" disabled>
        </mat-form-field>
    </div>
</div>


