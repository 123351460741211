import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StringService } from '../../../services/string.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { LoginStateService } from '../../../services/login-state.service';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private route: ActivatedRoute,
              private auth: AuthenticationService,
              private loginStateService: LoginStateService,
              private router: Router,
              public strings: StringService) { }

  ngOnInit(): void {
    //Only login via client certificate is allowed
    //Oauth server redirects here. Validate that state is same as was sent at login.
    //Then request access token using code
    let code = this.route.snapshot.paramMap.get('code');
    let state = this.route.snapshot.paramMap.get('state');

    //// If the above code (where code and state are found) doesnt work, try this one:
    // const urlParams = new URLSearchParams(window.location.search);
    // const code = urlParams.get("code");
    // let state = urlParams.get("state");

    if (code && state) {
      try {
        if (state.length % 4 !== 0) {
          for (let i=0; i<state.length % 4; i++) {
            state += '='
          }
        }
        if (state === this.auth.getState()) {
          let savedStateObj = JSON.parse(atob(this.auth.getState()));
          this.auth.requestAccessToken(code, savedStateObj.redirect)
        }
      } catch (e) {
      }
    } else {
      if (this.router.url === '/login/access-denied') {
          this.loginStateService.toggle(false);
      }
    }
  }

}
