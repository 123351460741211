import * as moment from 'moment';
import { IEngine } from '../interfaces/i-engine';

export class Engine {

    private _id: number = 0;
    private _engine: string;
    private _valid_from: Date;
    private _valid_to: Date;
    private _note: string;
    private _last_used: Date;
    private _amount: number;
    private _label: string;

    constructor(obj?: IEngine) {
        if (!obj) return;
        this._id = obj.id;
        this._engine = obj.engine;
        this._valid_from = obj._pivot_valid_from ? moment(obj._pivot_valid_from).toDate() : null;
        this._note = obj._pivot_note;
        this._last_used = obj._pivot_last_used ? moment(obj._pivot_last_used).toDate() : null;
        this._amount = obj.amount;
        this._label = obj.label;
    }

    public toJSON() {
        return {
            id: this._id,
            engine: this._engine,
            valid_from: moment(this._valid_from).format("YYYY-MM-DD"),
            note: this._note || null,
            last_used: this._last_used ? moment(this._last_used).format('YYYY-MM-DD') : null
        }
    }

    public isEqualTo(engine: Engine): boolean {
        return (
            this._id === engine.id && 
            this._engine === engine.engine && 
            this._valid_from === engine.valid_from && 
            this._valid_to === engine.valid_to && 
            this._note === engine.note && 
            this._last_used === engine.last_used
        )
    }

    /**
     * Getter id
     * @return {number}
     */
	public get id(): number {
		return this._id;
	}

    /**
     * Getter engine
     * @return {string}
     */
	public get engine(): string {
		return this._engine;
	}

    /**
     * Getter valid_from
     * @return {Date}
     */
	public get valid_from(): Date {
		return this._valid_from;
	}

    /**
     * Getter valid_to
     * @return {Date}
     */
	public get valid_to(): Date {
		return this._valid_to;
	}

    /**
     * Getter note
     * @return {string}
     */
	public get note(): string {
		return this._note;
	}

    /**
     * Getter last_used
     * @return {Daate}
     */
	public get last_used(): Date {
		return this._last_used;
	}

    /**
     * Getter amount
     * @return {number}
     */
	public get amount(): number {
		return this._amount;
	}

    /**
     * Getter label
     * @return {string}
     */
	public get label(): string {
		return this._label;
	}

    /**
     * Setter id
     * @param {number} value
     */
	public set id(value: number) {
		this._id = value;
	}

    /**
     * Setter engine
     * @param {string} value
     */
	public set engine(value: string) {
		this._engine = value;
	}

    /**
     * Setter valid_from
     * @param {Date} value
     */
	public set valid_from(value: Date) {
		this._valid_from = value;
	}

    /**
     * Setter valid_to
     * @param {Date} value
     */
	public set valid_to(value: Date) {
		this._valid_to = value;
	}

    /**
     * Setter note
     * @param {string} value
     */
	public set note(value: string) {
		this._note = value;
	}

    /**
     * Setter last_used
     * @param {Date} value
     */
	public set last_used(value: Date) {
		this._last_used = value;
	}

    /**
     * Setter amount
     * @param {number} value
     */
	public set amount(value: number) {
		this._amount = value;
	}

    /**
     * Setter label
     * @param {string} value
     */
	public set label(value: string) {
		this._label = value;
	}

    public toString(): string {
        return this._engine
    }
}
