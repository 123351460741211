//This service is used to issue new certificate, update existing or update status for existing
import { Injectable } from '@angular/core';
import { CertificateHolder } from '../classes/certificateHolder';
import { HttpClient } from '@angular/common/http';
import { HeaderService } from './header.service';
import { ICertificate } from '../interfaces/i-certificate';

@Injectable({
  providedIn: 'root'
})
export class CertificateService {

  private api: string;

  constructor(private http: HttpClient,
              private headers: HeaderService) {
                this.api = this.headers.API_LOCATION;
              }

  public issue(person: CertificateHolder) {
    let data = { holder: person.toJSON() };
    return this.http.post<ICertificate>(`${this.api}certificate`, data, this.headers.getOptions())
      .subscribe(() => { window.location.reload() })
  }

  public update(person: CertificateHolder) {
    let data = { holder: person.toJSON() };
    return this.http.put<ICertificate>(`${this.api}certificate`, data, this.headers.getOptions())
      .subscribe(() => { window.location.reload() })
  }

  public updateStatus(certificateId: number, action: string) {
    let data = { certificate_id: certificateId, action: action };
    return this.http.put<ICertificate>(`${this.api}certificate/status`, data, this.headers.getOptions())
      .subscribe(() => { window.location.reload() })
  }
}
