//This service is used to authorize the client
import { Injectable } from '@angular/core';
import { Role } from '../classes/role';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiService } from './api.service';
import { LoginStateService } from './login-state.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { v4 as uuid } from 'uuid';
import { tap } from 'rxjs/operators';
import { HeaderService } from './header.service';
import { ConstantsService } from './constants.service';
import { EventService } from './event.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public role: Role = new Role();
  private readonly ROLE_ADMIN = "ARTES_ADMIN";
  private readonly ACCESS_TOKEN = "ACCESS_TOKEN";
  private readonly REFRESH_TOKEN = "REFRESH_TOKEN";
  private readonly STATE = "STATE";
  private validationInProgress: boolean = false;
  private validationFailed: boolean = false;

  constructor(private http: HttpClient,
              private api: ApiService,
              private loginState: LoginStateService,
              private router: Router,
              private headers: HeaderService,
              private constantsService: ConstantsService,
              private eventsService: EventService) { }

  //Get headers for oauth server
  private getAuthHeaders(): Object {
    let headers = new HttpHeaders()
      .append('Content-Type', 'application/x-www-form-urlencoded')
    return { headers: headers }
  }

  //Local storage getters and setters
  public storeAccessToken(token: string) {
    localStorage.setItem(this.ACCESS_TOKEN, token);
  }

  public getAccessToken(): string {
    return localStorage.getItem(this.ACCESS_TOKEN);
  }

  public storeRefreshToken(token: string) {
    localStorage.setItem(this.REFRESH_TOKEN, token);
  }

  public getRefreshToken(): string {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  public storeState(state: string) {
    localStorage.setItem(this.STATE, state);
  }

  public getState(): string {
    return localStorage.getItem(this.STATE);
  }

  //Request auth code from oauth server
  public requestAuthCode(redirect?: string) {
    let state = uuid();
    let stateObj = {
      id: state,
      application: this.api.APPLICATION,
      platform: this.api.PLATFORM,
      redirect: redirect
    };
    let encoded = btoa(JSON.stringify(stateObj));
    this.storeState(encoded);
    
    window.location.href = `
      ${environment.ssoUrl}/authorize?
      state=${encoded}&
      scope=openid&
      client_id=${environment.clientId}&
      redirect_uri=${environment.redirectUrl}&
      response_type=code&
      token_content_type=jwt
    `.replace(/\s+/g, '');
  }

  //Request access token from oauth server using auth code, redirect back to original site
  public requestAccessToken(code: string, redirect?: string): Promise<any> {
    let params = `
      code=${code}&
      redirect_uri=${environment.redirectUrl}&
      client_id=${environment.clientId}&
      grant_type=authorization_code&
      token_content_type=jwt
    `.replace(/\s+/g, '');
    return this.http.post<any>(`${environment.ssoUrl}/token`, new Blob([params], {type: 'application/json'}), this.getAuthHeaders())
      .toPromise()
      .then((response) => {
        if (response && response.access_token) {
          this.storeAccessToken(response.access_token);
          this.loginState.toggle(true);
        } else this.loginState.toggle(false);
        if (response && response.refresh_token) {
          this.storeRefreshToken(response.refresh_token);
        }
        if (redirect && redirect.indexOf(window.location.origin) !== -1) {
          this.router.navigateByUrl(redirect.split(window.location.origin)[1])
        }
        return response;
      })
  }

  //Refresh access token using refresh token
  public refreshAccessToken() {
    let params = `
      refresh_token=${this.getRefreshToken()}&
      redirect_uri=${environment.redirectUrl}&
      client_id=${environment.clientId}&
      grant_type=refresh_token&
      token_content_type=jwt
    `.replace(/\s+/g, '');
    return this.http.post<any>(`${environment.ssoUrl}/token`, new Blob([params], { type: 'application/json' }), this.getAuthHeaders())
      .pipe(
        tap((response) => {
          if (response && response.access_token) {
            this.storeAccessToken(response.access_token);
            this.loginState.toggle(true);
          } else this.loginState.toggle(false);
          if (response && response.refresh_token) {
            this.storeRefreshToken(response.refresh_token);
          }
        })
      )
  }

  //Logout and delete tokens
  public logout() {
    this.storeAccessToken("");
    this.storeRefreshToken("");
    this.router.navigateByUrl('/start');
    this.loginState.toggle(false);
  }

  //Check with application server if client has valid access token
  public validateSession() {
    this.validationInProgress = true;
    let data = {
      application: this.api.APPLICATION,
      platform: this.api.PLATFORM
    }
    return this.http.post<any>(this.api.VERIFY, data, this.headers.getOptions())
      .subscribe((response: any) => {
        if (response && response.role) {
          this.validationFailed = false;
          this.role = new Role(response.role);
          if (this.constantsService.wasLoaded === false && this.isAdmin) {
            //this.constantsService.getConstants();
            this.eventsService.getScans();
            this.eventsService.getReports();
          }
        } else {
          this.validationFailed = true;
        }
      })
  }

  //Check if user has administrator role
  public get isAdmin(): Boolean {
    if (!this.validationInProgress && !this.validationFailed && !localStorage.getItem("role")) {
      this.validateSession();
    }
    return this.role.role === this.ROLE_ADMIN || localStorage.getItem("role") === this.ROLE_ADMIN;
  }
}
