//This service is used to get and update certificate holder
import { Injectable } from '@angular/core';
import { CertificateHolder } from '../classes/certificateHolder';
import { HttpClient } from '@angular/common/http';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { HeaderService } from './header.service';
import { ICertificateHolder } from '../interfaces/i-certificate-holder';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CertificateHolderService {

  public holder: CertificateHolder = new CertificateHolder();
  private api: string;

  constructor(private http: HttpClient,
              private headers: HeaderService) {
                this.api = this.headers.API_LOCATION;
              }

  //Output changes to components
  @Output() onChange: EventEmitter<CertificateHolder> = new EventEmitter();

  //Toggle output changes
  public toggleChange(holder?: CertificateHolder) {
    if (holder) {
      this.holder = holder;
    }
    this.onChange.emit(this.holder);
  }

  //Get certificate holder from server
  public getCertificateHolder(personId: number): Observable<ICertificateHolder> {
    return this.http.get<ICertificateHolder>(`${this.api}certificate-holders/${personId}`, this.headers.getOptions())
  }

  //Update certificate holder with changes
  public updateCertificateHolder(holder: CertificateHolder) {
    let data = { holder: holder.toJSON() };
    return this.http.put<ICertificateHolder>(`${this.api}certificate-holder`, data, this.headers.getOptions())
  }
}
